import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type TitleBackProviderType = {
  title: string | null;
  useTitleBack: (
    title?: TitleBackProviderType['title'],
    backUrl?: TitleBackProviderType['backUrl'],
    onClick?: TitleBackProviderType['onClick'],
    elements?: TitleBackProviderType['elements'],
    showArrow_?: TitleBackProviderType['isShowArrow'],
    showTitleBack_?: TitleBackProviderType['isShowTitleBack']
  ) => void;
  isShowTitleBack: boolean;
  isShowArrow: boolean;
  backUrl: string | null;
  onClick: null | Function;
  elements: ReactNode;
};

const TitleBackContext = createContext<TitleBackProviderType>({
  useTitleBack: () => {},
  isShowTitleBack: false,
  isShowArrow: true,
  title: null,
  backUrl: null,
  onClick: null,
  elements: null,
});

const TitleBackProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [isShowTitleBack, setIsShowTitleBack] = useState(false);
  const [isShowArrow, setIsShowArrow] = useState(true);
  const [title, setTitle] = useState<TitleBackProviderType['title']>('');
  const [backUrl, setBackUrl] = useState<TitleBackProviderType['backUrl']>(null);
  const [onClick, setOnClick] = useState<TitleBackProviderType['onClick']>(null);
  const [elements, setElements] = useState<TitleBackProviderType['elements']>(null);

  const useTitleBack = (
    title_?: TitleBackProviderType['title'],
    backUrl_: TitleBackProviderType['backUrl'] = null,
    onClick_: TitleBackProviderType['onClick'] = () => {},
    elements_?: TitleBackProviderType['elements'],
    showArrow_?: TitleBackProviderType['isShowArrow'],
    showTitleBack_?: TitleBackProviderType['isShowTitleBack']
  ) => {
    useEffect(() => {
      if (!title_) {
        return;
      }

      setTitle(title_);
      setBackUrl(backUrl_);
      setOnClick(onClick_);
      setElements(elements_);
      setIsShowArrow(showArrow_ !== undefined ? showArrow_ : true);
      setIsShowTitleBack(showTitleBack_ !== undefined ? showTitleBack_ : true);
      return () => {
        setIsShowTitleBack(false);
      };
    }, [title_, backUrl_, onClick_, elements_]);
  };

  return (
    <TitleBackContext.Provider
      value={{
        title,
        useTitleBack,
        isShowTitleBack,
        isShowArrow,
        backUrl,
        onClick,
        elements,
      }}
    >
      {children}
    </TitleBackContext.Provider>
  );
};

const useTitleBackContext = () => useContext(TitleBackContext);

export { useTitleBackContext, TitleBackProvider };
