import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { updateCourseStatus } from '../../../../../api/course';
import { CustomQuill } from '../../../../../components/CustomQuill/CustomQuill';
import useFetch from '../../../../../hooks/useFetch';
import { useNavigate } from '../../../../../hooks/useNavigate';
import { BottomSheet } from '../../../../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../../../../horizon-components/MButton';
import { Modal } from '../../../../../horizon-components/Modal';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseStatus } from '../../../../../models/Course';

export const CoursePreviewStaff: React.FC = () => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [comment, setComment] = useState('');
  const { t } = useTranslation(['translation']);

  const { isLoading: isRejecting, fetchData: fetchRejectedStatus } =
    useFetch(updateCourseStatus);
  const { isLoading: isSubmitting, fetchData: fetchVerifiedStatus } =
    useFetch(updateCourseStatus);

  const reject = () => {
    courseId &&
      fetchRejectedStatus(courseId, {
        new_status: CourseStatus.Rejected,
        comment,
      }).then(() => navigate(MainRoutes.edu + EduRoutes.verify));
  };

  const approve = () => {
    courseId &&
      fetchVerifiedStatus(courseId, {
        new_status: CourseStatus.Verified,
        comment: '',
      }).then(() => navigate(MainRoutes.edu + EduRoutes.verify));
  };

  const closeComment = () => setIsCommentModalVisible(false);
  const handleChangeComment = (value: string) => setComment(value);
  const showRejectModal = () => setIsCommentModalVisible(true);

  return (
    <>
      <div
        className={
          'inline-flex flex-col justify-between gap-2 rounded-2xl border border-secondary-grey-500 bg-secondary-grey-200 p-2 shadow-md shadow-shadow-700 lg:flex-row lg:items-center lg:gap-10 lg:rounded-[30px] lg:p-6'
        }
      >
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[3px]">
          <p className="self-stretch text-base font-semibold leading-snug text-[#1b2559] lg:text-lg">
            {t('course.verificationMode')}
          </p>
          <p className="self-stretch text-xs font-semibold leading-none text-[#8f9bba]">
            {t('course.verificationModeDesc')}
          </p>
        </div>
        <div
          className={
            'mt-2 flex w-full flex-wrap justify-end gap-2 lg:mt-0 lg:w-auto lg:gap-5'
          }
        >
          <MButton
            color={'danger'}
            className={'w-full lg:w-auto'}
            variant={'outline'}
            onClick={showRejectModal}
          >
            {t('course.Reject')}
          </MButton>
          <MButton
            color={'primary'}
            className={'w-full lg:w-auto'}
            variant={'highlighted'}
            onClick={approve}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('course.Approve')}
          </MButton>
        </div>
      </div>

      {window.innerWidth > 768 ? (
        <Modal
          title={t('course.commentRejection')}
          isOpen={isCommentModalVisible}
          onClose={closeComment}
          maxWidth={760}
        >
          <>
            <CustomQuill
              wrapperClassName={'mb-1'}
              value={comment}
              onChange={handleChangeComment}
            />
            <div className={'flex justify-end'}>
              <MButton
                color={'primary'}
                variant={'outline'}
                onClick={reject}
                disabled={!comment || isRejecting}
                loading={isRejecting}
              >
                {t('course.sendAndReject')}
              </MButton>
            </div>
          </>
        </Modal>
      ) : (
        <BottomSheet open={isCommentModalVisible} onDismiss={closeComment}>
          <>
            <CustomQuill
              wrapperClassName={'mb-1'}
              value={comment}
              onChange={handleChangeComment}
            />
            <div className={'flex justify-end'}>
              <MButton
                color={'primary'}
                variant={'outline'}
                onClick={reject}
                disabled={!comment || isRejecting}
                loading={isRejecting}
              >
                {t('course.sendAndReject')}
              </MButton>
            </div>
          </>
        </BottomSheet>
      )}
    </>
  );
};
