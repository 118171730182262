import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useTitleBackContext } from '../../../../providers';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import SEO from '../../../../components/SEO/SEO';
import { clearStringFromHtmlTags } from '../../../../utils/clearStringFromHtmlTags';

export const CoursePreviewBreadcrumbs: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const { useTitleBack } = useTitleBackContext();
  const { course } = useCourseCreation();

  useTitleBack(course?.title, MainRoutes.edu + EduRoutes['my-courses']);

  const breads: Bread[] = useMemo(
    () => [
      { title: 'EDU', url: MainRoutes.edu },
      {
        title: t('course.course_creator'),
        url: MainRoutes.edu + EduRoutes['my-courses'],
      },
      {
        title: course?.title,
        url: Number(id)
          ? MainRoutes.edu + EduRoutes.create
          : MainRoutes.edu + EduRoutes.edit + `/${id}/upload-content`,
      },
    ],
    [course?.title, id, t]
  );

  return (
    <>
      <SEO
        title={course?.title}
        desc={clearStringFromHtmlTags(course?.description)}
        bannerUrl={course?.cover_image}
        loading={!course}
      />
      <Breadcrumbs breads={breads} className="container hidden lg:block" />
    </>
  );
};
