import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileSubjectsStats } from '../../../../features/profile-subjects-stats';
import { TSubjectMetrica } from '../../../../models/Course';

interface ProfileSubjectsStatsCardProps {
  className?: string;
  metric?: TSubjectMetrica | null;
}

export const ProfileSubjectsStatsCard = ({
  className,
  metric,
}: ProfileSubjectsStatsCardProps) => {
  const { t } = useTranslation(['translation']);
  if (!metric) return null;
  return (
    <div
      className={classNames(
        'flex flex-col gap-2 lg:gap-4',
        'rounded-2xl bg-white p-2 text-xs text-secondary-dark-grey-500 shadow-md shadow-shadow-700 lg:rounded-[30px] lg:p-6 lg:text-base ',
        className
      )}
    >
      <div className="flex items-center justify-between">
        <h5 className="text-sm font-semibold text-secondary-dark-grey-900">
          {t('nft.gpaDP')}
        </h5>
        <span className="text-lg font-bold text-primary-purple-blue-500">
          {(metric?.gpa_avg || 0).toFixed(1)}
        </span>
      </div>
      <ProfileSubjectsStats className="w-full" metric={metric} />
    </div>
  );
};
