import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ERIcon } from '../../../../assets/icons/new-er.svg';
import { ReactComponent as StarIcon } from '../../../../assets/icons/star.svg';
import Img from '../../../../assets/images/reward/erp.png';
import FailedImg from '../../../../assets/images/reward/failed.webp';
import SuccessImg from '../../../../assets/images/reward/success.webp';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { Grade, TSummary } from '../../../../models/Course';
import { useLanguage } from '../../../../providers';
import { formatNumber } from '../../../../shared/utils/formatNumber';

type Props = {
  summaryResult: TSummary;
  rateCourse: () => void;
  restartUnit: (unitId: number) => void;
  handleCourseEnd: () => void;
};

export const SummaryResultInfoOneUnit: React.FC<Props> = ({
  summaryResult,
  rateCourse,
  restartUnit,
  handleCourseEnd,
}) => {
  const { t } = useTranslation(['translation']);
  const { language } = useLanguage();
  const summaryData = summaryResult.data[0];

  const isSuccess = (['A', 'B', 'C'] as Grade[]).includes(summaryData.grade);

  const gradeContentMap: Record<Grade, string> = {
    A: 'bg-primary-green-100 text-primary-green-600',
    B: 'bg-primary-green-100 text-primary-green-600',
    C: 'bg-additional-orange-100 text-additional-orange-600',
    D: 'bg-additional-orange-100 text-additional-orange-600',
    F: 'bg-additional-pink-100 text-additional-pink-600',
  };

  const infoTable = useMemo(() => {
    const info = [
      {
        title: t('summary_result.grade.title'),
        description: t('summary_result.grade.description'),
        value: summaryData.grade,
      },
      {
        title: t('summary_result.gpa.title'),
        description: t('summary_result.gpa.description'),
        value: summaryData.gpa,
      },
      {
        title: t('summary_result.percentage.title'),
        description: t('summary_result.percentage.description'),
        value: formatNumber(summaryData.percentage_correct.toString(), language),
      },
      {
        title: t('summary_result.earned.title'),
        description: summaryData.award_already_received
          ? t('summary_result.earned.received')
          : summaryData.er_points_income !== '0.00'
            ? t('summary_result.earned.success')
            : t('summary_result.earned.error'),
        value: formatNumber(summaryData.er_points_income, language),
        earned: true,
      },
    ];

    const infoTable = [];
    for (const item of info) {
      infoTable.push(
        <>
          {item.earned && (
            <div className="w-full border border-secondary-dark-grey-200" />
          )}
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex flex-col">
              <span className="text-sm font-medium leading-[18px] text-secondary-dark-grey-900">
                {item.title}
              </span>
              <span className="text-xs font-medium leading-4 text-secondary-dark-grey-500">
                {item.description}
              </span>
            </div>
            {!item.earned && (
              <div
                className={classNames(
                  'flex items-center justify-center rounded-lg px-3 py-2 text-xs font-semibold leading-4',
                  gradeContentMap[summaryData.grade]
                )}
              >
                {item.value}
              </div>
            )}
            {item.earned && (
              <>
                <div
                  className={classNames(
                    'flex items-center justify-center gap-1',
                    'rounded-lg bg-primary-purple-blue-100 px-3 py-2 text-xs font-semibold leading-4 text-primary-purple-blue-600',
                    {
                      'bg-secondary-grey-500 text-secondary-grey-700':
                        item.value === '0',
                    }
                  )}
                >
                  <span className="leading-4">{item.value}</span>
                  <ERIcon
                    className={classNames('h-4 w-4 fill-primary-purple-blue-500', {
                      'fill-secondary-grey-700': item.value === '0',
                    })}
                  />
                </div>
              </>
            )}
          </div>
        </>
      );
    }

    return infoTable;
  }, [summaryResult.data, t]);

  const resultImage = !isSuccess
    ? FailedImg
    : summaryData.er_points_income === '0.00'
      ? SuccessImg
      : Img;

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <img src={resultImage} alt={'img'} className="max-h-[140px] object-contain" />
      <div className="text-secondary-gray-900 text-center text-base font-medium">
        {isSuccess ? t('summary_result.title') : t('summary_result.title_failed')}
      </div>
      <div
        className={classNames(
          'inline-flex flex-col items-start justify-start gap-2 lg:gap-4',
          'w-full rounded-2xl bg-white shadow-md shadow-shadow-700 lg:rounded-[30px]',
          'p-2 lg:px-10 lg:py-6'
        )}
      >
        {infoTable}
      </div>
      <MButton
        color="primary"
        size="sm"
        variant="secondary"
        onClick={rateCourse}
        className="mt-2 w-full"
      >
        <div className="flex items-center gap-1">
          <span>{t('summary_result.buttons.rate_course')}</span>
          <StarIcon className="h-[18px] w-[18px]" />
        </div>
      </MButton>
      <div className="flex w-full items-center justify-between gap-2">
        <MButton
          color="primary"
          size="sm"
          variant="outline"
          onClick={() => restartUnit(Number(summaryData.course_unit.id))}
          className="w-full"
        >
          {t('summary_result.buttons.restart')}
        </MButton>
        <MButton
          color="primary"
          size="sm"
          variant="highlighted"
          onClick={handleCourseEnd}
          className="w-full"
        >
          {t('summary_result.buttons.next')}
        </MButton>
      </div>
    </div>
  );
};
