import classNames from 'classnames';
import { Grade } from '../../../models/Course';
import { TCourseProgress } from '../../../models/Unit';

interface ProfileCourseStatsProps {
  className?: string;
  courses?: TCourseProgress[];
}

export const ProfileCourseStats = ({
  className,
  courses,
}: ProfileCourseStatsProps) => {
  const gradeContentMap: Record<Grade, string> = {
    A: 'text-primary-green-500',
    B: 'text-primary-green-500',
    C: 'text-additional-orange-600',
    D: 'text-additional-orange-600',
    F: 'text-additional-pink-600',
  };

  return (
    <div className={classNames('flex h-full flex-col gap-3', className)}>
      {courses?.length
        ? courses?.map((course, index) => (
            <div
              key={index}
              className="flex items-center justify-between rounded-lg bg-white leading-3"
            >
              <div className="text-xs font-semibold text-secondary-dark-grey-900 lg:text-[10px]">
                {course.title}
              </div>
              <span
                className={classNames(
                  `text-xs font-semibold lg:text-[10px]`,
                  gradeContentMap[course.unit_progress.grade as Grade]
                )}
              >
                {course.unit_progress.grade}
              </span>
            </div>
          ))
        : null}
    </div>
  );
};
