import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'green';
};

export const BidChip = ({ children, variant = 'primary' }: Props) => {
  const primaryStyle = 'text-primary-purple-blue-700 bg-purple-100';
  const secondaryStyle = 'text-purple-100 bg-primary-purple-blue-500';
  const greenStyle = 'text-primary-green-800 bg-primary-green-100';

  return (
    <div
      className={classNames(
        'left-3 top-3 flex w-fit items-center gap-1 rounded-full p-2',
        'text-xs font-semibold leading-none',
        variant === 'primary'
          ? primaryStyle
          : variant === 'secondary'
            ? secondaryStyle
            : greenStyle
      )}
    >
      {children}
    </div>
  );
};
