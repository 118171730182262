import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getCourseUnitV3,
  getPrivateCourseV3,
  getPublicСourseLicenseV3,
} from '../../api/courseV3';
import { getLanguagesList, Languages } from '../../api/dictionaries';
import useFetch from '../../hooks/useFetch';
import { Course, TLicense, UnitItem } from '../../models/Course';
import { ResponsePagination } from '../../models/Response';

interface CourseContextProps {
  course: Course | null;
  isLoading: boolean;
  error: Error | null;
  fetchCourse: (courseId: string) => void;
  getCourse: () => void;
  updateCourse: (course: Course) => void;
  languages: ResponsePagination<Languages> | null;
  licenses: ResponsePagination<TLicense> | null;
  courseUnits: UnitItem[];
  updateCourseUnit: (v: UnitItem) => void;
  updateCourseUnits: (v: UnitItem[]) => void;
  setCourseUnits: React.Dispatch<React.SetStateAction<UnitItem[]>>;
  activeTab: number;
  updateActiveTab: (v: number) => void;
  currentUnit?: UnitItem;
  setCurrentUnitExamId: (v: number | null) => void;
  currentUnitExamId: number | null;
  setCurrentTopicIndex: (v: number | null) => void;
  currentTopicIndex: number | null;
  setIsTopicTestActive: (v: boolean) => void;
  isTopicTestActive: boolean;
  isMiscellaneousSubject: boolean;
  checkFirstStep: boolean;
  checkSecondStep: boolean;
  checkTherdStep: boolean;
}

const CourseContext = createContext<CourseContextProps | undefined>(undefined);

export const useCourseCreation = () => {
  const context = useContext(CourseContext);
  if (!context) {
    throw new Error('useCourseContext must be used within a CourseCreationProvider');
  }
  return context;
};

interface CourseCreationProviderProps {
  children: ReactNode;
}

export const CourseCreationProvider: React.FC<CourseCreationProviderProps> = ({
  children,
}) => {
  const { id: courseId } = useParams();
  const [urlSearchParams] = useSearchParams();
  const [course, setCourse] = useState<Course | null>(null);
  const [courseUnits, setCourseUnits] = useState<UnitItem[]>([]);
  const [activeTab, setActiveTab] = useState<number>(
    course?.subject_level_units?.[0]?.id || 1
  );
  const [currentUnitExamId, setCurrentUnitExamId] = useState<number | null>(null);
  const [currentTopicIndex, setCurrentTopicIndex] = useState<number | null>(0);
  const [isTopicTestActive, setIsTopicTestActive] = useState(false);
  const {
    isLoading,
    error,
    fetchData: fetchCourse,
  } = useFetch((courseId: string) => getPrivateCourseV3(courseId));
  const { data: languages, fetchData: fetchLanguagesList } =
    useFetch(getLanguagesList);
  const { fetchData: fetchCourseUnits } = useFetch(getCourseUnitV3);
  const { data: licenses, fetchData: fetchLicenses } = useFetch(
    getPublicСourseLicenseV3
  );
  const isMiscellaneousSubject =
    urlSearchParams.get('is_miscellaneous_subject') === 'true' ||
    !!course?.tags.length ||
    false;

  useEffect(() => {
    fetchCourseUnits(Number(courseId) || 0).then((data) => {
      if (data?.length) {
        setCourseUnits(data);
        setActiveTab(data[0]?.id);
      }
    });
  }, [courseId]);

  const getCourse = useCallback(() => {
    courseId && fetchCourse(courseId).then((res) => res?.id && setCourse(res));
  }, [courseId]);

  useEffect(() => getCourse(), [getCourse]);

  const updateCourseUnit = (data: UnitItem) => {
    setCourseUnits((prevUnits) =>
      prevUnits.map((unit) => (unit.id === data.id ? data : unit))
    );
  };

  const currentUnit = useMemo(() => {
    return courseUnits?.find((unit) => unit.id === activeTab);
  }, [courseUnits, activeTab]);

  useEffect(() => {
    fetchLanguagesList();
    fetchLicenses();
  }, []);

  const checkFirstStep = useMemo(() => {
    return !!(
      course?.title &&
      course?.language &&
      course?.outcomes?.length &&
      course?.description &&
      (isMiscellaneousSubject
        ? course?.tags?.length
        : course?.subject.id && course?.level && course?.subject_level_units?.length)
    );
  }, [course, isMiscellaneousSubject]);

  const checkSecondStep = useMemo(() => {
    return !!courseUnits
      ?.map(
        (unit) =>
          unit?.questions
            ?.map((question) =>
              typeof question === 'number'
                ? true
                : !!question?.unit_answers
                    ?.map((answer) => answer?.text && answer?.correct)
                    ?.includes(false) && !!question.text
            )
            ?.includes(false) &&
          unit?.topics
            ?.map(
              (topic) =>
                !!topic?.contents?.map((content) => !!content?.text)?.includes(false)
            )
            ?.includes(false)
      )
      .includes(false);
  }, [courseUnits]);

  const checkTherdStep = useMemo(() => {
    return !!(
      course?.cover_image ||
      course?.cover_image_predefined ||
      course?.introduction_files.length
    );
  }, [course]);

  const contextValue = useMemo(
    () => ({
      course,
      isLoading,
      error,
      fetchCourse,
      updateCourse: setCourse,
      getCourse,
      languages,
      licenses,
      currentUnit,
      courseUnits,
      updateCourseUnit,
      updateCourseUnits: setCourseUnits,
      updateActiveTab: setActiveTab,
      setCourseUnits,
      activeTab,
      setCurrentUnitExamId,
      currentUnitExamId,
      setCurrentTopicIndex,
      currentTopicIndex,
      setIsTopicTestActive,
      isTopicTestActive,
      isMiscellaneousSubject,
      checkFirstStep,
      checkSecondStep,
      checkTherdStep,
    }),
    [
      course,
      isLoading,
      error,
      fetchCourse,
      getCourse,
      languages,
      licenses,
      currentUnit,
      courseUnits,
      activeTab,
      currentUnitExamId,
      currentTopicIndex,
      isTopicTestActive,
      isMiscellaneousSubject,
      checkFirstStep,
      checkSecondStep,
      checkTherdStep,
    ]
  );

  return (
    <CourseContext.Provider value={contextValue}>{children}</CourseContext.Provider>
  );
};
