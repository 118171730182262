import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRightPurpleIcon } from '../../../../assets/icons/chevron.right.purple.svg';
import { Modal } from '../../../../horizon-components/Modal';
import { BottomSheet } from '../../../../horizon-components/bottom-sheet/ui';

export const AuctionViewRules = () => {
  const { t } = useTranslation(['translation']);
  const [rulesModalIsOpen, setRulesModalIsOpen] = useState(false);
  const rulesCount = 11;

  const contentModalRender = () => (
    <div className="flex max-w-xl flex-col gap-5 overflow-y-auto">
      {[...Array(rulesCount)].map((_, ruleNumber) => (
        <div key={ruleNumber} className={'flex items-start gap-[8px]'}>
          <div
            className={
              'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
            }
          >
            {ruleNumber + 1}
          </div>
          <p className={'mt-1 text-[16px] font-[600] text-secondaryGrey-900'}>
            {t(`bid.r${ruleNumber + 1}`)}
          </p>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div
        className={
          'mb-[calc(env(safe-area-inset-bottom))] flex cursor-pointer items-center justify-between rounded-2xl p-2 !shadow-[4px_4px_24px_4px_#7090B014] lg:rounded-3xl lg:p-4'
        }
        onClick={() => setRulesModalIsOpen(true)}
      >
        <p className={'text-[16px] font-[600] text-gray-900'}>{t('bid.Rules')}</p>
        <ChevronRightPurpleIcon />
      </div>
      {window.innerWidth > 768 ? (
        <Modal
          title={t('bid.Rules')}
          isOpen={rulesModalIsOpen}
          onClose={() => setRulesModalIsOpen(false)}
        >
          {contentModalRender()}
        </Modal>
      ) : (
        <BottomSheet
          open={rulesModalIsOpen}
          onDismiss={() => setRulesModalIsOpen(false)}
        >
          {contentModalRender()}
        </BottomSheet>
      )}
    </>
  );
};
