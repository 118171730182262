import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuctionRoutes, MainRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { BidWinnings } from '../../../modules/Bid/bid-winnings';
import {
  AuctionViewBidsPage,
  AuctionViewPage,
} from '../../../modules/Bid/auction-view';
import { BidServiceProvider } from '../../../providers/BidServiceProvider/BidServiceProvider';
import { BidAll } from '../../../modules/Bid/bid-all/ui/bid-all';
import { BidAbout } from '../../../modules/Bid/bid-about/ui/bid-about';

export const BidRouter: React.FC = () => {
  return (
    <div className="AddBottom mx-auto w-full grow sm-max:p-2">
      <div className="mx-auto w-full grow">
        <Routes>
          <Route
            path="/"
            element={<Navigate to={MainRoutes.bid + AuctionRoutes.actual} replace />}
          />
          <Route path={AuctionRoutes.actual} element={<BidAll />} />
          <Route path={AuctionRoutes.about} element={<BidAbout />} />
          <Route path={AuctionRoutes.winnings} element={<BidWinnings />} />
          <Route
            path={AuctionRoutes.auctionView + '/:id'}
            element={<AuctionViewPage />}
          />
          <Route
            path={AuctionRoutes.winningsView + '/:id'}
            element={<AuctionViewPage />}
          />
          <Route
            path={AuctionRoutes.auctionView + '/:id' + AuctionRoutes.bids}
            element={<AuctionViewBidsPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};
