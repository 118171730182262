import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '../../../../components/Loader';
import { Bid, BidStatusEnum } from '../../../../models/Auction';
import { useUserContext } from '../../../../providers';
import { BidCard } from '../../../../widgets/bid-card';
import { getBidsV3 } from '../../../../api/bid';
import classNames from 'classnames';
import { AuctionViewRate } from './auction-view-rate';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { BIDS_PAGE_SIZE } from '../../constants';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { useBidServiceContext } from '../../../../providers/BidServiceProvider/BidServiceProvider';

export const AuctionViewBidsAll = ({
  isScroll = false,
  fullHeight = false,
  onBidMode,
}: {
  isScroll?: boolean;
  onBidMode?: () => void;
  fullHeight?: boolean;
}) => {
  const [firstBidsLoaded, setFirstBidsLoaded] = useState(false);
  const { user } = useUserContext();
  const { getBidsPagesCount, bidsCommonInfo } = useBidServiceContext();
  const {
    auction,
    auctionStatus,
    getIsWinner,
    bids: bidsFromContext,
    isBidsLoading: isBidsFromContextLoading,
    setActualBid,
    setIsSendBidMode,
    fetchBids,
  } = useAuctionContext();

  const handleBid = useCallback(
    (bidAmount: number) => {
      setIsSendBidMode(true);
      setActualBid(bidAmount + 1);
      onBidMode?.();
    },
    [setIsSendBidMode, setActualBid, onBidMode]
  );

  const [page, setPage] = useState(0);
  const [bids, setBids] = useState<Bid[]>([]);
  const targetRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [minBid, setMinBid] = useState<number>(0);
  const winnersLanght = (bids?.filter((bid) => getIsWinner(bid)) || [])?.length;
  const number_winners =
    auction?.number_winners &&
    winnersLanght < auction?.number_winners &&
    auctionStatus === BidStatusEnum.COMPLETED
      ? winnersLanght
      : auction?.number_winners || 0;

  const auctionId = auction?.id || 0;

  useEffect(() => {
    setMinBid(
      number_winners
        ? Number(bids.find((_, idx) => idx + 1 === number_winners)?.bid_amount)
        : 0
    );
  }, [auction, bids, number_winners]);

  const canFetchMoreBids = !!(
    auctionId &&
    !isLoadingMore &&
    page > 1 &&
    !isBidsFromContextLoading
  );

  const loadMoreBids = useCallback(() => {
    if (canFetchMoreBids) {
      setIsLoadingMore(true);
      getBidsV3(auctionId, page - 1).then((res) => {
        const bidsReversed = res?.slice().reverse();
        setBids((prevBids) => [...prevBids, ...(bidsReversed || [])]);
        if (res?.length === BIDS_PAGE_SIZE) {
          setPage((prevPage) => prevPage - 1);
        }
        setIsLoadingMore(false);
      });
    }
  }, [canFetchMoreBids, auctionId, page]);

  useInfiniteScroll(targetRef, loadMoreBids);

  useEffect(() => {
    if (bidsFromContext && !firstBidsLoaded) {
      setBids(bidsFromContext);
      setPage(getBidsPagesCount(auctionId) - 1);
      setFirstBidsLoaded(true);
    }
  }, [auctionId, bidsFromContext, firstBidsLoaded, getBidsPagesCount]);

  useEffect(() => {
    setBids(bidsFromContext);
  }, [bidsFromContext]);

  useEffect(() => {
    setFirstBidsLoaded(false);
  }, [fullHeight]);

  const userBid = bids?.findIndex((bid) => bid.user.guid === user?.guid);

  useEffect(() => {
    if (isScroll) {
      const element = document.getElementById('my-bid');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          window.scrollBy({
            top: 0,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [userBid, isScroll]);

  useEffect(() => {
    if (!bidsFromContext.length && bidsCommonInfo) {
      fetchBids();
    }
  }, [bidsCommonInfo, bidsFromContext, fetchBids]);

  return (
    <>
      <div
        className={classNames(
          'flex min-h-[30vh] flex-col gap-3 overflow-y-auto pb-1 pr-4 ',
          {
            'max-h-[70vh]': isScroll && !fullHeight,
            'max-h-[50vh]': !isScroll && !fullHeight,
            'h-full': fullHeight,
          }
        )}
      >
        {bids?.map((bid, idx) => (
          <>
            <BidCard
              bid={bid}
              key={bid.id}
              place={idx < number_winners ? idx + 1 : undefined}
              isMy={bid.user.guid === user?.guid}
              onBid={() => handleBid(Number(bid.bid_amount))}
              canBid={
                auctionStatus === BidStatusEnum.ACTIVE
                  ? typeof userBid !== 'undefined' && userBid > -1
                    ? userBid === idx
                      ? minBid >= Number(bid.bid_amount) && userBid !== 0
                      : number_winners > idx && userBid > idx
                    : number_winners > idx
                  : false
              }
              isWin={
                bid.user.guid === user?.guid ||
                auctionStatus === BidStatusEnum.COMPLETED
                  ? getIsWinner(bid)
                  : idx < number_winners
              }
              isMin={idx + 1 === number_winners}
            />
            {idx + 1 === number_winners && <hr className="mt-1" />}
          </>
        ))}
        {(page > 1 || (isBidsFromContextLoading && !firstBidsLoaded)) && (
          <>
            <div ref={targetRef} className="mt-3" />
            <Loader />
          </>
        )}
      </div>
      <AuctionViewRate />
    </>
  );
};
