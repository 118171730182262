import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mainRoutes } from '../../../horizon-layout/MainLayout/routes';
import { MobileTab } from '../../../widgets/MobileTab';
// import { TrainingMobile } from '../../../widgets/Training';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms'
import './MobileNavBar.scss';
import { MainRoutes } from '../../../horizon-layout/MainLayout/Routes/types/routes';

interface MobileNavBarProps {
  className?: string;
}

export const MobileNavBar = ({ className }: MobileNavBarProps) => {
  const { t } = useTranslation(['translation']);

  const routes: RoutesType[] = useMemo(() => {
    const updatedRoutes = [...mainRoutes];
    updatedRoutes[0] = { ...updatedRoutes[2] };
    updatedRoutes[0].layout = 'menu-play';
    const [movedRoute] = updatedRoutes.splice(0, 1);
    updatedRoutes.splice(3, 0, movedRoute);

    return updatedRoutes
      .filter(route => route.path !== MainRoutes.profile)
      .map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
        path: item.path,
      }));
  }, [t]);


  if (getPlatform() === PlatformsEnum.TELEGRAM) {
    if (window.Telegram?.WebApp.platform === "ios"){
      document.documentElement.style.setProperty("--safe-area-bottom-bc", "#000000");
      document.documentElement.style.setProperty("--safe-area-bottom-height", "var(--tg-safe-area-inset-bottom)");
    } else if (window.Telegram?.WebApp.platform === "android"){
      document.documentElement.style.setProperty("--safe-area-bottom-height", "var(--tg-safe-area-inset-bottom)");
      // document.documentElement.style.setProperty("--safe-area-bottom-height", "3rem");
      // setTimeout(()=> {
        if (document.documentElement.style.cssText.includes("--tg-safe-area-inset-bottom: 0px;")) {
          document.documentElement.style.setProperty("--safe-area-bottom-height", "3rem");
        } 
      // }, 50)
    }

  }
  // let platformstyle = getPlatform() === PlatformsEnum.TELEGRAM ? 'SafeAreaTgBottom' : 'SafeAreaAppBottom'
  // if (document.getElementsByTagName('html')[0].style.cssText.includes("--tg-safe-area-inset-bottom: 0px;")) {
  //   platformstyle = 'SafeAreaTgBottomFailsafe';
  // }

  const createLinks = (routes?: RoutesType[]) => {
    return routes?.map((route, idx) => (
      <MobileTab
        title={route.name}
        key={idx}
        icon={route.icon}
        url={route.path}
        items={route.items}
        secondary={route.secondary}
        component={route.component}
        auth={route.auth}
        className={route.layout}
      />
    ));
  };

  return (
    <>
      <div className={classNames('MobileNavBar', className)}>
        <div className={classNames('MobileNavBarIn')}>
          {createLinks(routes)}
        </div>
        <div id='bottom-safe-area' className={'SafeAreaBottom'}>
        </div>
      </div>
      {/*<TrainingMobile />*/}
    </>
  );
};
