import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../assets/icons/profile/edit.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/icons/profile/share.svg';
import SEO from '../../../../components/SEO/SEO';
import { PlatformsEnum } from '../../../../entities/Platforms/platforms';
import { useNavigate } from '../../../../hooks/useNavigate';
import { useShareReferal } from '../../../../hooks/useShareReferal';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import getPlatform from '../../../../utils/getPlatform';

export const MyProfileHeader = ({ className }: { className?: string }) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { useTitleBack } = useTitleBackContext();
  const { handleClick, isShareDisabled } = useShareReferal();
  const { isLoading } = useRewardContext();
  const platform = getPlatform();

  const buttons = useMemo(() => {
    return (
      <div className="flex flex-row justify-between gap-2 lg:gap-6">
        {platform !== PlatformsEnum.TELEGRAM && (
          <MButton
            color="white"
            variant="highlighted"
            className="shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] lg-max:!px-2.5"
            onClick={() =>
              navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit)
            }
          >
            <span className="hidden lg:block">{t('edit')}</span>
            <EditIcon />
          </MButton>
        )}
        <MButton
          color="white"
          variant="highlighted"
          className="shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] lg-max:!px-2.5"
          onClick={handleClick}
          loading={isLoading}
          disabled={isLoading || isShareDisabled}
          isLoaderCenter
        >
          <span className="hidden lg:block">{t('buttons.Share')}</span>
          <ShareIcon />
        </MButton>
      </div>
    );
  }, [isLoading, isShareDisabled]);

  useTitleBack(t('profile.title'), null, null, buttons, false, false);

  return (
    <div
      className={classNames(
        'flex flex-row justify-end gap-3 lg:justify-between',
        className
      )}
    >
      <SEO
        title={t('pages.profile.meta.title')}
        desc={t('pages.profile.meta.desc')}
      />
      <h1 className="g-main-title hidden lg:block">{t('menu.Profile')}</h1>
      <div className="hidden lg:block">{buttons}</div>
    </div>
  );
};
