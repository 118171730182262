import React, { useMemo, useState } from 'react';
import InputField from '../../../../horizon-components/Input/InputField';
import { Card } from '../../../../horizon-components/Card/Card';
import { useUserContext } from '../../../../providers';
import useForm, { ErrorMessagesCode } from '../../../../hooks/useForm';
import * as yup from 'yup';
import { HButton } from '../../../../horizon-components/Button/HButton';
import { changePassword } from '../../../../api/auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type Form = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

export const ChangePassword: React.FC = () => {
  const { user } = useUserContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, onChange, validate, errors, setValues } = useForm<Form>({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });
  const { t } = useTranslation(['translation']);

  const schema = useMemo(
    () =>
      yup.object().shape({
        old_password: yup.string().required(t(ErrorMessagesCode.required)),
        new_password: yup.string().required(t(ErrorMessagesCode.required)),
        confirm_password: yup.string().required(t(ErrorMessagesCode.required)),
      }),
    []
  );

  const submit = async () => {
    const hasError = await validate(schema);

    if (hasError) {
      return;
    }

    if (values.new_password !== values.confirm_password) {
      toast(t('profile.compareError'), {
        type: 'error',
      });
      return;
    }

    setIsSubmitting(true);
    changePassword({
      ...values,
      email: user?.email || '',
    })
      .then(() => {
        toast(t('profile.success'), {
          type: 'success',
        });
        setValues({
          old_password: '',
          confirm_password: '',
          new_password: '',
        });
      })
      .catch(() => {
        toast(t('profile.compareError'), {
          type: 'error',
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Card extra={'p-3 mb-[10px]'}>
      <div className="w-full pt-1">
        <h5 className="text-xl font-semibold text-navy-700 dark:text-white">
          {t('profile.changePass')}
        </h5>
        <p className="text-md text-[#A3AED0]">{t('profile.changePassInfo')}</p>
      </div>
      <div className="mt-7 grid grid-cols-1 gap-3">
        <InputField
          extra="mb-3"
          label={t('profile.oldPass')}
          placeholder={t('profile.oldPassP')}
          type="password"
          value={values.old_password}
          onChange={(e) => onChange(e.target.value, 'old_password')}
          errorText={errors.old_password}
        />
        <InputField
          extra="mb-3"
          label={t('profile.newPass')}
          placeholder={t('profile.newPassP')}
          type="password"
          value={values.new_password}
          onChange={(e) => onChange(e.target.value, 'new_password')}
          errorText={errors.new_password}
        />
        <InputField
          extra="mb-3"
          label={t('profile.confirmPass')}
          placeholder={t('profile.confirmPassP')}
          type="password"
          value={values.confirm_password}
          onChange={(e) => onChange(e.target.value, 'confirm_password')}
          errorText={errors.confirm_password}
        />
      </div>
      <div className={'mt-[15px] flex w-full justify-end'}>
        <HButton color={'dark'} disabled={isSubmitting} onClick={submit}>
          {t('profile.saveChanges')}
        </HButton>
      </div>
    </Card>
  );
};
