import React from 'react';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/tg.svg';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import {
  ExternalHtmlRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

export type Props = {
  signIn: boolean;
};

export const TelegramAuthMobileButton: React.FC<Props> = ({ signIn }) => {
  const { t } = useTranslation(['translation']);

  const onClick = async () => {
    await Browser.open({
      url: `${process.env.REACT_APP_API_URL}${ExternalHtmlRoutes.authTelegram}`,
      presentationStyle: 'popover',
    });
  };

  return (
    <div
      onClick={onClick}
      className="flex h-[44px] w-full items-center justify-center gap-2 rounded-xl bg-[#54a9eb] hover:cursor-pointer md:h-[48px]"
    >
      <div className="rounded-full text-xl">
        <TelegramIcon className="h-[20px] w-[20px] text-white" />
      </div>
      <p className="text-sm font-medium text-white">
        {signIn ? t('auth.signInTg') : t('auth.signUpTg')}
      </p>
    </div>
  );
};
