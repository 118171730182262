import { CreateWalletRequest } from '../models/TonWallet';
import instance, { AUTH_API_URL } from './instance';

export function setPrivateCreateCryptoWallet(
  body: CreateWalletRequest
): Promise<{ error?: string; status?: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/crypto-wallet/create/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deletePrivateCreateCryptoWallet(): Promise<{
  error?: string;
  status?: string;
}> {
  return instance()
    .delete(`${AUTH_API_URL}/private/crypto-wallet/delete/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
