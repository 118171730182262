export enum DailyRewardStatusEnum {
  CAN_BE_CLAIMED = 'can_be_claimed',
  CLAIMED = 'claimed',
  UPCOMING = 'upcoming',
}

export type TDailyReward = {
  id: number;
  wallet: number;
  amount: string;
  title: string;
  description: string;
  created_at: string;
};

export type TTokenomicConfigs = {
  id: number;
  value: string;
  tokenomic_type: string;
};

export type TDailyRewardData = {
  daily_info: {
    status: DailyRewardStatusEnum;
  }[];
  is_captcha_passed: boolean;
};

