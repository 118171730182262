import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import { authMe, createJWT } from '../../../api/auth';
import { getRedirectPath } from '../../../api/sessions';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';
import { sendLogin, setUserID } from '../../../hooks/useAnalytics';
import useForm, { ErrorMessagesCode } from '../../../hooks/useForm';
import { useNavigate } from '../../../hooks/useNavigate';
import { Checkbox } from '../../../horizon-components/Checkbox/Checkbox';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import getErrorMessages from '../../../utils/getErrorMessages';
import { AuthLayout } from '../../../widgets/AuthLayout';
import { AppleAuth } from '../AppleAuth/AppleAuth';
import { GoogleAuth } from '../GoogleAuth/GoogleAuth';
import getPlatform from '../../../utils/getPlatform';
import SEO from '../../../components/SEO/SEO';
import { TelegramAuth } from '../TelegramAuth/TelegramAuth';
import { TelegramAuthMobileButton } from '../TelegramAuthMobile/TelegramAuthMobileButton';

type SignInForm = {
  email: string;
  password: string;
};

const SignIn: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { saveUser, user } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const redirectPath = getRedirectPath();
  const platform = getPlatform();

  useEffect(() => {
    if (user) {
      navigate(MAIN_PAGE_ROUTE);
    }
  }, [navigate, user]);

  const { values, onChange, errors, validate } = useForm<SignInForm>({
    email: '',
    password: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(t(ErrorMessagesCode.email))
          .required(t(ErrorMessagesCode.required)),
        password: yup.string().required(t(ErrorMessagesCode.required)),
      }),
    []
  );

  const signInHandler = async () => {
    setError('');
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    setIsLoading(true);
    createJWT(values.email, values.password)
      .then((data) => {
        saveAssesTokenStorage(data.access);
        saveRefreshTokenStorage(data.refresh);
        sendLogin('Email');
        return authMe().then((data) => {
          saveUser(data);
          setUserID(data.guid);
          const redirectUrl = searchParams.get('redirect');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            navigate(redirectPath || MAIN_PAGE_ROUTE);
          }
        });
      })
      .catch((error) => {
        error?.response?.data &&
          setError(
            getErrorMessages(error?.response?.data) || t('profile.errorEmail')
          );
        return error;
      })
      .finally(() => setIsLoading(false));
  };

  // TODO: It's bad practice to make links as buttons
  // SUGGESTION: Make option to support link on MButton
  const goToSignUp = () => navigate(MainRoutes.auth + AuthRoutes.signup);

  return (
    <AuthLayout title={t('profile.signIn')} desc={t('auth.loginDesc')}>
      <SEO
        title={t('pages.sign-in.meta.title')}
        desc={t('pages.sign-in.meta.desc')}
      />
      <>
        <div className="flex flex-col gap-y-2">
          {[PlatformsEnum.WEB, PlatformsEnum.ANDROID, PlatformsEnum.IOS].includes(
            platform
          ) && <GoogleAuth signIn={true} />}
          {[PlatformsEnum.WEB, PlatformsEnum.IOS].includes(platform) && (
            <AppleAuth signIn={true} />
          )}
          {[PlatformsEnum.WEB, PlatformsEnum.TELEGRAM].includes(platform) && (
            <TelegramAuth />
          )}
          {[PlatformsEnum.WEB, PlatformsEnum.TELEGRAM].includes(platform) && (
            <TelegramAuth />
          )}
          {[PlatformsEnum.IOS, PlatformsEnum.ANDROID].includes(platform) && (
            <TelegramAuthMobileButton signIn={true} />
          )}
        </div>
        <div className="my-4 flex items-center gap-3 md:my-8">
          <div className="h-px w-full bg-purple-100" />
          <p className="text-xs font-medium leading-4 text-purple-300 md:text-sm md:leading-[18px]">
            {t('profile.or')}
          </p>
          <div className="h-px w-full bg-purple-100" />
        </div>
        <div className="flex flex-col gap-4">
          <MInput
            label={t('profile.email')}
            placeholder="example@mail.com"
            id="email"
            name={'email'}
            type="text"
            bottomText={errors.email}
            state={errors.email ? 'error' : 'default'}
            value={values.email}
            onChange={onChange}
            inputClassName={'w-full leading-[18px]'}
            wrapperClassName={'w-full'}
            autoComplete="username"
          />
          <MInput
            label={t('profile.password')}
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            name={'password'}
            bottomText={errors.password}
            state={errors.password ? 'error' : 'default'}
            value={values.password}
            onChange={onChange}
            inputClassName={'w-full leading-[18px]'}
            wrapperClassName={'w-full'}
            autoComplete="on current-password"
          />
          <div className="mt-2 hidden items-center justify-between px-2 sm:flex">
            <div className="flex items-center">
              <Checkbox
                onChange={(e) => setRememberMe(e.target.checked)}
                checked={rememberMe}
                color={'navy'}
              />
              <p className="ml-2 text-xs font-medium text-navy-700 dark:text-white md:text-sm">
                {t('profile.keep')}
              </p>
            </div>
            <Link
              to={MainRoutes.auth + AuthRoutes.reset}
              className="text-right text-xs font-medium text-purple-500 hover:text-purple-500 md:text-sm"
            >
              {t('profile.forgot')}
            </Link>
          </div>
          {!!error && (
            <p className={'text-center text-xs text-red-600 md:text-sm'}>{error}</p>
          )}
          <div className="flex flex-col gap-y-2">
            <MButton
              variant="highlighted"
              color="primary"
              onClick={signInHandler}
              disabled={isLoading || !values.email || !values.password}
              loading={isLoading}
              size={'md'}
            >
              {t('auth.Sign_In')}
            </MButton>
            <MButton
              variant="secondary"
              color="primary"
              onClick={goToSignUp}
              size={'md'}
              disabled={isLoading}
            >
              {t('profile.createAccount')}
            </MButton>
            <Link
              to={MainRoutes.auth + AuthRoutes.reset}
              className="block text-center text-xs font-medium leading-8 text-purple-500 hover:text-purple-500 sm:hidden"
            >
              {t('profile.forgot')}
            </Link>
          </div>
        </div>
      </>
    </AuthLayout>
  );
};

export default SignIn;
