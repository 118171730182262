import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AppStoreImage from '../../assets/images/downloadButtons/app-store.svg';
import GooglePlayImage from '../../assets/images/downloadButtons/google-play.png';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { Link } from '../../horizon-components/Link';
import { Modal } from '../../horizon-components/Modal';
import getPlatform from '../../utils/getPlatform';

type Props = {
  showModal: boolean;
  onClose: () => void;
  text?: string;
};

export const DownloadMobileAppModal: React.FC<Props> = ({
  showModal,
  onClose,
  text,
}) => {
  const { t } = useTranslation(['translation']);

  const googlePlayLink =
    'https://play.google.com/store/apps/details?id=com.edres.app';
  const appStoreLink = 'https://apps.apple.com/us/app/edres/id6557075013';

  const platform = getPlatform();

  const linksJsx = useMemo(() => {
    const googlePlayIcon = (
      <img src={GooglePlayImage} alt="google play" className={'w-[300px]'} />
    );
    const appStoreIcon = (
      <img src={AppStoreImage} alt="app store" className={'w-[300px]'} />
    );

    return (
      <>
        <Link to={googlePlayLink}>{googlePlayIcon}</Link>
        <Link to={appStoreLink}>{appStoreIcon}</Link>
      </>
    );
  }, [platform]);

  return window.innerWidth > 768 ? (
    <Modal title={t('course.download')} isOpen={showModal} onClose={onClose}>
      <div className={'flex flex-col items-center gap-3'}>
        {text && (
          <p className={'w-[300px] text-center font-medium text-navy-700'}>{text}</p>
        )}
        <div className={'flex flex-col items-center justify-center gap-4'}>
          {linksJsx}
        </div>
      </div>
    </Modal>
  ) : (
    <BottomSheet open={showModal} onDismiss={onClose}>
      <div className={'flex flex-col items-center gap-3'}>
        {text && (
          <p className={'w-[300px] text-center font-medium text-navy-700'}>{text}</p>
        )}
        <div className={'flex flex-col items-center justify-center gap-4'}>
          {linksJsx}
        </div>
      </div>
    </BottomSheet>
  );
};
