import { FC, useEffect, useState } from 'react';
import HighSchoolMap from './HighSchoolMap/HighSchoolMap';
import ElementarySchoolMap from './ElementarySchoolMap/ElementarySchoolMap';
import MiddleSchoolMap from './MiddleSchoolMap/MiddleSchoolMap';
import CharacterMovementArea from './CharacterMovementArea/CharacterMovementArea';
import './LevelMap.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stores';
import { useTranslation } from 'react-i18next';

export const LevelMap: FC = () => {
  const { i18n, t } = useTranslation(['translation']);
  const language = i18n.language;
  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );

  const [subjectLevel, setSubjectLevel] = useState<number>(1);

  useEffect(() => {
    const subjectDetail = subjectDetails?.[language]?.find(
      (detail) => detail.subjectId === currentSubjectId
    );
    setSubjectLevel(Number(subjectDetail?.subjectLevel));
  }, [language, t, subjectDetails, currentSubjectId]);

  useEffect(() => {
    const scrollToPosition = () => {
      let scrollPosition = 0;

      if (
        (subjectLevel >= 1 && subjectLevel <= 4) ||
        (subjectLevel >= 9 && subjectLevel <= 11)
      ) {
        scrollPosition = 1000;
      } else if (subjectLevel === 5) {
        scrollPosition = 50;
      } else if (subjectLevel >= 6 && subjectLevel <= 7) {
        scrollPosition = 300;
      } else if (subjectLevel === 8 || subjectLevel === 12) {
        scrollPosition = 200;
      } else {
        scrollPosition = 1000;
      }
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    };

    scrollToPosition();
  }, [subjectLevel, t]);

  const [isMiddleSchoolMapVisible, setIsMiddleSchoolMapVisible] = useState(true);
  const [isElementarySchoolMapVisible, setIsElementarySchoolMapVisible] =
    useState(true);

  useEffect(() => {
    setIsMiddleSchoolMapVisible(!(subjectLevel >= 9 && subjectLevel <= 12));
    setIsElementarySchoolMapVisible(!(subjectLevel >= 6 && subjectLevel <= 12));
  }, [subjectLevel, t]);

  const renderMaps = () => (
    <>
      <div
        className="h-[960px] pt-[64px]"
        style={{
          // marginTop: `calc(var(--safe-area-top-height))`,
          marginBottom: `calc(var(--safe-area-bottom-height) + 4rem)`,
        }}
      >
        <div className="relative h-full w-full">
          <div className="absolute top-0 h-full w-full">
            {' '}
            <HighSchoolMap className="absolute top-0 h-full w-full" />
            {isMiddleSchoolMapVisible && (
              <MiddleSchoolMap className={`absolute top-0 h-full w-full`} />
            )}
            {isElementarySchoolMapVisible && (
              <ElementarySchoolMap className={`absolute top-0 h-full w-full`} />
            )}
            <CharacterMovementArea className="absolute top-0 h-full w-full" />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="school-map h-full w-full">{renderMaps()}</div>
    </>
  );
};
