import classNames from 'classnames';
import { ProfileSubjectsStats } from '../../../../features/profile-subjects-stats';
import { TSubjectMetrica } from '../../../../models/Course';

interface ProfileSubjectsStatsCardProps {
  className?: string;
  metric?: TSubjectMetrica | null;
}

export const ProfileSubjectsStatsCard = ({
  className,
  metric,
}: ProfileSubjectsStatsCardProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-2 lg:gap-4',
        'rounded-2xl bg-white p-2 shadow-md shadow-shadow-700 lg:rounded-[30px] lg:p-6 ',
        className
      )}
    >
      <ProfileSubjectsStats className="w-full" metric={metric} />
    </div>
  );
};
