import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { changePassword } from '../../../api/auth';
import useForm, { ErrorMessagesCode } from '../../../hooks/useForm';
import InputField from '../../../horizon-components/Input/InputField';
import { useUserContext } from '../../../providers';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import classNames from 'classnames';
import * as yup from 'yup';
import './ProfileChangePassword.scss';

type Form = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

interface ProfileChangePasswordProps {
  className?: string;
}

export const ProfileChangePassword: React.FC<ProfileChangePasswordProps> = ({
  className,
}) => {
  const { user } = useUserContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, onChange, validate, errors, setValues } = useForm<Form>({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });
  const { t } = useTranslation(['translation']);

  const schema = useMemo(
    () =>
      yup.object().shape({
        old_password: yup.string().required(t(ErrorMessagesCode.required)),
        new_password: yup.string().required(t(ErrorMessagesCode.required)),
        confirm_password: yup.string().required(t(ErrorMessagesCode.required)),
      }),
    []
  );

  const submit = async () => {
    const hasError = await validate(schema);

    if (hasError) {
      return;
    }

    if (values.new_password !== values.confirm_password) {
      toast(t('profile.compareError'), {
        type: 'error',
      });
      return;
    }

    setIsSubmitting(true);
    changePassword({
      ...values,
      email: user?.email || '',
    })
      .then(() => {
        toast(t('profile.success'), {
          type: 'success',
        });
        setValues({
          old_password: '',
          confirm_password: '',
          new_password: '',
        });
      })
      .catch(() => {
        toast(t('profile.compareError'), {
          type: 'error',
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className={classNames('ProfileChangePassword', className)}>
      <div className="ProfileChangePassword__head">
        <h3>{t('profile.changePass')}</h3>
        <p>{t('profile.changePassInfo')}</p>
      </div>
      <div className="ProfileChangePassword__content">
        <InputField
          label={t('profile.oldPass')}
          placeholder={t('profile.oldPassP')}
          type="password"
          value={values.old_password}
          onChange={(e) => onChange(e.target.value, 'old_password')}
          errorText={errors.old_password}
        />
        <InputField
          label={t('profile.newPass')}
          placeholder={t('profile.newPassP')}
          type="password"
          value={values.new_password}
          onChange={(e) => onChange(e.target.value, 'new_password')}
          errorText={errors.new_password}
        />
        <InputField
          label={t('profile.confirmPass')}
          placeholder={t('profile.confirmPassP')}
          type="password"
          value={values.confirm_password}
          onChange={(e) => onChange(e.target.value, 'confirm_password')}
          errorText={errors.confirm_password}
        />
      </div>
      <div className={'ProfileChangePassword__foot'}>
        <MButton
          color={'primary'}
          variant="highlighted"
          disabled={isSubmitting}
          onClick={submit}
        >
          {t('profile.saveChanges')}
        </MButton>
      </div>
    </div>
  );
};
