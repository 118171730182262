import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getWizCompletedCount } from '../../../../api/bid';
import {
  getPublicStartedCoursesV3,
  subjectMetricV3,
} from '../../../../api/courseV3';
import { getCourseProgressV3 } from '../../../../api/digitalPassV3';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import { useMyCourses } from '../../../../entities/Courses';
import { ProfileCardNew } from '../../../../features/profile-card-new';
import useFetch from '../../../../hooks/useFetch';
import { Link } from '../../../../horizon-components/Link';
import { MButton } from '../../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardItem } from '../../../../models/Course';
import { CourseCard } from '../../../../widgets/CourseCard';
import { DeletedProfile } from './DeletedProfile';
import './PublicProfile.scss';
import { PublicProfileHeader } from './PublicProfileHeader';
import { ProfileStats } from './profile-stats';
import { ProfileStatsGroup } from './profile-stats-group';

export const PublicProfile: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { id: profileId } = useParams();
  const {
    data: verifiedCourses,
    isLoading: isVerifiedLoading,
    fetchData: fetchVerifiedData,
  } = useFetch(getPublicStartedCoursesV3);
  const { data: metric, fetchData: fetchMetric } = useFetch(subjectMetricV3);
  const { data: courses, fetchData: fetchCoursesProgress } =
    useFetch(getCourseProgressV3);
  const { data: wiz_completed, fetchData: fetchWizCompleted } =
    useFetch(getWizCompletedCount);

  useEffect(() => {
    if (profileId) {
      fetchCoursesProgress(profileId);
      fetchWizCompleted(profileId);
      fetchMetric(profileId);
      fetchVerifiedData(profileId);
    }
  }, [profileId, t]);

  if (profileId === 'undefined') {
    return <DeletedProfile />;
  }

  return (
    <div className="PublicProfile">
      <PublicProfileHeader />
      <div className={`PublicProfile__content`}>
        <ProfileCardNew
          className="lg:w-full"
          gpa_avg={metric?.gpa_avg}
          completed={wiz_completed?.quiz_count}
        />
        <ProfileStats
          className="hidden lg:flex lg:w-full"
          metric={metric}
          courses={courses?.results}
        />
      </div>
      <ProfileStatsGroup metric={metric} courses={courses} />
      <div className={`PublicProfile__content !items-start lg:grid-cols-2`}>
        <div className="w-full lg:col-span-2">
          {isVerifiedLoading ? (
            <Loader />
          ) : !!verifiedCourses?.length ? (
            <div className={'flex w-full flex-wrap gap-2 lg:gap-5'}>
              <div
                className={
                  'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                }
              >
                {verifiedCourses?.map((course, i) => (
                  <CourseCard
                    course={course as unknown as CourseCardItem}
                    key={course.id}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>
              <NotFound text={' '} />
              <Link to={MainRoutes.edu + EduRoutes.courses}>
                <MButton
                  variant="highlighted"
                  color="primary"
                  className="m-auto w-full lg:w-auto"
                  size="lg"
                >
                  {t('buttons.Find_courses')}
                </MButton>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
