import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeParentSubscriptionNotification,
  getParentSubscriptionNotification,
  setParentSubscriptionNotification,
} from '../../../api/course';
import { BottomSheet } from '../../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../../horizon-components/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import { Modal } from '../../../horizon-components/Modal';
import { TMailing } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import './Mailing.scss';

interface MailingProps {
  className?: string;
}

export const Mailing = ({ className }: MailingProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mailing, setMailing] = useState<TMailing>();
  const [email, setEmail] = useState('');

  useEffect(() => {
    user?.guid && getMailing();
  }, []);

  const getMailing = () => {
    getParentSubscriptionNotification().then((res) => {
      if (res?.id) {
        setMailing(res);
        setEmail(res.parent_email);
      }
    });
  };

  const hndlMailing = () => {
    setIsLoading(true);
    setParentSubscriptionNotification(email)
      .then((res) => {
        if (res?.id) {
          setMailing(res);
          setEmail(res.parent_email);
        }
      })
      .finally(() => {
        setIsLoading(false);
        hndlOpen();
      });
  };

  const hndlChange = () => {
    if (mailing?.id) {
      setIsLoading(true);
      changeParentSubscriptionNotification(mailing?.id, email)
        .then((res) => {
          if (res?.id) {
            setMailing(res);
            setEmail(res.parent_email);
          }
        })
        .finally(() => {
          setIsLoading(false);
          hndlOpen();
        });
    }
  };

  const hndlOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames('Mailing', className)}>
      <div>
        <h4>{mailing?.parent_email || 'Будьте в курсе!'}</h4>
        <p>
          {mailing?.id
            ? t('mailing.A_report_will_be_sent_to_this_email_address')
            : t('mailing.Receive_weekly_progress_reports_by_email')}
        </p>
      </div>
      <MButton
        variant={mailing?.id ? 'secondary' : 'highlighted'}
        color={'primary'}
        onClick={hndlOpen}
      >
        {mailing?.id ? t('buttons.Edit') : t('buttons.Receive_a_newsletter')}
      </MButton>

      {window.innerWidth > 768 ? (
        <Modal
          title={t('mailing.Subscribe_to_the_newsletter')}
          isOpen={isOpen}
          onClose={hndlOpen}
        >
          <>
            <MInput
              label={t('buttons.Specify_the_Email_Address')}
              placeholder="Email"
              bottomText={t('mailing.A_weekly_report_will_be_sent_to_the_address')}
              inputClassName="w-full"
              onChange={setEmail}
              value={email}
            />
            <div className="flex justify-end">
              <MButton
                variant={'highlighted'}
                color={'primary'}
                onClick={mailing?.id ? hndlChange : hndlMailing}
                loading={isLoading}
                disabled={isLoading}
              >
                {t('buttons.Confirm')}
              </MButton>
            </div>
          </>
        </Modal>
      ) : (
        <BottomSheet open={isOpen} onDismiss={hndlOpen}>
          <>
            <MInput
              label={t('buttons.Specify_the_Email_Address')}
              placeholder="Email"
              bottomText={t('mailing.A_weekly_report_will_be_sent_to_the_address')}
              inputClassName="w-full"
              onChange={setEmail}
              value={email}
            />
            <div className="flex justify-end">
              <MButton
                variant={'highlighted'}
                color={'primary'}
                onClick={mailing?.id ? hndlChange : hndlMailing}
                loading={isLoading}
                disabled={isLoading}
              >
                {t('buttons.Confirm')}
              </MButton>
            </div>
          </>
        </BottomSheet>
      )}
    </div>
  );
};
