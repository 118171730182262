import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  getDailyMultiplierInfo,
  getUserMetrics,
  Question,
  setForceCompleteQuiz,
  setWizStart,
  TCA,
  TDailyMultiplierInfo,
  TStartQuiz,
  WizDailyStatusEnum,
} from '../../api/wiz/wiz';
import useFetch from '../../hooks/useFetch';
import { useUserContext } from '../UserProvider';
import { WIZ_LIVE } from '../../shared/const/wiz';
import { removeStorage } from '../../api';

type WizProviderType = {
  livesCount: number;
  dailyInfo: null | TDailyMultiplierInfo[];
  currentMultiplier: number;
  currentDay: number;
  isLivesDataLoading: boolean;
  isDailyInfoLoading: boolean;
  nextLifeRecoveryTime: number;
  fetchLivesData: () => Promise<any>;
  fetchDailyInfo: () => Promise<void>;
  setLivesCount: React.Dispatch<React.SetStateAction<number>>;
  fetchForceCompleteQuiz: () => Promise<void>;
  currentTest: TStartQuiz | null;
  handleCloseTest: () => void;
  isCurrentAuthTestLoading: boolean;
  getQuestions: () => void;
  question: Question | null;
  questions: Question[] | null;
  answers: TCA[] | null;
  fetchStartQuiz: (categoryId: number) => Promise<void>;
};

const WizContext = createContext<WizProviderType>({
  livesCount: WIZ_LIVE,
  dailyInfo: null,
  currentMultiplier: 1,
  currentDay: 1,
  isLivesDataLoading: true,
  isDailyInfoLoading: true,
  nextLifeRecoveryTime: 0,
  fetchLivesData: async () => {},
  fetchDailyInfo: async () => {},
  setLivesCount: () => {},
  fetchForceCompleteQuiz: async () => {},
  currentTest: null,
  handleCloseTest: () => {},
  isCurrentAuthTestLoading: false,
  getQuestions: () => {},
  question: null,
  questions: null,
  answers: null,
  fetchStartQuiz: async () => {},
});

const WizProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { user, removeUserSomeData, getUserSomeData, setUserSomeData } =
    useUserContext();
  const [currentDay, setCurrentDay] = useState(1);
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [dailyInfo, setDailyInfo] = useState<TDailyMultiplierInfo[] | null>(null);
  const [isDailyInfoLoading, setIsDailyInfoLoading] = useState(true);
  const [livesCount, setLivesCount] = useState(3);
  const [currentTest, setCurrentTest] = useState<TStartQuiz | null>(null);
  const [isCurrentAuthTestLoading, setIsCurrentAuthTestLoading] = useState(false);
  const [question, setQuestion] = useState<Question | null>(null);
  const [questions, setQuestions] = useState<Question[] | null>(null);
  const [answers, setAnswers] = useState<TCA[] | null>(null);

  const {
    data: livesData,
    isLoading: isLivesDataLoading,
    fetchData: fetchLivesData,
  } = useFetch(getUserMetrics);

  const { fetchData: fetchStart } = useFetch((category: number) =>
    setWizStart(category)
  );

  const fetchStartQuiz = async (categoryId: number) => {
    const startResult = await fetchStart(categoryId);
    startResult?.questions.length && setUserSomeData('quiz_code', startResult?.ca);
    startResult?.questions.length &&
      setUserSomeData('questions', startResult?.questions);
    startResult?.start_quiz?.id && setUserSomeData('quiz', startResult?.start_quiz);
    startResult?.start_quiz?.id && setCurrentTest(startResult?.start_quiz);
    if (startResult) {
      user?.guid && fetchLivesData();
      getQuestions();
    }
  };

  const fetchDailyInfo = async () => {
    setIsDailyInfoLoading(true);
    const dailyInfo = await getDailyMultiplierInfo();
    const currentDay_ = dailyInfo.find(
      (dayInfo) => dayInfo.status === WizDailyStatusEnum.ACTIVE
    );
    setDailyInfo(dailyInfo);
    setCurrentDay(currentDay_?.day || 1);
    setCurrentMultiplier(currentDay_?.multiplier || 1);
    setIsDailyInfoLoading(false);
  };

  const fetchForceCompleteQuiz = async () => {
    const quiz = getUserSomeData('quiz') as unknown as TStartQuiz;

    try {
      quiz?.id && (await setForceCompleteQuiz(quiz?.id));
    } finally {
      handleCloseTest();
    }
  };

  useEffect(() => {
    if (!livesData) return;
    setLivesCount(livesData.lives);
  }, [livesData]);

  const handleCloseTest = () => {
    setCurrentTest(null);

    removeUserSomeData('quiz');
    removeUserSomeData('questions');
    removeUserSomeData('quiz_code');
    removeUserSomeData('answers');
  };

  const getQuestions = useCallback(async () => {
    const questions = getUserSomeData('questions') as unknown as Question[];
    setQuestions(questions);

    questions?.[0]?.id && setQuestion(questions?.[0]);
    questions?.length && setUserSomeData('questions', questions.slice(1));

    const answers = getUserSomeData('answers') as unknown as TCA[];
    answers?.length && setAnswers(answers);
  }, [getUserSomeData, setUserSomeData]);

  const getCurrentData = useCallback(() => {
    const questions = getUserSomeData('questions') as unknown as Question[];
    setQuestions(questions);

    questions?.[0]?.id && setQuestion(questions?.[0]);
    questions?.length && setUserSomeData('questions', questions.slice(1));

    const answers = getUserSomeData('answers') as unknown as TCA[];
    answers?.length && setAnswers(answers);

    const quiz = getUserSomeData('quiz') as unknown as TStartQuiz;
    quiz?.id && setCurrentTest(quiz);
  }, [getUserSomeData, setUserSomeData]);

  useEffect(() => {
    fetchDailyInfo();
    if (user) {
      fetchLivesData();
    }
    getCurrentData();
  }, []);

  return (
    <WizContext.Provider
      value={{
        livesCount,
        nextLifeRecoveryTime: livesData?.next_life_recovery_time || 0,
        currentDay,
        currentMultiplier,
        dailyInfo,
        isLivesDataLoading,
        isDailyInfoLoading,
        fetchLivesData,
        fetchDailyInfo,
        setLivesCount,
        fetchForceCompleteQuiz,
        currentTest,
        handleCloseTest,
        isCurrentAuthTestLoading,
        getQuestions,
        question,
        questions,
        answers,
        fetchStartQuiz,
      }}
    >
      {children}
    </WizContext.Provider>
  );
};

const useWizContext = () => useContext(WizContext);

export { useWizContext, WizProvider };
