import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  checkEnrolledUserCourse,
  enrollUserCourse,
  setPrivateCoursePurchaseV3,
} from '../../api/course';
import { saveCurrentUrl } from '../../api/sessions';
import CoinIcon from '../../assets/icons/auction/erp.svg';
import { sendCoursePurchase, sendSpendEDRES } from '../../hooks/useAnalytics';
import { useNavigate } from '../../hooks/useNavigate';
import { MButton } from '../../horizon-components/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../providers';
import { useRewardContext } from '../../providers/RewardProvider';
import { AppDispatch } from '../../stores';
import { setIsOpenLevelModal } from '../../stores/subject';
import getErrorMessages from '../../utils/getErrorMessages';

interface Props {
  courseId?: number;
  className?: string;
  isLogo?: boolean;
}

export const CourseEnroll: FC<Props> = ({ courseId, className, isLogo }) => {
  const { t } = useTranslation(['translation']);
  const { id: paramId } = useParams<{ id: string }>();
  const id = courseId || Number(paramId);
  const navigate = useNavigate();
  const location = useLocation();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const { user, updateWallet } = useUserContext();
  const [amount, setAmount] = useState(0);
  const { rewards } = useRewardContext();
  const dispatch: AppDispatch = useDispatch();
  const onCloseLevelModal = () => {
    dispatch(setIsOpenLevelModal(false));
  };

  const currentPath = location.pathname;
  const extracted = '/' + currentPath.split('/').slice(2).join('/');

  useEffect(() => {
    if (rewards?.length) {
      const value = rewards?.find(
        (item) => item.tokenomic_type === 'course_purchase'
      )?.value;

      if (value) setAmount(parseFloat(value));
    }
  }, [rewards, t]);

  const checkEnroll = useCallback(() => {
    checkEnrolledUserCourse(Number(id))
      .then((data) => {
        setIsEnrolled(data.is_enrolled);
      })
      .catch(() => {
        setIsEnrolled(false);
      });
  }, [id]);

  useEffect(() => {
    user?.guid && checkEnroll();
  }, [user?.guid]);

  const enrollCourse = () => {
    setIsPurchased(true);
    return setPrivateCoursePurchaseV3(Number(id || 0))
      .then(() =>
        enrollUserCourse(Number(id || 0))
          .then(() => {
            sendCoursePurchase();
            sendSpendEDRES();
            updateWallet();
            onCloseLevelModal();
            navigate(MainRoutes.edu + EduRoutes.enroll + `/${id}`, {
              state: { from: extracted },
            });
          })
          .catch((error) => {
            toast(getErrorMessages(error?.response?.data), {
              type: 'error',
            });
          })
      )
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => setIsPurchased(false));
  };

  const enrollBtnHandler = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();

    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    if (!isEnrolled) {
      enrollCourse();
    } else {
      goToCourse();
    }
  };

  const goToCourse = () => {
    onCloseLevelModal();
    navigate(MainRoutes.edu + EduRoutes.enroll + `/${id}`, {
      state: { from: extracted },
    });
  };

  return isEnrolled ? (
    <MButton
      onClick={goToCourse}
      color={'primary'}
      variant={'highlighted'}
      className={`${className}`}
    >
      {t('btnsText.continue')}
    </MButton>
  ) : (
    <MButton
      onClick={(event) => enrollBtnHandler(event)}
      color={'primary'}
      variant={'highlighted'}
      className={`${className}`}
      loading={isPurchased}
      disabled={isPurchased}
    >
      {t('nft.buy_for')} {amount || 0}{' '}
      {isLogo ? <img src={CoinIcon} alt="" /> : 'EdRes'}
    </MButton>
  );
};
