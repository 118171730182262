import React, { ReactNode, useEffect, useState } from 'react';
import { BottomSheet as ReactBottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { useModal } from '../../providers';
import './style.scss';

interface BottomSheetProps {
  children: ReactNode;
  open?: boolean;
  onDismiss?: () => void;
  modalId?: string;
  noScroll?: boolean;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  open,
  onDismiss,
  modalId,
  noScroll = false,
}) => {
  const { isModalOpen, closeModal } = useModal();

  const hndlClose = () => {
    onDismiss?.();
    if (modalId) closeModal(modalId);
  };

  return (
    <ReactBottomSheet
      onDismiss={hndlClose}
      open={open || isModalOpen(modalId || '')}
      className={noScroll ? 'disable-scroll' : undefined}
      style={{ zIndex: 50, position: 'relative' }}
    >
      <div className="z-50 flex flex-col items-center px-2 pb-2">{children}</div>
    </ReactBottomSheet>
  );
};
