import {
  useNavigate as useRouterNavigate,
  NavigateOptions,
  To,
} from 'react-router-dom';
import { useLanguage } from '../providers/LanguageProvider';
import getPlatform from '../utils/getPlatform';
import { PlatformsEnum } from '../entities/Platforms/platforms';

export const useNavigate = (): ((
  to: string | To | number,
  options?: NavigateOptions
) => void) => {
  const navigate = useRouterNavigate();
  const { language } = useLanguage();

  return (to: To | number | string, options?: NavigateOptions) => {
    const platform = getPlatform();

    if (typeof to === 'number') {
      navigate(to); // Обработка числового перехода (например, -1 для возврата назад)
      return;
    }

    if (
      typeof to === 'string' &&
      platform === PlatformsEnum.TELEGRAM &&
      (to.includes('t.me') || to.includes('telegram.me'))
    ) {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !('Stream' in window);
      const modifiedURL = isIOS
        ? to.replace('telegram.me', 't.me')
        : to.replace('t.me', 'telegram.me');

      if (isIOS) {
        window.Telegram!.WebApp.openTelegramLink(modifiedURL);
      } else {
        window.Telegram!.WebApp.openLink(modifiedURL, { try_instant_view: false });
      }
      return;
    }

    if (
      typeof to === 'string' &&
      (to.startsWith('http://') || to.startsWith('https://')) &&
      process.env.REACT_APP_API_URL &&
      !to.includes(process.env.REACT_APP_API_URL)
    ) {
      if (platform === PlatformsEnum.TELEGRAM) {
        window.Telegram!.WebApp.openLink(to, { try_instant_view: false });
      } else {
        window.open(to, '_blank');
      }
      return;
    }

    if (typeof to !== 'string') {
      const path = {
        ...to,
        pathname: `/${language}${to.pathname}`,
      };
      navigate(path, options);
      return;
    }

    const path = `/${language}${to.startsWith('/') ? '' : '/'}${to}`;
    navigate(path, options);
  };
};
