import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PlatformsEnum } from '../../entities/Platforms/platforms';
import { MainRoutes } from '../../horizon-layout/MainLayout/Routes/types/routes';
import getPlatform from '../../utils/getPlatform';
import './style.scss';

interface MainPageLearnMoreProps {
  className?: string;
}

export const MainPageLearnMore = ({ className }: MainPageLearnMoreProps) => {
  const { t } = useTranslation(['translation']);
  const platform = getPlatform();
  return (
    <Link
      to={
        platform !== PlatformsEnum.TELEGRAM
          ? MainRoutes.support
          : 'https://t.me/edres_community'
      }
    >
      <div className={classNames('MainPageLearnMore', className)}>
        <div className={classNames('MainPageLearnMore__content')}>
          <h4>{t('main.learn_more_about_edres')}</h4>
          <p>
            {t('main.learn_more_about_edres_description')}{' '}
            <span>{t('main.learn_more_about_edres_link')}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};
