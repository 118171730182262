import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Badge } from '../../Badge';
import { SelectOptions } from '../../MMultSelect/MMultiSelect';
import './MultiSearch.scss'; // Добавьте стили для выпадающего меню и тегов

type MultiSearchProps = {
  className?: string;
  placeholder?: string;
  options: SelectOptions[];
  selectedOptions: SelectOptions[];
  onSelect: (option: SelectOptions[]) => void;
  onChange: (inputValue: string) => void;
  inputValue: string;
};

export const MultiSearch: React.FC<MultiSearchProps> = ({
  className,
  placeholder,
  options,
  selectedOptions,
  onSelect,
  onChange,
  inputValue,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<SelectOptions[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      const existingOption = options.find(
        (option) => option.label.toLowerCase() === inputValue.toLowerCase()
      );

      if (existingOption) {
        let newSelect = [];
        if (
          !selectedOptions.some((option) => option.value === existingOption.value)
        ) {
          onChange('');
          setFilteredOptions(options);
          newSelect = [...selectedOptions, existingOption];
        } else {
          newSelect = selectedOptions;
        }

        onSelect(newSelect);
      } else {
        await onChange(inputValue);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [inputValue, options, onChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange(value);

    if (value.length > 0) {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setIsDropdownVisible(true);
    } else {
      setFilteredOptions(options);
    }
  };

  const handleOptionClick = (option: SelectOptions) => {
    let newSelect = [];

    if (!selectedOptions.some((selected) => selected.value === option.value)) {
      newSelect = [...selectedOptions, option];
    } else {
      newSelect = selectedOptions;
    }

    onChange('');
    onSelect(newSelect);
    setFilteredOptions(options);
    setIsDropdownVisible(false);
  };

  const handleRemoveOption = (optionToRemove: SelectOptions) => {
    onSelect(
      selectedOptions.filter((option) => option.value !== optionToRemove.value)
    );
    setFilteredOptions(options);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      target.closest('.Badge') ||
      target.closest('input') ||
      target.closest('.MultiSearch__dropdown')
    ) {
      return;
    }

    inputRef.current?.focus();
    setIsDropdownVisible((prev) => !prev);
  };

  const handleInputClick = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  return (
    <div
      className={classNames('MultiSearch', className)}
      ref={containerRef}
      onClick={handleClick}
    >
      <div className="MultiSearch__options">
        {selectedOptions.map((option) => (
          <Badge
            size="sm"
            variant="primary"
            key={option.value}
            onClick={() => handleRemoveOption(option)}
            className="!gap-2 text-nowrap"
            active
          >
            {option.label}
            <button onClick={() => handleRemoveOption(option)}>x</button>
          </Badge>
        ))}
        <input
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onClick={handleInputClick}
          placeholder={placeholder}
        />
      </div>
      {isDropdownVisible && filteredOptions.length > 0 && (
        <div className="MultiSearch__dropdown">
          {filteredOptions.map((option) => (
            <div key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label} <span>{option.count}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
