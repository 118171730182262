export const ISO: Record<string, string> = {
  en: 'English',
  ru: 'Русский',
  kk: 'Қазақша',
  kz: 'Қазақша',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  it: 'Italiano',
  zh: '中文',
  ja: '日本語',
};
