import { Stream } from '@cloudflare/stream-react';
import React, { useEffect, useMemo, useState } from 'react';
import { getVideoTranslationFile } from '../../../../api/course';
import { Loader } from '../../../../components/Loader';
import {
  MediaViewer,
  VTTConfigItem,
} from '../../../../components/MediaViewer/MediaViewer';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import { CoursePreviewNavigation } from './course-preview-navigation/course-preview-navigation';
import { CoursePreviewTopicTest } from './course-preview-topic-test';
import { CoursePreviewUnitExam } from './course-preview-unit-exam';
import { CoursePreviewAssistant } from './course-preview-assistant';

export const CoursePreviewDitail: React.FC = () => {
  const {
    currentUnit,
    languages,
    activeTab,
    setCurrentUnitExamId,
    currentUnitExamId,
    setCurrentTopicIndex,
    currentTopicIndex,
    setIsTopicTestActive,
    isTopicTestActive,
  } = useCourseCreation();
  const [vttFiles, setVttFiles] = useState<VTTConfigItem[]>([]);
  const [vttDownloaded, setVttDownloaded] = useState(false);

  useEffect(() => {
    setCurrentTopicIndex(0);
    !currentUnitExamId && setCurrentUnitExamId(null);
    setIsTopicTestActive(!!currentUnitExamId);
  }, [activeTab]);

  const sortTopics = currentUnit?.topics?.sort((a, b) => {
    if (a.order === undefined && b.order === undefined) {
      return 0;
    }
    if (a.order === undefined) {
      return 1;
    }
    if (b.order === undefined) {
      return -1;
    }
    return a.order - b.order;
  });

  const totalTopics = sortTopics?.length || 0;
  const currentTopic = sortTopics?.[currentTopicIndex || 0];
  const disablePrev = currentTopicIndex === 0 && !isTopicTestActive;

  const topicMedia = useMemo(
    () =>
      currentTopic?.contents
        ?.filter((content) => content.resourcetype === 'FileContent')
        .sort((a, b) => {
          const idA = a.id !== undefined ? a.id : Infinity;
          const idB = b.id !== undefined ? b.id : Infinity;
          return idA - idB;
        }),
    [currentTopic?.contents]
  );
  const contents = currentTopic?.contents
    ?.filter((content) => content.resourcetype === 'TextContent')
    .sort((a, b) => {
      const idA = a.id !== undefined ? a.id : Infinity;
      const idB = b.id !== undefined ? b.id : Infinity;
      return idA - idB;
    });
  const topicText = contents?.[contents?.length - 1];
  const cfVideoStream = topicMedia?.[topicMedia?.length - 1]?.cf_video_stream;
  const cfVideoGuid = cfVideoStream?.cf_signed_video_guid ?? cfVideoStream?.cf_uid;
  const videoUrl = topicMedia?.[topicMedia?.length - 1]?.file;

  const onNextClick = () => {
    if (
      !isTopicTestActive &&
      !!sortTopics?.[currentTopicIndex || 0]?.questions?.length
    ) {
      setIsTopicTestActive(true);
      return;
    }

    if (totalTopics ? totalTopics - 1 === currentTopicIndex : true) {
      setIsTopicTestActive(true);
      currentUnit?.id && setCurrentUnitExamId(currentUnit?.id);
      return;
    }
    if (!currentUnitExamId) {
      setIsTopicTestActive(false);
      setCurrentTopicIndex((currentTopicIndex || 0) + 1);
    }
  };

  const onPrevClick = () => {
    if (disablePrev) return;
    setCurrentUnitExamId(null);
    if (isTopicTestActive) {
      setIsTopicTestActive(false);
      return;
    }
    setCurrentTopicIndex((currentTopicIndex || 0) - 1);
  };

  useEffect(() => {
    const mediaId = topicMedia?.[topicMedia?.length - 1]?.id;
    if (mediaId) {
      getVideoTranslationFile(mediaId)
        .then((data) => {
          if (data?.results?.length) {
            const _vtt = data?.results?.map((item) => {
              const lang = languages?.results?.find(
                (el) => el?.title === item?.language
              );

              return {
                srcLang: lang?.title || '',
                src: item?.file,
              };
            });
            setVttFiles(_vtt);
          }
        })
        .finally(() => {
          setVttDownloaded(true);
        });
    }
  }, [languages, topicMedia]);

  return (
    <div className="CoursePreview__info content">
      <div className="flex w-full items-center justify-between gap-3">
        <div
          className={
            'overflow-hidden overflow-ellipsis font-bold text-secondary-dark-grey-900 sm-max:hidden md:text-[20px]'
          }
        >
          {currentTopic?.title}
        </div>
        <CoursePreviewNavigation
          currentTopicIndex={currentTopicIndex || 0}
          currentUnitExamId={currentUnitExamId}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
        />
      </div>
      {!isTopicTestActive && !currentUnitExamId ? (
        <>
          {topicMedia && topicMedia?.length > 0 && (
            <>
              {cfVideoGuid ? (
                <div className="relative overflow-hidden">
                  <Stream
                    src={cfVideoGuid}
                    className="stream z-10 !pt-[380px] md:!pt-[450px] lg:!pt-[530px]"
                    width="100%"
                    height="100%"
                    controls
                    key={currentTopic?.id}
                  />
                  <Loader className="absolute top-[50%] z-0" />
                </div>
              ) : (
                videoUrl && (
                  <MediaViewer
                    link={videoUrl || ''}
                    vttFiles={vttFiles}
                    vttDownloaded={vttDownloaded}
                  />
                )
              )}
            </>
          )}
          {topicText && topicText?.text && (
            <div dangerouslySetInnerHTML={{ __html: topicText?.text }} />
          )}
          <CoursePreviewAssistant topicId={currentTopic?.id} />
        </>
      ) : currentUnitExamId ? (
        <CoursePreviewUnitExam />
      ) : (
        <CoursePreviewTopicTest topic={currentTopic} />
      )}
    </div>
  );
};
