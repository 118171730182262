import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as StarFull } from '../../../assets/icons/rating/star-full.svg';
import { Tags } from '../../../components/Tags';
import { CourseFavorite } from '../../../features/CourseFavorite';
import { useNavigate } from '../../../hooks/useNavigate';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardItem, CourseCardStatus } from '../../../models/Course';
import './CourseCard.scss';

interface CourseCardProps {
  className?: string;
  course?: CourseCardItem;
  variant?: 'default' | 'recomended';
  inCreateCourse?: boolean;
  hideFavorites?: boolean;
  inVerifyCourse?: boolean;
  showBtns?: boolean;
  favorite?: boolean;
  status?: CourseCardStatus;
  onFavorite?: () => void;
}

export const CourseCard = ({
  className,
  course,
  variant = 'default',
  inCreateCourse,
  hideFavorites,
  inVerifyCourse,
  showBtns = true,
  favorite,
  status = CourseCardStatus.CAN_ENROLL,
  onFavorite,
}: CourseCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const tags = useMemo(() => {
    const data: { id: number | string; title: string }[] = [];

    course?.digital_pass?.title &&
      data.push({ id: course.subject.slug, title: course.subject.title });

    course?.digital_pass?.title &&
      data.push({
        id: course.digital_pass.title,
        title: course.digital_pass.title,
      });

    course?.level &&
      data.push({
        id: course?.level,
        title: `${t('course.Grade')}: ${course?.level}`,
      });

    return data;
  }, [course]);

  if (!course) return null;

  const goToView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate(MainRoutes.edu + EduRoutes.course + '/' + course?.id);
  };
  const goToPerformance = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate(MainRoutes.edu + EduRoutes.performance + `/${course.id}`);
  };
  const goToVerifyCourse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate(MainRoutes.edu + EduRoutes.preview + `/${course.id}`);
  };

  return (
    <div className="relative">
      {!hideFavorites && (
        <CourseFavorite
          favoriteId={course.id}
          isFavorite={favorite}
          className="!absolute right-2 top-2"
          onFavorite={onFavorite}
        />
      )}
      <Link
        className={classNames('CourseCard', variant, className, {
          'md:!max-h-[256px]': inCreateCourse,
        })}
        style={{ backgroundImage: `url(${course.cover_image})` }}
        to={
          inCreateCourse
            ? MainRoutes.edu + EduRoutes.course + '/' + course?.id
            : inVerifyCourse
              ? MainRoutes.edu + EduRoutes.preview + `/${course.id}`
              : MainRoutes.edu + EduRoutes.course + '/' + course?.id
        }
      >
        <div className="CourseCard__head">
          <div className="truncate">
            <h5>{course.title}</h5>
            <div className="CourseCard__head-stats">
              {!inVerifyCourse && (
                <span>
                  <StarFull /> {course?.average_rating}
                </span>
              )}
              {!!course?.enrolls_count && (
                <>
                  <span className="!font-semibold">•</span>
                  <span>{`${course?.enrolls_count} ${t('course.enrolls')}`}</span>
                </>
              )}
              {!!course?.units_count && !course.subject.is_miscellaneous_subject && (
                <>
                  <span className="!font-semibold">•</span>
                  <span>{`${course?.units_count} ${t('course.units')}`}</span>
                </>
              )}
            </div>
          </div>
          <div className="w-11"></div>
        </div>
        <div className={classNames('CourseCard__foot', variant)}>
          {!!showBtns && !course.subject.is_miscellaneous_subject ? (
            <Tags tags={tags} />
          ) : (
            <div></div>
          )}

          <MButton
            color="white"
            size={variant === 'recomended' ? 'xs' : 'sm'}
            variant="highlighted"
            onClick={(e) => {
              e.stopPropagation();
              inCreateCourse
                ? goToPerformance(e)
                : inVerifyCourse
                  ? goToVerifyCourse(e)
                  : goToView(e);
            }}
          >
            {inCreateCourse ? (
              t('btnsText.performance')
            ) : inVerifyCourse ? (
              t('btnsText.checkCourse')
            ) : status === CourseCardStatus.ENROLLED ? (
              <>{t('btnsText.continue')}</>
            ) : status === CourseCardStatus.PREVIEW ? (
              <>
                {t('course.Preview')} <Eye />
              </>
            ) : (
              <>
                {t('btnsText.enroll')} <Plus />
              </>
            )}
          </MButton>
        </div>
      </Link>
    </div>
  );
};
