import React from 'react';
import { useTranslation } from 'react-i18next';
import { Auction, Bid } from '../../../../models/Auction';
import { MButton } from '../../../../horizon-components/MButton';
import { CoinIcon } from '../../../../components/CoinIcon';

type AuctionViewWinnerProps = {
  isMultiWinners: boolean;
  winners: Bid[];
  auction: Auction;
  onClose?: () => void;
};

export const AuctionViewWinner: React.FC<AuctionViewWinnerProps> = ({
  isMultiWinners,
  winners,
  auction,
  onClose,
}) => {
  const { t } = useTranslation(['translation']);

  return (
    <div>
      <p
        className={
          'mb-[10px] truncate text-center text-[18px] font-bold text-purple-500'
        }
      >
        {auction?.title}
      </p>
      {!isMultiWinners ? (
        <div className="inline-flex w-full items-center justify-start gap-3 rounded-2xl bg-[#e9e3ff] p-2">
          <img
            src={
              winners?.[0]?.user?.user_meta?.profile_image ||
              require('../../../../assets/images/noImageUser/anonymous1.jpg')
            }
            alt={'av'}
            className="relative h-[58px] w-[58px] rounded-[35px]"
          />
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5 truncate">
            <div className="w-full truncate text-sm font-semibold leading-[18px] text-[#2b3674]">
              {winners?.[0]?.user?.user_meta?.username}
            </div>
            <div className="inline-flex items-center justify-center gap-1.5">
              <div className="text-center text-2xl font-bold leading-7 text-[#2d396b]">
                {!!winners?.[0]?.bid_amount &&
                  Math.floor(Number(winners?.[0]?.bid_amount)).toLocaleString(
                    'ru-RU'
                  )}
              </div>
              <CoinIcon />
            </div>
            <div className="text-[10px] font-bold leading-3 text-[#4318ff]">
              {t('bid.TopBid').toUpperCase()}
            </div>
          </div>
        </div>
      ) : (
        <div className={'flex w-full justify-center  rounded-2xl bg-[#e9e3ff] p-2'}>
          {winners
            ?.splice(0, 4)
            ?.map((winner, i) => (
              <img
                key={i}
                src={
                  winner?.user?.user_meta?.profile_image ||
                  require('../../../../assets/images/no-avatar.png')
                }
                className={
                  'ml-[-5px] h-[64px] w-[64px] overflow-hidden rounded-full border-[1px] border-white object-cover'
                }
                alt={'av'}
              />
            ))}
          {winners.length > 4 && (
            <div
              className={
                'ml-[-5px] flex h-[64px] w-[64px] items-center justify-center overflow-hidden rounded-full border-[1px] border-white bg-purple-100'
              }
            >
              <p
                className={
                  'text-center text-[16px] font-bold leading-[19px] text-purple-500'
                }
              >
                +{winners?.length}
                <br />
                {t('bid.more')}
              </p>
            </div>
          )}
        </div>
      )}
      <div className={'mt-[16px] flex flex-col items-center justify-center'}>
        <MButton variant={'highlighted'} color={'primary'} onClick={onClose}>
          {t('bid.Congratulations')}
        </MButton>
      </div>
    </div>
  );
};
