import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputField from '../../../horizon-components/Input/InputField';
import { useUserContext } from '../../../providers';
import { getUserData } from '../../../utils/getUserData';
import { useMemo, useState } from 'react';
import useForm, { ErrorMessagesCode } from '../../../hooks/useForm';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { changeUserMeta } from '../../../api/auth';
import { saveUserStorage } from '../../../api';
import * as yup from 'yup';
import getErrorMessages from '../../../utils/getErrorMessages';
import { toast } from 'react-toastify';
import './ProfileAccountSettings.scss';
import { ProfileCard } from '../../ProfileCard';

interface ProfileAccountSettingsProps {
  className?: string;
}

type Form = {
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  education: string;
  major: string;
  degree: string;
  position: string;
};

export const ProfileAccountSettings = ({
  className,
}: ProfileAccountSettingsProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { username } = getUserData(user || undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, onChange, errors, validate } = useForm<Form>({
    username: username || '',
    firstName: user?.user_meta?.first_name || '',
    lastName: user?.user_meta?.last_name || '',
    phoneNumber: user?.user_meta?.phone_number || '',
    address: user?.user_meta?.address || '',
    education: user?.user_meta?.education || '',
    major: user?.user_meta?.major || '',
    degree: user?.user_meta?.degree || '',
    position: user?.user_meta?.position || '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        username: yup.string().required(t(ErrorMessagesCode.required)),
        firstName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        phoneNumber: yup.string().nullable(),
        address: yup.string().nullable(),
        education: yup.string().nullable(),
        major: yup.string().nullable(),
        degree: yup.string().nullable(),
        position: yup.string().nullable(),
      }),
    [t]
  );

  const submit = async () => {
    const hasError = await validate(schema);

    if (hasError) {
      return;
    }

    setIsSubmitting(true);
    changeUserMeta(user?.guid || '', {
      username: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber.replace(/[^0-9+]/g, ''),
      address: values.address,
      education: values.education,
      major: values.major,
      degree: values.degree,
      position: values.position,
    })
      .then((data) => {
        if (user) {
          user.user_meta.username = values.username;
          user.user_meta.profile_image = data.profile_image;
          user.user_meta.first_name = values.firstName;
          user.user_meta.last_name = values.lastName;
          user.user_meta.phone_number = values.phoneNumber;
          user.user_meta.address = values.address;
          user.user_meta.education = values.education;
          user.user_meta.major = values.major;
          user.user_meta.degree = values.degree;
          user.user_meta.position = values.position;
          saveUserStorage(user);

          toast(t('profile.success'), {
            type: 'success',
          });
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className={classNames('ProfileAccountSettings', className)}>
      <div className="ProfileAccountSettings__head">
        <h3>{t('profile.account')}</h3>
        <p>{t('profile.info')}</p>
      </div>
      <ProfileCard guid={user?.guid} userMeta={user?.user_meta} edit />
      <div className="ProfileAccountSettings__content">
        <InputField
          label={t('profile.username')}
          placeholder={t('profile.usernameP')}
          type="text"
          value={values.username}
          onChange={(e) => onChange(e.target.value, 'username')}
          errorText={errors.username}
        />
        <InputField
          label={t('profile.email')}
          placeholder={t('profile.email')}
          type="text"
          name={'email'}
          value={user?.email}
          disabled
        />
        <InputField
          type="tel"
          name={'phoneNumber'}
          label={t('profile.phoneNumber')}
          placeholder={t('profile.phoneNumber')}
          errorText={errors.phoneNumber}
          mask="+7 (999) 999-99-99"
          value={values.phoneNumber}
          onChange={(e) => onChange(e.target.value, 'phoneNumber')}
        />
        <InputField
          label={t('profile.firstName')}
          placeholder={t('profile.firstName')}
          type="text"
          name={'firstName'}
          value={values.firstName}
          onChange={(e) => onChange(e.target.value, 'firstName')}
          errorText={errors.firstName}
        />
        <InputField
          label={t('profile.lastName')}
          placeholder={t('profile.lastName')}
          type="text"
          name={'lastName'}
          value={values.lastName}
          onChange={(e) => onChange(e.target.value, 'lastName')}
          errorText={errors.lastName}
        />
      </div>
      <div className={'ProfileAccountSettings__foot mt-1'}>
        <MButton
          color={'primary'}
          variant="highlighted"
          disabled={isSubmitting}
          onClick={submit}
        >
          {t('profile.saveChanges')}
        </MButton>
      </div>
    </div>
  );
};
