import {
  Modal as CUModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal';
import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/modal/close.svg';
import { useModal } from '../../../providers/ModalProvider';
import { MButton } from '../../MButton/ui/MButton';
import './Modal.scss';

export type ModalProps = {
  title?: string;
  isOpen?: boolean;
  onClose?(): void;
  children?: React.ReactNode;
  overlayClassName?: string;
  bodyClassName?: string;
  contentClassName?: string;
  modalId?: string;
  showClose?: boolean; // Отображение крестика для закрытия
  bgClose?: boolean; // Закрыть по клику на задний фон
  minHeight?: number; // Минимальная высота
  minWidth?: number; // Минимальная ширина
  maxHeight?: number; // Максимальная высота
  maxWidth?: number; // Максимальная ширина
  position?: 'top' | 'center' | 'bottom'; // Позиция модального окна
};

export const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  showClose = true,
  overlayClassName = '',
  bodyClassName = '',
  contentClassName = '',
  modalId,
  bgClose = true,
  minHeight,
  minWidth,
  maxHeight,
  maxWidth = 500,
  position = 'center',
}) => {
  const { isModalOpen, closeModal } = useModal();

  const hndlClose = () => {
    onClose?.();
    if (modalId) closeModal(modalId);
  };

  const handleBgClose = () => {
    if (bgClose) hndlClose();
  };

  return (
    <CUModal isOpen={isOpen || isModalOpen(modalId || '')} onClose={handleBgClose}>
      <ModalOverlay
        className={classNames('Modal__bg', overlayClassName)}
        onClick={handleBgClose}
      />
      <ModalContent
        className={classNames(
          'Modal__content',
          `Modal__content--${position}`,
          contentClassName
        )}
        style={{
          minHeight: minHeight ? `${minHeight}px` : undefined,
          minWidth: minWidth ? `${minWidth}px` : undefined,
          maxHeight: maxHeight ? `${maxHeight}px` : undefined,
          maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        }}
      >
        <ModalBody className={classNames('Modal__body', bodyClassName)}>
          {(!!title || showClose) && (
            <div className="Modal__header">
              {title ? <h3>{title}</h3> : null}
              {showClose ? (
                <MButton
                  color="white"
                  variant="highlighted"
                  className="Modal__close !p-2"
                  onClick={hndlClose}
                >
                  <CloseIcon />
                </MButton>
              ) : null}
            </div>
          )}
          {children}
        </ModalBody>
      </ModalContent>
    </CUModal>
  );
};
