import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { patchFeed, ResourceType } from '../../../api/weber/feed';
import { ReactComponent as FavoriteIcons } from '../../../assets/icons/feed/bookmark.svg';
import { ReactComponent as DotsIcons } from '../../../assets/icons/feed/dots.svg';
import { ReactComponent as DraftIcons } from '../../../assets/icons/feed/draft.svg';
import { ReactComponent as EditIcons } from '../../../assets/icons/feed/edit.svg';
import { ReactComponent as NewspaperIcons } from '../../../assets/icons/feed/newspaper.svg';
import { ReactComponent as TrashIcons } from '../../../assets/icons/feed/trash.svg';
import { Loader } from '../../../components/Loader';
import { useFolder, useFolders, usePostFavorite } from '../../../entities/Post';
import { BottomSheet } from '../../../horizon-components/bottom-sheet/ui';
import { Modal } from '../../../horizon-components/Modal';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Feed, FeedStatus } from '../../../models/Feed';
import { DropdownButton, IDropdownButton } from '../../../widgets/DropdownButton';
import { FavoritePostModal } from '../../../widgets/FavoritePostModal';
import { PutFeedModal } from '../../../widgets/PutFeedModal';
import { RemovePostModal } from '../../../widgets/RemovePostModal';
import { CreateFolderModal } from '../../CreateFolderModal';

interface FeedDotsProps {
  className?: string;
  feed: Feed;
  onRemove?: (id: number) => void;
  onStaus?: (status: FeedStatus, resourcetype: string, id: number) => void;
  onRequest?: () => void;
  isAuthor?: boolean;
}

export const FeedDots = ({
  className,
  feed,
  onRemove,
  onStaus,
  onRequest,
  isAuthor = false,
}: FeedDotsProps) => {
  const { t } = useTranslation(['translation']);
  const [isDelete, setIsDelete] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFolder, setIsFolder] = useState(false);
  const [isPutFeed, setIsPutFeed] = useState(false);
  const [isInFavorite, setIsInFavorite] = useState(feed.is_favourite);
  const [buttons, setButtons] = useState<IDropdownButton[]>([]);

  const { data: folders, isLoading, fetchData } = useFolders();
  const { fetchCreate, isLoading: isCreating } = useFolder();
  const { addToFavorite, deleteFromFavorite, updateFavorite } = usePostFavorite();

  useEffect(() => {
    isFavorite && fetchData();
  }, [isFavorite]);

  useEffect(() => {
    const arr: (boolean | IDropdownButton)[] = [
      !isInFavorite && {
        title: t('buttons.Save_to_favorites'),
        onClick: () => hndlOpenFavoriteModal(),
        icon_right: <FavoriteIcons />,
      },
      isInFavorite && {
        title: t('feed.change_folder'),
        onClick: () => hndlOpenFavoriteModal(),
        icon_right: <FavoriteIcons />,
      },
      isInFavorite && {
        title: t('feed.remove_from_favorites'),
        onClick: () => hndlRemoveFromFavorites(feed.post_favourite_folder),
        icon_right: <FavoriteIcons />,
        type: 'remove',
      },
      isAuthor &&
        !feed.is_published_in_feed && {
          title: t('buttons.Put_it_in_the_feed'),
          onClick: () => hndlOpenPutFeedModal(),
          icon_right: <NewspaperIcons />,
        },
      isAuthor &&
        feed.status === 'published' &&
        feed.resourcetype !== ResourceType.DoiPost && {
          title: t('feed.move_to_draft'),
          onClick: () => onStaus?.('draft', feed?.resourcetype, feed.id),
          icon_right: <DraftIcons />,
        },
      isAuthor && {
        title: t('buttons.Edit'),
        link: MainRoutes.weber + WeberRoutes['feed-edit'] + `/${feed.id}`,
        icon_right: <EditIcons />,
      },
      isAuthor && {
        title: t('buttons.Delete'),
        onClick: () => hndlOpenDeleteModal(),
        icon_right: <TrashIcons />,
        type: 'remove',
      },
    ];
    setButtons(arr.filter(Boolean) as IDropdownButton[]);
  }, [feed, isAuthor, isInFavorite, onStaus, t]);

  const hndlOpenPutFeedModal = () => {
    setIsPutFeed(!isPutFeed);
  };

  const hndlOpenDeleteModal = () => {
    setIsDelete(!isDelete);
  };

  const hndlOpenFavoriteModal = () => {
    setIsFavorite(!isFavorite);
  };

  const hndlOpenFolderModal = () => {
    setIsFolder(!isFolder);
  };

  const hndlRemove = () => {
    onRemove?.(feed.id);
    hndlOpenDeleteModal();
  };

  const hndlCreateFolder = () => {
    hndlOpenFavoriteModal();
    hndlOpenFolderModal();
  };

  const hndlCreate = (title: string) => {
    fetchCreate(title).finally(() => {
      hndlOpenFavoriteModal();
      hndlOpenFolderModal();
    });
  };

  const hndlSave = (folderId?: number | null) => {
    if (!feed.post_favourite_folder) {
      addToFavorite(feed.id, folderId).finally(() => onRequest?.());
      setIsInFavorite(!isInFavorite);
    } else {
      folderId &&
        updateFavorite(feed.post_favourite_folder, feed.id, folderId).finally(() =>
          onRequest?.()
        );
    }
    hndlOpenFavoriteModal();
  };

  const hndlRemoveFromFavorites = (folderId: number) => {
    deleteFromFavorite(folderId).finally(() => onRequest?.());
    setIsInFavorite(!isInFavorite);
  };

  const onPutFeed = () => {
    patchFeed({ is_published_in_feed: true }, feed.id)
      .then(() => toast(t('feed.post_published'), { type: 'success' }))
      .finally(hndlOpenPutFeedModal);
  };

  return (
    <DropdownButton
      variant="highlighted"
      color="white"
      className={classNames('!w-[42px] !px-0', className)}
      buttons={buttons}
    >
      <DotsIcons />
      {window.innerWidth > 768 ? (
        <Modal isOpen={isPutFeed} onClose={hndlOpenPutFeedModal} showClose={false}>
          <PutFeedModal onCancel={hndlOpenPutFeedModal} onPut={onPutFeed} />
        </Modal>
      ) : (
        <BottomSheet open={isPutFeed} onDismiss={hndlOpenPutFeedModal}>
          <PutFeedModal onCancel={hndlOpenPutFeedModal} onPut={onPutFeed} />
        </BottomSheet>
      )}
      {window.innerWidth > 768 ? (
        <Modal isOpen={isDelete} onClose={hndlOpenDeleteModal} showClose={false}>
          <RemovePostModal onCancel={hndlOpenDeleteModal} onRemove={hndlRemove} />
        </Modal>
      ) : (
        <BottomSheet open={isDelete} onDismiss={hndlOpenDeleteModal}>
          <RemovePostModal onCancel={hndlOpenDeleteModal} onRemove={hndlRemove} />
        </BottomSheet>
      )}
      {window.innerWidth > 768 ? (
        <Modal
          title={t('feed.save_to_a_folder')}
          isOpen={isFavorite}
          onClose={hndlOpenFavoriteModal}
        >
          {isCreating || isLoading ? (
            <Loader />
          ) : (
            <FavoritePostModal
              onCreateFolder={hndlCreateFolder}
              folders={folders}
              onSave={hndlSave}
              folderId={feed.favourite_folder}
            />
          )}
        </Modal>
      ) : (
        <BottomSheet open={isFavorite} onDismiss={hndlOpenFavoriteModal}>
          {isCreating || isLoading ? (
            <Loader />
          ) : (
            <FavoritePostModal
              onCreateFolder={hndlCreateFolder}
              folders={folders}
              onSave={hndlSave}
              folderId={feed.favourite_folder}
            />
          )}
        </BottomSheet>
      )}
      {window.innerWidth > 768 ? (
        <Modal
          title={t('feed.create_a_new_folder')}
          isOpen={isFolder}
          onClose={hndlCreateFolder}
        >
          <CreateFolderModal onCreate={hndlCreate} />
        </Modal>
      ) : (
        <BottomSheet open={isFolder} onDismiss={hndlCreateFolder}>
          <CreateFolderModal onCreate={hndlCreate} />
        </BottomSheet>
      )}
    </DropdownButton>
  );
};
