import katex from 'katex';
import 'katex/dist/katex.min.css';
import Quill from 'quill';
import TableUI, * as quillTableUI from 'quill-table-ui';
import 'quill-table-ui/dist/index.css';
import Table from 'quill/modules/table';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { ReactComponent as TableIcon } from '../../assets/icons/course/table.svg';
import { MButton } from '../../horizon-components/MButton';
import { Loader } from '../Loader';
import './styles.scss';

window.katex = katex;
const Formula = Quill.import('formats/formula');
Quill.register('formats/formula', Formula);
Quill.register(
  {
    'modules/tableUI': quillTableUI.default,
  },
  true
);
Quill.register('modules/tableUI', TableUI);
Quill.register('modules/table', Table);

const icons = Quill.import('ui/icons') as Record<string, string | Function>;
icons['undo'] = '';
icons['redo'] = '';
icons['bold'] = '';
icons['italic'] = '';
icons['underline'] = '';
icons['list'] = '';
icons['link'] = '';
icons['image'] = '';
icons['clean'] = '';

interface TableModule {
  insertTable: (rows: number, cols: number) => void;
}

type Props = {
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  label?: string;
  labelRequiredChar?: boolean;
  errorText?: string;
  value?: string;
  name?: string;
  onChange?: (value: string, name?: string) => void;
  loading?: boolean;
  image?: boolean;
  isBar?: boolean;
  height?: number | 'auto';
  text?: string;
  isInsertTable?: boolean;
};

export const CustomQuill: React.FC<Props> = ({
  placeholder,
  className = '',
  wrapperClassName = '',
  label,
  labelRequiredChar = false,
  errorText,
  name,
  value = '',
  onChange,
  height = 'auto',
  image = false,
  loading = false,
  isBar = true,
  text,
  isInsertTable = false,
}) => {
  const { i18n, t } = useTranslation('translation');
  const editorRef = useRef<Quill | null>(null);
  const snowRef = useRef<HTMLDivElement | null>(null);

  const modules = useMemo(() => {
    if (!isBar) {
      return { toolbar: false };
    }

    const toolbar: (string | object)[][] = [
      [{ undo: 'undo' }, { redo: 'redo' }],
      [{ header: [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline', 'clean'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['formula'],
    ];

    if (image) {
      toolbar.push(['image']);
    }

    return {
      toolbar,
      history: {
        delay: 1000,
        userOnly: true,
      },
      table: true,
      tableUI: true,
    };
  }, [isBar, image]);

  const initializeTable = (snow: Quill) => {
    const table = snow.getModule('table') as TableModule;

    if (table?.insertTable) {
      document.querySelector('#insert-table')?.addEventListener('click', () => {
        table.insertTable(3, 3);
      });
    } else {
      console.error('Table module is not initialized correctly.');
    }
  };

  const initializeImage = (snow: Quill) => {
    const insertImage = (file: File) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const range = snow.getSelection();
        if (range) {
          snow.insertEmbed(range.index, 'image', e.target?.result);
        }
      };
      reader.readAsDataURL(file);
    };

    const fileInput = document.querySelector('#image-upload') as HTMLInputElement;
    fileInput?.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        insertImage(target.files[0]);
      }
    });

    const toolbarImageButton = document.querySelector(
      '.ql-image'
    ) as HTMLButtonElement;
    if (toolbarImageButton) {
      toolbarImageButton.addEventListener('click', () => {
        fileInput?.click();
      });
    }
  };

  const initializeUndoRedo = (snow: Quill) => {
    const history = snow.history;

    const undoButton = document.querySelector('.ql-undo') as HTMLButtonElement;
    const redoButton = document.querySelector('.ql-redo') as HTMLButtonElement;

    if (undoButton) {
      undoButton.addEventListener('click', () => history.undo());
    } else {
      console.error('Undo button is not found.');
    }

    if (redoButton) {
      redoButton.addEventListener('click', () => history.redo());
    } else {
      console.error('Redo button is not found.');
    }
  };

  const removeFile = (str: string) => {
    const cleanedStr = str.replace(/<img[^>]*>/g, '');
    onChange?.(cleanedStr, name);
  };

  const handleChange = (v: string) => {
    if (image) {
      onChange?.(v, name);
    } else {
      removeFile(v);
    }
  };

  useEffect(() => {
    if (!snowRef.current || editorRef.current) return;

    const editor = new Quill(snowRef.current, {
      theme: 'snow',
      modules,
      placeholder,
    });

    editorRef.current = editor;

    initializeTable(editor);
    initializeImage(editor);
    initializeUndoRedo(editor);

    if (value) {
      editor.root.innerHTML = value;
    }

    editor.on('text-change', () => {
      const html = editor.root.innerHTML;
      handleChange(html);
    });
  }, [modules, placeholder, value]);

  useEffect(() => {
    if (editorRef.current && editorRef.current.root.innerHTML !== value) {
      editorRef.current.root.innerHTML = value;
    }
  }, [value]);

  useEffect(() => {
    if (editorRef.current) {
      const quillContainer = editorRef.current.root;

      if (quillContainer) {
        const container = quillContainer as HTMLElement;

        if (height === 'auto') {
          container.style.minHeight = '150px';
        } else {
          container.style.minHeight = `${height}px`;
          container.style.maxHeight = `${height}px`;
          container.style.overflowY = 'scroll';
        }
      }
    }
  }, [height]);

  return (
    <div className={`relative ${wrapperClassName} lang-${i18n.language} b-quill`}>
      {label && (
        <p
          className={`mb-1 ml-4 text-sm font-medium text-gray-900 ${errorText ? 'text-red-700' : ''}`}
        >
          {label}&nbsp;
          {labelRequiredChar && <span className={'g-label--required'}>*</span>}
        </p>
      )}

      <div
        ref={snowRef}
        id="snow-container"
        style={{ height }}
        className={`b-quill relative ${className} ${errorText ? 'b-quill__error' : ''}`}
      >
        {loading && (
          <Loader className="absolute top-[40%] z-10 rounded-b-xl bg-shadow-700" />
        )}
      </div>

      {errorText && <p className={'g-error mt-1 pl-4'}>{errorText}</p>}
      {text && <p className={'g-error mt-1 pl-4 text-[#8F9BBA]'}>{text}</p>}
      {isInsertTable && (
        <div className="mt-2 flex flex-row-reverse">
          <MButton variant="transparent" id="insert-table">
            {t('buttons.Create_table')} <TableIcon />
          </MButton>
        </div>
      )}
      {image && (
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          style={{ display: 'none' }}
        />
      )}
    </div>
  );
};
