import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as ShareIcon } from '../../../../assets/icons/profile/share.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import SEO from '../../../../components/SEO/SEO';
import { useShareReferal } from '../../../../hooks/useShareReferal';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../../providers';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';

export const PublicProfileHeader = ({ className }: { className?: string }) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const { useTitleBack } = useTitleBackContext();
  const { handleClick } = useShareReferal();
  const { isLoading } = useRewardContext();

  const isCurrentUser = useMemo(
    () =>
      anotherUserGuid && user?.guid ? anotherUserGuid === user?.guid : !!user?.guid,
    [user?.guid, anotherUserGuid]
  );

  const buttons = useMemo(() => {
    return (
      <div className="flex flex-row justify-between gap-2 lg:gap-6">
        <MButton
          color="white"
          variant="highlighted"
          className="shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] lg-max:!px-2.5"
          onClick={handleClick}
          loading={isLoading}
          disabled={isLoading}
          isLoaderCenter
        >
          <span className="hidden lg:block">{t('buttons.Share')}</span>
          <ShareIcon />
        </MButton>
      </div>
    );
  }, [isCurrentUser, isLoading]);

  useTitleBack(t('profile.title'), null, null, buttons);

  const breads: Bread[] = useMemo(
    () => [
      {
        title: 'Edu',
        url: MainRoutes.edu + EduRoutes.courses,
      },
      {
        title: t('profile.User_Profile'),
        url:
          MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${anotherUserGuid}`,
      },
    ],
    [anotherUserGuid, t]
  );

  return (
    <div
      className={classNames(
        'flex flex-row justify-end gap-3 lg:justify-between',
        className
      )}
    >
      <SEO
        title={t('pages.profile.meta.title')}
        desc={t('pages.profile.meta.desc')}
      />
      <Breadcrumbs breads={breads} className="hidden lg:block" />
    </div>
  );
};
