import { useTranslation } from 'react-i18next';
import { ReactComponent as Shared } from '../../../../assets/icons/shared-link.svg';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Badge } from '../../../../horizon-components/Badge';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import { ISO } from '../../../../shared/const/ISO';
import { CourseAsideCard } from '../../../../widgets/CourseAsideCard';

export const CoursePreviewCategory = () => {
  const { t } = useTranslation(['translation']);
  const { course } = useCourseCreation();
  const navigate = useNavigate();

  const goToDP = () =>
    navigate(
      MainRoutes.edu + EduRoutes.nft + `/${course?.digital_pass?.category_id}`
    );

  return (
    <CourseAsideCard
      title={course?.subject?.title}
      data={
        !course?.subject?.is_miscellaneous_subject && (
          <Shared
            onClick={goToDP}
            className="cursor-pointer stroke-secondary-grey-600 hover:stroke-primary-purple-blue-500"
          />
        )
      }
      className="!flex lg-max:!hidden"
    >
      <div className="flex flex-wrap gap-2">
        {!!course?.level && (
          <Badge variant="primary" className="capitalize">
            {t('course.Grade')} {course?.level}
          </Badge>
        )}
        {!!course?.digital_pass?.title && (
          <Badge variant="primary" className="capitalize">
            {course?.digital_pass?.title}
          </Badge>
        )}
        {!!course?.language && (
          <Badge variant="primary" className="capitalize">
            {ISO?.[course?.language]}
          </Badge>
        )}
      </div>
    </CourseAsideCard>
  );
};
