import { FC, useEffect, useState } from 'react';
import { Character2 } from '../LevelMap/items/winterItems';
import { MButton } from '../../../horizon-components/MButton';
import CheckIcon from '../../../assets/icons/map/check_circle.svg';
import SubjectModalCard from './SubjectSelectCard';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../stores';
import {
  fetchPublicSubjects,
  fetchPublicUnits,
  fetchSubjectDetails,
  setCurrentSubjectId,
  setIsOpenSubjectModal,
} from '../../../stores/subject';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';

const SubjectsModal: FC = () => {
  const { t, i18n } = useTranslation(['translation']);

  const language = i18n.language;
  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const dispatch: AppDispatch = useDispatch();

  const handleSubjectSelect = (subjectId: number) => {
    dispatch(setCurrentSubjectId(subjectId));
  };

  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );

  const subjectsStatus = useSelector(
    (state: RootState) => state.start.subjectsStatus
  );

  const unitsStatus = useSelector((state: RootState) => state.start.unitsStatus);

  const progressLevelData = useSelector(
    (state: RootState) => state.start.progressLevelData
  );

  const playGame = () => {
    dispatch(setIsOpenSubjectModal(false));
  };

  useEffect(() => {
    dispatch(fetchPublicSubjects('en'));
    dispatch(fetchPublicUnits('en'));
    dispatch(fetchPublicSubjects('ru'));
    dispatch(fetchPublicUnits('ru'));
  }, [dispatch]);

  useEffect(() => {
    if (
      subjectsStatus === 'succeeded' &&
      unitsStatus === 'succeeded' &&
      progressLevelData
    ) {
      dispatch(fetchSubjectDetails(language));
    }
  }, [dispatch, subjectsStatus, unitsStatus, progressLevelData, t, language]);

  const [cachedImages, setCachedImages] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (subjectsStatus === 'succeeded') {
      const imagesToCache = subjectDetails?.[language]?.map(
        (data) => data.subjectImage
      );
      imagesToCache?.forEach((src) => {
        if (src && !cachedImages[src]) {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            setCachedImages((prev) => ({ ...prev, [src]: src }));
          };
        }
      });
    }
  }, [subjectsStatus, subjectDetails, language, cachedImages]);

  return (
    <div className="flex h-full w-full flex-col md:h-[722px]">
      <p className="mb-[12px] text-[18px] font-bold text-[#2B3674] md:hidden">
        {t('map.TrainingUnderTheProgram')}
      </p>
      <div className="relative flex-grow">
        <div className="flex">
          <div className="relative h-[90px] w-[90px] overflow-hidden">
            <img
              className="absolute top-[-1rem] h-[140px] w-[140px] object-cover md:top-[-1.1rem] md:h-[140px] md:w-[140px]"
              style={{ transform: 'rotate(-16.11deg)' }}
              src={Character2}
              alt=""
            />
          </div>
          <div className="flex items-center  mini:ml-[8px] md:ml-[26px]">
            <div
              className="w-0- h-0 border-b-[10px] border-r-[15px] border-t-[10px] 
            border-b-transparent border-r-[#8361f9] border-t-transparent"
            ></div>
            <div
              className="relative my-auto flex h-[67px] w-[206px] items-center rounded-[20px] p-[20px] opacity-70 md:mx-auto md:h-[77px] md:w-[251px]"
              style={{ background: 'linear-gradient(to right, #471FF7, #C414FF)' }}
            >
              <p className="mx-auto text-center text-[16px] font-semibold text-white">
                {t('map.ChooseSubjectToStart')}
              </p>
            </div>
          </div>
        </div>

        <div
          className="overflow-auto shadow-[4px_4px_24px_0px_rgba(112,144,176,0.12)]"
          style={{ maxHeight: 'calc(280px + (100vh - 530px))' }}
        >
          {(!subjectDetails || subjectsStatus === 'loading') && <Loader />}
          {subjectsStatus === 'succeeded' &&
            subjectDetails?.[language]?.map(
              (data) =>
                data && (
                  <div
                    onClick={() => handleSubjectSelect(data.subjectId)}
                    key={data.subjectId}
                    className="mb-[8px] shadow-[4px_4px_24px_0px_rgba(112,144,176,0.12)]"
                  >
                    <SubjectModalCard
                      className="mb-[8px]"
                      imageSrc={data.subjectImage}
                      title={data.title}
                      subtitle={data.levelLabel}
                      details={`${data.gradeCount} ${t('map.grades')}, ${data.unitCount} ${t('map.units')}`}
                      icon={
                        data.subjectId === currentSubjectId ? CheckIcon : undefined
                      }
                    />
                  </div>
                )
            )}
        </div>
      </div>

      <MButton
        variant="highlighted"
        color="primary"
        className="relative z-20 mt-[16px] h-[42px] w-full sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
        onClick={playGame}
      >
        {t('map.StartTraining')}
      </MButton>
    </div>
  );
};

export default SubjectsModal;
