import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getVerificationCourses } from '../../../api/course';
import { DatePicker } from '../../../components/DatePicker';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import SEO from '../../../components/SEO/SEO';
import useFetch from '../../../hooks/useFetch';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { Course, CourseCardItem } from '../../../models/Course';
import { CourseCard } from '../../../widgets/CourseCard';

const PAGE_SIZE = 12;

const lastYear = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const lastDay = new Date(currentDate);
  const firstDay = new Date(currentDate);
  firstDay.setFullYear(currentDate.getFullYear() - 1);
  firstDay.setDate(firstDay.getDate() + 1);
  return [firstDay, lastDay];
};

export const VerifyCourseList: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const targetRef = useRef<HTMLDivElement>(null);

  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedDates, setSelectedDates] = useState<Date[]>(lastYear());
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { isLoading, fetchData: fetchCourses } = useFetch(getVerificationCourses);

  const fetchVerifyCourses = useCallback(async () => {
    if (!hasMore || isLoading || selectedDates.length !== 2) return;

    const startDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
    const endDate = dayjs(selectedDates[1]).add(1, 'day').format('YYYY-MM-DD');

    const data = await fetchCourses(startDate, endDate, page);

    if (data?.results?.length) {
      setCourses((prevCourses) => [...prevCourses, ...data?.results]);

      if (data?.results?.length < PAGE_SIZE) {
        setHasMore(false);
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    } else {
      setHasMore(false);
    }
  }, [fetchCourses, hasMore, isLoading, page, selectedDates, t]);

  useEffect(() => {
    setCourses([]);
    setPage(0);
    setHasMore(true);
  }, [selectedDates, t]);

  useInfiniteScroll(targetRef, fetchVerifyCourses);

  return (
    <>
      <SEO
        title={t('pages.verify-course.meta.title')}
        desc={t('pages.verify-course.meta.desc')}
      />

      <div className="b-course-list w-full">
        <div className="flex items-center justify-between gap-x-2">
          <h1 className="g-main-title text-xl md:text-4xl">
            {t('menu.VerifyCourse')}
          </h1>
          <DatePicker
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            configs={{ dateFormat: 'dd.MM' }}
            className="z-10"
          />
        </div>

        {courses.length === 0 && !isLoading ? (
          <NotFound />
        ) : (
          <div className="flex flex-wrap gap-3">
            {courses.map((course, index) => (
              <div
                className="w-full sm:w-[45%] md:w-[30%] md:max-w-[256px]"
                key={index}
              >
                <CourseCard
                  course={course as unknown as CourseCardItem}
                  hideFavorites
                  inVerifyCourse
                />
              </div>
            ))}
          </div>
        )}

        <div ref={targetRef} className="mt-3" />
        {isLoading ? <Loader /> : null}
      </div>
    </>
  );
};
