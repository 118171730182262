/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserSomeDataStorage, setUserSomeDataStorage } from '../../../api';
import { BottomSheet } from '../../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../../horizon-components/MButton';
import { Modal } from '../../../horizon-components/Modal';
import { useUserContext } from '../../../providers';
import { selectProgressLevelData } from '../../../stores/subject';
import { Character2 } from '../LevelMap/items/winterItems';

const StartGameModal = () => {
  const { t } = useTranslation(['translation']);
  const [startModalIsOpen, setStartModalIsOpen] = useState(false);
  const closeModal = () => {
    setUserSomeDataStorage('startModal', today, user?.guid);
    setStartModalIsOpen(false);
  };
  const { user } = useUserContext();
  const today = new Date().toLocaleDateString();
  const progressLevelData = useSelector(selectProgressLevelData);

  useEffect(() => {
    if (
      progressLevelData !== null &&
      getUserSomeDataStorage('startModal', user?.guid) === undefined
    ) {
      setStartModalIsOpen(true);
    }
  }, [progressLevelData, user?.guid]);

  const playGame = () => {
    setUserSomeDataStorage('startModal', today, user?.guid);
    closeModal();
  };

  const contentModalRender = () => (
    <div className="relative overflow-hidden">
      <div
        className=" h-[250px] w-full rounded-[20px] pb-[40px] pl-[26px] pr-[16px] pt-[12px] sm:h-[200px]"
        style={{
          background: 'linear-gradient(to right, #C414FF, #471FF7)',
        }}
      >
        <p className="text-[16px] font-semibold text-white">
          {t('map.StartModalInfo')}
        </p>
      </div>
      <img
        className="absolute right-[-25px] top-[145px] z-10 w-[165px] scale-x-[-1] transform sm:top-[75px] sm:w-[182px]"
        style={{ transform: 'scaleX(-1) rotate(-13.11deg)' }}
        src={Character2}
        alt=""
      />
      <MButton
        variant="highlighted"
        color="primary"
        className="relative z-20 mt-[24px] w-full sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
        onClick={playGame}
      >
        {t('map.Start')}
      </MButton>
    </div>
  );

  return window.innerWidth > 768 ? (
    <Modal title={t('map.Hello')} isOpen={startModalIsOpen} onClose={closeModal}>
      {contentModalRender()}
    </Modal>
  ) : (
    <BottomSheet open={startModalIsOpen} onDismiss={closeModal}>
      {contentModalRender()}
    </BottomSheet>
  );
};

export default StartGameModal;
