import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ErpIcon from '../../../../assets/images/reward/erp.png';
import { Loader } from '../../../../components/Loader';
import { useNavigate } from '../../../../hooks/useNavigate';
import { MButton } from '../../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { useUserContext } from '../../../../providers/UserProvider';

export const PreviewModal = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { rewards, isLoading } = useRewardContext();

  const coursePublishedReward = useMemo(() => {
    if (!rewards) {
      return 0;
    }
    const rewardObject = rewards.find(
      (reward) => reward.tokenomic_type === 'course_verification'
    );
    if (!rewardObject) {
      return 0;
    }

    return parseInt(rewardObject.value).toLocaleString();
  }, [rewards]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className={
        'flex max-w-[700px] flex-col items-center justify-center gap-[16px] text-center lg:gap-[24px]'
      }
    >
      <img src={ErpIcon} alt="Edres tokens" className={'max-w-[300px]'} />
      <div
        className={
          'text-[20px] font-bold text-primary-purple-blue-500 lg:text-[32px]'
        }
      >
        {t('edu.course_sent_to_verification')}
      </div>
      <div className={'text-[16px] text-secondary-grey-900 lg:text-[20px]'}>
        {t('edu.course_sent_to_verification_description_1')}
        {/* {' '}
        <span className={'text-primary-purple-blue-500'}>{user?.email}</span> */}
        {'. '}
        {t('edu.course_sent_to_verification_description_2', {
          erpReward: coursePublishedReward,
        })}
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        onClick={() => navigate(MainRoutes.edu + EduRoutes['my-courses'])}
        size={'md'}
        className={'w-full'}
      >
        {t('edu.to_my_courses')}
      </MButton>
    </div>
  );
};
