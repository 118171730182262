import { saveCurrentUrl } from '../../../api/sessions';
import { useCourseFavorite } from '../../../entities/Courses';
import { useNavigate } from '../../../hooks/useNavigate';
import { Favorite } from '../../../horizon-components/Favorite';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';

interface CourseFavoriteProps {
  className?: string;
  favoriteClassName?: string;
  isFavorite?: boolean;
  favoriteId: number;
  onFavorite?: () => void;
}

export const CourseFavorite = ({
  className,
  isFavorite = false,
  favoriteId,
  onFavorite,
  favoriteClassName,
}: CourseFavoriteProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { favorite, fetchFavorite, isLoading } = useCourseFavorite(isFavorite);

  const handleFavorite = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    fetchFavorite(favoriteId).finally(onFavorite);
  };

  return (
    <MButton
      size="xs"
      color="white"
      variant="highlighted"
      className={`max-h-[42px] max-w-[42px] !rounded-full !px-2 !py-[7px] ${className}`}
      disabled={isLoading}
      onClick={handleFavorite}
    >
      <Favorite
        className={favoriteClassName}
        favorite={favorite}
        isLoading={isLoading}
        onFavorite={handleFavorite}
      />
    </MButton>
  );
};
