import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResourceType } from '../../../../api/weber/feed';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/feed/question.svg';
import { ReactComponent as StarIcon } from '../../../../assets/icons/feed/star.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Loader } from '../../../../components/Loader';
import { usePost, usePostComments } from '../../../../entities/Post';
import { FeedComment } from '../../../../features/FeedComment';
import { FeedReaction } from '../../../../features/FeedReaction';
import { UserFollowButton } from '../../../../features/UserFollowButton';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Badge } from '../../../../horizon-components/Badge';
import { BottomSheet } from '../../../../horizon-components/bottom-sheet/ui';
import { Link } from '../../../../horizon-components/Link';
import { Modal } from '../../../../horizon-components/Modal';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../../providers';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import { getTimeAgo } from '../../../../shared/utils/getTimeAgo';
import { getDomainName } from '../../../../utils/getDomainName';
import { getUserData } from '../../../../utils/getUserData';
import { AltmetricBar } from '../../../../widgets/AltmetricBar';
import { AsideCard } from '../../../../widgets/AsideCard';
import { AvatarTitleTextCard } from '../../../../widgets/AvatarTitleTextCard';
import { FeedCommentList } from '../../../../widgets/FeedCommentCard';
import { FeedGallery } from '../../../../widgets/FeedGallery';
import { FeedThumb } from '../../../../widgets/FeedThumb';
import { FeedCommunity } from './components/FeedCommunity';
import './FeedView.scss';

type TAltmetric = { title: string; percent: number; total: number; isBar?: boolean };

export const FeedView: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { data: feed, isLoading } = usePost(Number(id));
  const author = getUserData(feed?.author);
  const [altmetrics, setAltmetrics] = useState<TAltmetric[]>([]);
  const [altmetricCount, setAltmetricCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const {
    dataScroll: comments,
    fetchData: fetchComments,
    isLoading: isCommentsLoading,
    setPage,
    currentPage,
    total,
  } = usePostComments();
  const [breads, setBreads] = useState<Bread[]>([]);
  const [isOpen, setIsOpen] = useState<number | null>(null);
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(feed?.title, MainRoutes.edu + EduRoutes.courses);

  useEffect(() => {
    if (feed?.id) {
      setBreads([
        { title: 'Weber', url: MainRoutes.weber },
        {
          title: t('feed.title'),
          url: MainRoutes.weber + WeberRoutes.feeds,
        },
        {
          title: feed.title,
          url: MainRoutes.weber + WeberRoutes.feed + `/${id}`,
        },
      ]);
    }
  }, [feed, id, t]);

  const loadComments = useCallback(() => {
    if (total > currentPage) {
      setTimeout(() => {
        feed?.id && fetchComments(feed?.id, currentPage + 1);
      }, 1000);
    }
  }, [total, currentPage, feed?.id]);

  const targetRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll(targetRef, loadComments);

  useEffect(() => {
    !!feed?.id && getTotal();
  }, [feed?.id, t]);

  const getTotal = () => {
    let totalVotes = 0;

    let newData: TAltmetric[] = [
      {
        title: t('feed.interested'),
        total: feed?.reaction_count.INTERESTED_IN || 0,
        percent: 0,
        isBar: true,
      },
      {
        title: t('feed.doubt'),
        total: feed?.reaction_count.DOUBT_THAT || 0,
        percent: 0,
        isBar: true,
      },
      {
        title: t('feed.support'),
        total: feed?.reaction_count.SUPPORT_BY || 0,
        percent: 0,
        isBar: true,
      },
    ];

    // Находим общее количество проголосовавших
    newData?.forEach((item) => {
      totalVotes += item.total;
    });

    // Вычисляем процент голосов от общего числа и добавляем его в каждый элемент массива
    newData?.forEach((item) => {
      item.percent = Math.round((item.total / totalVotes) * 100) || 0;
    });

    setAltmetricCount(totalVotes);
    setAltmetrics(newData);
  };

  useEffect(() => {
    if (feed?.id) {
      fetchComments(feed?.id, 1);
    }
  }, [feed, t]);

  const authorName: string[] = useMemo(() => {
    const first_name = feed?.author?.user_meta?.first_name;
    const last_name = feed?.author?.user_meta?.last_name;
    const name = `${first_name !== null ? first_name : ''}${
      first_name !== null && last_name !== null ? ' ' : ''
    }${last_name !== null ? last_name : ''}`;
    return name.length ? [name] : [];
  }, [feed?.author?.user_meta?.first_name, feed?.author?.user_meta?.last_name]);

  const metrics = useMemo(
    () => [
      {
        id: 1,
        title: t('feed.Rating'),
        description: t('feed.RatingDescription'),
      },
      {
        id: 2,
        title: t('feed.altmetrics'),
        description: t('feed.AltmetricsDescription'),
      },
      {
        id: 3,
        title: t('feed.Citations'),
        description: t('feed.CitationsDescription'),
      },
    ],
    [t]
  );

  return (
    <div className="FeedView">
      <div className="hidden md:block">
        <Breadcrumbs breads={breads} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="FeedView__content">
            <div className="FeedView__list">
              <h1 className="hidden md:block">{feed?.title}</h1>

              <div className="FeedView__wrapper">
                <div className="FeedView__body content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        feed?.resourcetype === 'ArticlePost'
                          ? feed?.html_body
                          : feed?.description || '',
                    }}
                  />
                </div>

                {feed?.resourcetype === ResourceType.LinkPost ? (
                  <FeedThumb
                    title={feed.title}
                    thumb={feed.preview_image}
                    link={feed.link}
                    minRead={feed.min_read}
                    onClick={() => navigate(feed.link)}
                  />
                ) : (
                  <FeedGallery
                    files={feed?.attached_files}
                    isFileMode={feed?.resourcetype === ResourceType.ArticlePost}
                  />
                )}
              </div>

              {!!feed?.doi_link && <hr />}

              {!!feed?.doi_link && (
                <div>
                  <h3>{t('feed.Link_to_paper')}</h3>
                  <Link to={feed?.doi_link} target="_blank">
                    {t('feed.Visit_the_papers_external_site')}
                  </Link>
                </div>
              )}

              <div className="FeedView__buttons">
                {!!feed && <FeedReaction feed={feed} isSingle />}
              </div>

              {!!feed?.id && (
                <FeedComment
                  id={feed?.id}
                  onSend={() => {
                    fetchComments(feed?.id, 1);
                    setPage(1);
                    setCommentsCount(commentsCount + 1);
                  }}
                />
              )}

              {!!comments?.length && (
                <div className="FeedView__comments">
                  <h3>
                    {Number(feed?.comments_count) + commentsCount}{' '}
                    {t('feed.comments')}
                  </h3>
                  <FeedCommentList
                    comments={comments?.map((comment) => comment.comment)}
                  />
                  <div ref={targetRef} />
                </div>
              )}
              {isCommentsLoading && <Loader />}
            </div>
            <div className="FeedView__aside">
              <AsideCard title={t('feed.data')}>
                <div className="flex flex-wrap gap-2">
                  {!!feed?.doi && <Badge variant="outline">DOI: {feed?.doi}</Badge>}
                  {!!feed?.tags?.length &&
                    feed?.tags?.map((tag, idx) => (
                      <Badge variant="outline" className="capitalize" key={idx}>
                        {tag}
                      </Badge>
                    ))}
                  {!!feed?.created_at && (
                    <Badge variant="outline">
                      {getTimeAgo(new Date(feed?.created_at), 'day')?.[0]
                        ? getTimeAgo(new Date(feed?.created_at), 'day')?.[0] + ' '
                        : ''}
                      {t(getTimeAgo(new Date(feed?.created_at), 'day')[1])}
                    </Badge>
                  )}
                  {!!feed?.min_read && (
                    <Badge variant="outline">
                      {feed?.min_read} {t('feed.min_read')}
                    </Badge>
                  )}
                  {!!feed?.link && (
                    <Badge
                      variant="outline"
                      className="capitalize"
                      onClick={() => navigate(feed?.link)}
                    >
                      {getDomainName(feed?.link)}
                    </Badge>
                  )}
                </div>
              </AsideCard>

              {!!feed?.author?.guid && (
                <AsideCard title={t('feed.publisher')}>
                  <div className="FeedView__card">
                    <Link
                      to={
                        MainRoutes.auth +
                        AuthRoutes.profile +
                        ProfileRoutes.user +
                        `/${feed?.author?.guid}`
                      }
                    >
                      <AvatarTitleTextCard
                        title={author.username}
                        text={authorName}
                        img={author.image}
                      />
                    </Link>
                    {!!feed?.author?.guid && feed?.author?.guid !== user?.guid && (
                      <div className="ml-auto flex justify-end">
                        <UserFollowButton anotherUserGuid={feed?.author.guid} />
                      </div>
                    )}
                  </div>
                </AsideCard>
              )}

              {!!feed?.group && <FeedCommunity groupId={feed?.group} />}

              <AsideCard>
                <div className="flex justify-between">
                  <h4 className="flex items-center gap-1">
                    {t('feed.Rating')}
                    <QuestionIcon
                      className="cursor-pointer fill-secondary-dark-grey-300 hover:fill-primary-purple-blue-400"
                      onClick={() => setIsOpen(0)}
                    />
                  </h4>
                  <div className="flex items-center gap-1">
                    <StarIcon className="fill-primary-purple-blue-400" />{' '}
                    <span className="text-base font-semibold text-primary-purple-blue-400">
                      {feed?.altmetrics?.altmetric_val || altmetricCount}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <h4 className="flex items-center gap-1">
                    {t('feed.altmetrics')}
                    <QuestionIcon
                      className="cursor-pointer fill-secondary-dark-grey-300 hover:fill-primary-purple-blue-400"
                      onClick={() => setIsOpen(1)}
                    />
                  </h4>
                  <div className="flex items-center gap-1">
                    <span className="text-base font-semibold text-primary-purple-blue-400">
                      {feed?.altmetrics?.altmetric_val || altmetricCount}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <small className="text-xs font-semibold text-secondary-dark-grey-700">
                    {t('feed.Views')}
                  </small>
                  <small className="text-xs font-semibold text-secondary-dark-grey-900">
                    {feed?.altmetrics?.altmetric_val || altmetricCount}
                  </small>
                </div>
                {altmetrics.map((metric, idx) => (
                  <AltmetricBar
                    title={metric.title}
                    total={metric.total}
                    percent={metric.percent}
                    isBar={metric.isBar}
                    key={idx}
                  />
                ))}
                <AltmetricBar
                  title={t('feed.comments')}
                  total={feed?.comments_count || 0}
                />
                <div className="flex justify-between">
                  <h4 className="flex items-center gap-1">
                    {t('feed.Citations')}
                    <QuestionIcon
                      className="cursor-pointer fill-secondary-dark-grey-300 hover:fill-primary-purple-blue-400"
                      onClick={() => setIsOpen(2)}
                    />
                  </h4>
                  <div className="flex items-center gap-1">
                    <span className="text-base font-semibold text-primary-purple-blue-400">
                      {feed?.altmetrics?.altmetric_val || altmetricCount}
                    </span>
                  </div>
                </div>
              </AsideCard>
            </div>
          </div>
        </>
      )}

      {window.innerWidth > 768 ? (
        <Modal
          title={metrics?.[isOpen || 0].title}
          isOpen={isOpen !== null}
          onClose={() => setIsOpen(null)}
          maxWidth={600}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: metrics?.[isOpen || 0].description,
            }}
          />
        </Modal>
      ) : (
        <BottomSheet open={isOpen !== null} onDismiss={() => setIsOpen(null)}>
          <div
            dangerouslySetInnerHTML={{
              __html: metrics?.[isOpen || 0].description,
            }}
          />
        </BottomSheet>
      )}
    </div>
  );
};
