import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSubmitAnswer, TCA } from '../../api/wiz/wiz';
import { Loader } from '../../components/Loader';
import { LoaderMini } from '../../components/LoaderMini';
import { sendWizCompletion } from '../../hooks/useAnalytics';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from '../../hooks/useNavigate';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { Modal } from '../../horizon-components/Modal';
import { ProgressBar } from '../../horizon-components/ProgressBar';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../providers';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { QuizEndModal } from '../QuizEndModal';
import './style.scss';

interface QuizTestProps {
  className?: string;
  onStart?: () => void;
}

const TIME = 30;
const TOTAL_PERCENT = 100;

export const QuizTest = ({ className, onStart }: QuizTestProps) => {
  const { getUserSomeData, setUserSomeData } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [selected, setSelected] = useState<number | null>(null);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);
  const { data: userAnswer, fetchData: fetchAnswer } = useFetch(
    (q_id: number, a_id: number, ca: TCA) => setSubmitAnswer(q_id, a_id, ca)
  );

  const { handleCloseTest, getQuestions, question, questions, answers } =
    useWizContext();

  const ca = getUserSomeData('quiz_code') as unknown as TCA;

  const [seconds, setSeconds] = useState<number>(TIME);
  const [isTimerStopped, setIsTimerStopped] = useState(true);
  const [percentage, setPercentage] = useState(TOTAL_PERCENT);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    timerRef.current = setTimeout(async () => {
      setSeconds((prevSeconds) => (prevSeconds !== 0 ? prevSeconds - 1 : 0));
    }, 1000);
  };

  useEffect(() => {
    if (questions?.length === 0 && answers?.length) {
      hndlOpenStartModal();
    }
  }, [answers?.length, questions]);

  useEffect(() => {
    if (questions?.length === 0 && !answers) {
      handleStartTest();
    }
  }, [answers, questions]);

  useEffect(() => {
    if (isTimerStopped) {
      return;
    }

    startTimer();
    if (seconds === 0) {
      if (question) {
        handleAnswer(question.id, question.answers[0].id);
      }
    }
  }, [seconds, isTimerStopped, question]);

  useEffect(() => {
    if (!question?.id) {
      return;
    }
    onStartTest();
  }, [question?.id]);

  useEffect(() => {
    setPercentage((seconds / TIME) * TOTAL_PERCENT);
  }, [seconds]);

  const hndlSelect = (questionId: number, selectId: number) => {
    setSelected(selectId);
    handleAnswer(questionId, selectId);
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  const handleAnswer = async (questionId: number, answerId: number) => {
    setIsTimerStopped(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsSubmittingAnswer(true);
    try {
      const answer = await fetchAnswer(questionId, answerId, ca);

      if (answer?.correct) {
        const answers = getUserSomeData('answers');
        if (Array.isArray(answers)) {
          setUserSomeData('answers', [...answers, answer?.ea]);
        } else {
          setUserSomeData('answers', [answer?.ea]);
        }
      }

      setTimeout(() => {
        getQuestions();
      }, 1000);
    } catch (e) {
      onStart?.();
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const onStartTest = () => {
    setSelected(null);
    setSeconds(TIME);
    setIsTimerStopped(false);
    setPercentage(TOTAL_PERCENT);
  };

  const goTo = () => {
    handleCloseTest();
    navigate(MainRoutes.edu + EduRoutes.courses);
  };

  const handleStartTest = () => {
    sendWizCompletion();
    handleCloseTest();
    onStart?.();
  };

  const shuffledAnswers = useMemo(
    () => question?.answers?.sort(() => Math.random() - 0.5),
    [question?.answers]
  );

  return (
    <div className={classNames('QuizTest', className)}>
      {isQuestionLoading ? (
        <Loader />
      ) : (
        <>
          {question?.id ? (
            <>
              <div className="QuizTest__head">
                <div className="flex w-full flex-col lg:gap-2">
                  <div className="flex items-center justify-between gap-2">
                    <p>
                      <span className="inline lg-max:hidden">
                        {t('wiz.Category')}:{' '}
                      </span>
                      {question?.category?.title}
                    </p>
                    <img
                      src={question?.category?.image}
                      className="block h-8 w-8 flex-none self-start fill-primary-purple-blue-500 lg:!hidden"
                      alt="icon"
                    />
                  </div>
                  <h1>
                    <span className="inline lg-max:hidden">
                      {t('wiz.Question')}:{' '}
                    </span>
                    {question?.text}
                  </h1>
                </div>
                <img
                  src={question?.category?.image}
                  className="!hidden flex-none self-start fill-primary-purple-blue-500 lg:!block lg:h-[60px] lg:w-[60px]"
                  alt="icon"
                />
              </div>

              <div className="QuizTest__content">
                {shuffledAnswers?.map((answer) => (
                  <div
                    onClick={() =>
                      seconds !== 0 &&
                      question?.id &&
                      hndlSelect(question.id, answer.id)
                    }
                    className={classNames('relative', {
                      selected: !userAnswer?.correct && selected === answer.id,
                      success: userAnswer?.correct === answer.id,
                      error:
                        userAnswer?.correct !== answer.id && selected === answer.id,
                      'pointer-events-none': !!selected || seconds === 0,
                      loading: isSubmittingAnswer && selected === answer.id,
                    })}
                    key={answer.id}
                  >
                    <span>
                      {answer.text}
                      {isSubmittingAnswer && selected === answer.id && (
                        <LoaderMini className="absolute right-1 top-1 -mb-5 !h-5 !w-5 lg:!h-7 lg:!w-7" />
                      )}
                    </span>
                  </div>
                ))}
              </div>

              <div className="QuizTest__foot">
                <div
                  className={classNames('QuizTest__bar', {
                    ending: seconds < 6 && seconds > 0,
                  })}
                >
                  <div
                    className={classNames('QuizTest__sec', {
                      '!text-primary-red-500': seconds < 6 && seconds > 0,
                    })}
                  >
                    {seconds}
                  </div>{' '}
                  <ProgressBar size="md" rating={percentage} />
                </div>
              </div>
            </>
          ) : null}

          {window.innerWidth > 768 ? (
            <Modal isOpen={isStartOpen} onClose={handleStartTest} bgClose={false}>
              <QuizEndModal onStart={handleStartTest} onClick={goTo} />
            </Modal>
          ) : (
            <BottomSheet open={isStartOpen} onDismiss={handleStartTest}>
              <QuizEndModal onStart={handleStartTest} onClick={goTo} />
            </BottomSheet>
          )}
        </>
      )}
    </div>
  );
};
