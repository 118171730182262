import { useTranslation } from 'react-i18next';
import { ReactComponent as ERIcon } from '../../../../assets/icons/new-er.svg';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { useCourseCreation } from '../../../../providers/course-provider/course-creation-provider';
import { CourseAsideCard } from '../../../../widgets/CourseAsideCard';

export const CoursePreviewReward = () => {
  const { t } = useTranslation(['translation']);
  const { rewards } = useRewardContext();
  const { course } = useCourseCreation();

  const unit_completion_grade_a = rewards?.find(
    (reward) => reward.tokenomic_type === 'unit_completion_grade_a'
  )?.value;
  const unit_completion_grade_b = rewards?.find(
    (reward) => reward.tokenomic_type === 'unit_completion_grade_b'
  )?.value;
  const unit_completion_grade_c = rewards?.find(
    (reward) => reward.tokenomic_type === 'unit_completion_grade_c'
  )?.value;

  if (!course?.id) return null;

  return (
    <CourseAsideCard className="!flex lg-max:!hidden">
      <div className="flex items-start justify-between gap-3 self-stretch rounded-[30px] text-xs font-medium text-secondary-dark-grey-900 md:text-sm">
        <div>
          <span className="text-sm font-semibold text-primary-purple-blue-500 md:text-base">
            {(course?.rating && parseFloat(course?.rating?.toFixed(1))) || 0}
          </span>{' '}
          {t('course.course_rating')}
        </div>
        <div>
          <span className="text-sm font-semibold text-primary-purple-blue-500 md:text-base">
            {course?.enrolls_count || 0}
          </span>{' '}
          {t('course.Enrolls')}
        </div>
      </div>
      {!!course?.subject_level_units?.length && (
        <div className="inline-flex h-28 flex-col items-center justify-center gap-2 rounded-2xl bg-primary-purple-blue-100 px-3 py-2">
          <div className="inline-flex items-center justify-center gap-2.5 self-stretch">
            <div className="shrink grow basis-0 text-xs font-semibold leading-none text-primary-purple-blue-500">
              {t('course.The_reward_for_the_first_pass')}
            </div>
            <ERIcon className="h-3.5 w-3.5 fill-primary-purple-blue-500" />
          </div>
          {!!unit_completion_grade_a && (
            <div className="inline-flex items-center justify-center gap-2.5 self-stretch">
              <div className="shrink grow basis-0 text-[10px] font-medium leading-3 text-secondary-grey-900">
                {t('course.Evaluation')} А
              </div>
              <div className="shrink grow basis-0 text-right text-[10px] font-medium leading-3 text-secondary-grey-900">
                {' '}
                {course?.subject_level_units?.length *
                  Number(unit_completion_grade_a)}{' '}
                ERP
              </div>
            </div>
          )}
          {!!unit_completion_grade_b && (
            <div className="inline-flex items-center justify-center gap-2.5 self-stretch">
              <div className="shrink grow basis-0 text-[10px] font-medium leading-3 text-secondary-grey-900">
                {t('course.Evaluation')} В
              </div>
              <div className="shrink grow basis-0 text-right text-[10px] font-medium leading-3 text-secondary-grey-900">
                {' '}
                {course?.subject_level_units?.length *
                  Number(unit_completion_grade_b)}{' '}
                ERP
              </div>
            </div>
          )}
          {!!unit_completion_grade_c && (
            <div className="inline-flex items-center justify-center gap-2.5 self-stretch">
              <div className="shrink grow basis-0 text-[10px] font-medium leading-3 text-secondary-grey-900">
                {t('course.Evaluation')} С
              </div>
              <div className="shrink grow basis-0 text-right text-[10px] font-medium leading-3 text-secondary-grey-900">
                {' '}
                {course?.subject_level_units?.length *
                  Number(unit_completion_grade_c)}{' '}
                ERP
              </div>
            </div>
          )}
          <div className="inline-flex items-center justify-center gap-2.5 self-stretch">
            <div className="shrink grow basis-0 text-[10px] font-medium leading-3 text-secondary-grey-900">
              {t('course.Evaluation')} D-F
            </div>
            <div className="shrink grow basis-0 text-right text-[10px] font-medium leading-3 text-secondary-grey-900">
              0 ERP
            </div>
          </div>
        </div>
      )}
    </CourseAsideCard>
  );
};
