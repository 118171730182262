import { ImagesEnum } from '../../../providers/StartProvider/ImagesProvider';

export const backgroundCloudsToDraw: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [495, 883.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault],
  [323.24, 747.04, 94.76, 55, ImagesEnum.Cloud4MRDefault, true],
  [604.75, 747.3, 96.56, 42.36, ImagesEnum.Cloud2MRDefault],
  [164.25, 655.8, 96.56, 42.36, ImagesEnum.Cloud2MRDefault, true],
  [528, 624.01, 67.99, 48.41, ImagesEnum.Cloud1MRDefault, true],
  [710, 581.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault],
  [293, 467.19, 98.93, 58.82, ImagesEnum.Cloud3MRDefault, true],
  [509.24, 411.04, 94.76, 55, ImagesEnum.Cloud4MRDefault],
  [142.5, 383.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault],
  [354.5, 358.01, 67.99, 48.41, ImagesEnum.Cloud1MRDefault],
  [254.25, 277.3, 96.56, 42.36, ImagesEnum.Cloud2MRDefault],
  [643, 243.69, 98.93, 58.82, ImagesEnum.Cloud3MRDefault, true],
  [514.74, 196.04, 94.76, 55, ImagesEnum.Cloud4MRDefault],
  [328.24, 120.54, 94.76, 55, ImagesEnum.Cloud4MRDefault, true],
  [530.5, 62.51, 67.99, 48.41, ImagesEnum.Cloud1MRDefault, true],

  //block
  [364.91, 510, 67.22, 52.95, ImagesEnum.BlockMRWhite],
];

// Определение объектов для отрисовки с помощью ObjectBlock
export const bigIsland1: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [42.24, 0, 55.77, 98.41, ImagesEnum.TreeMRDefault, true, undefined, 200],
  [79.63, 62, 37.41, 66.02, ImagesEnum.TreeMRDefault, true, undefined, 200],
  [113.22, 37, 42.29, 74.62, ImagesEnum.TreeMRDefault, false, undefined, 200],
  [0, 73.12, 26.31, 38.34, ImagesEnum.StickMRSkull, true, undefined, 200],
  [0, 81.14, 156.5, 123.28, ImagesEnum.BlockMRBroken],
];

export const bigIsland2: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [12.61, 18.5, 55.77, 98.41, ImagesEnum.TreeMRDefault, true, undefined, 200],
  [40.11, 0, 55.77, 98.41, ImagesEnum.TreeMRDefault, true, undefined, 199],
  [86.59, 30.5, 42.29, 74.62, ImagesEnum.TreeMRDefault, true, undefined, 198],
  [58, 82.5, 28.87, 36.17, ImagesEnum.SignMRSkull, undefined, undefined, 201],
  [0, 80.5, 136.5, 107.52, ImagesEnum.BlockLRBroken],
];

export const bigIsland3: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [55.29, 0, 55.77, 98.41, ImagesEnum.TreeMRDefault, true, undefined, 200],
  [16.77, 45, 42.29, 74.62, ImagesEnum.TreeMRDefault, undefined, undefined, 200],
  [46.34, 84.85, 35.95, 29.71, ImagesEnum.CoinsMRBox, true, undefined, 200],
  [70.9, 93, 33.65, 30.33, ImagesEnum.CoinsMRBag, undefined, undefined, 200],
  [121, 105.27, 8.23, 14.48, ImagesEnum.FenceSRDefault, true, -12, 200],
  [127.3, 94.41, 12.33, 21.69, ImagesEnum.FenceMRDefault, true, -12, 199],
  [0, 84, 158.05, 124.5, ImagesEnum.BlockLRBroken],
];

export const bigIsland4: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [28, 46.22, 41.23, 69.22, ImagesEnum.TreeMRDefault, undefined, undefined, 201],
  [48.4, 0, 55.77, 93.53, ImagesEnum.TreeMRDefault, undefined, undefined, 200],
  [67.71, 72.68, 32.98, 55.55, ImagesEnum.TreeMRDefault, true, undefined, 202],
  [91.51, 78.45, 9.11, 14.97, ImagesEnum.FenceMRCone, undefined, undefined, 200],
  [100.62, 93.42, 6.94, 11.28, ImagesEnum.FenceMRCone, undefined, undefined, 200],
  [120.15, 87.78, 6.94, 11.28, ImagesEnum.FenceMRCone, undefined, undefined, 200],
  [97.15, 140.73, 95.48, 77.69, ImagesEnum.BlockMRStones, undefined, undefined, 201],
  [156.96, 130.29, 23.87, 27.56, ImagesEnum.SignMRArrow, false, -15, 202],
  [0, 80, 158.84, 124.99, ImagesEnum.BlockSRBlue, undefined, undefined, 199],
];

export const bigIsland5: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [42, 0, 55.77, 93.53, ImagesEnum.TreeMRDefault, undefined, undefined, 201],
  [64.52, 72.48, 32.98, 55.55, ImagesEnum.TreeMRDefault, undefined, undefined, 202],
  [97.51, 43.4, 41.23, 69.22, ImagesEnum.TreeMRDefault, undefined, undefined, 201],
  [78.77, 91.04, 30.16, 25.17, ImagesEnum.CoinsMRBag, undefined, undefined, 201],
  [2.83, 86.42, 14.11, 24.63, ImagesEnum.FenceMRDefault, undefined, -12.44, 201],
  [16.01, 97.06, 9.75, 16.68, ImagesEnum.FenceMRDefault, undefined, -12.44, 202],
  [0, 78.02, 158.84, 124.99, ImagesEnum.BlockMRBroken, undefined, undefined, 200],
];

export const bigIsland6: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [40.98, 0, 48.62, 85.81, ImagesEnum.TreeMRDefault, false, undefined, 200],
  [86.32, 39.24, 36.87, 65.06, ImagesEnum.TreeMRDefault, true, undefined, 200],
  [64.52, 73.98, 31.35, 25.9, ImagesEnum.CoinsMRBox, undefined, -3.53, 200],
  [46.65, 81.09, 29.34, 26.44, ImagesEnum.CoinsMRBag, true, undefined, 200],
  [12.34, 82.31, 10.75, 18.91, ImagesEnum.FenceSRDefault, false, -12, 200],
  [22.64, 91.79, 7.18, 12.63, ImagesEnum.FenceMRDefault, false, -12, 201],
  [0, 73.24, 137.8, 108.55, ImagesEnum.BlockLRBroken],
];

export const bigIsland7: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [24.28, 25.93, 55.28, 93.18, ImagesEnum.TreeMRDefault, true, undefined, 201],
  [43.66, 0, 58.99, 99.45, ImagesEnum.TreeMRDefault, undefined, undefined, 200],
  [84.12, 31.06, 47.02, 79.22, ImagesEnum.TreeMRDefault, true, undefined, 201],
  [72.94, 85.67, 29.63, 37.04, ImagesEnum.SignMRSkull, undefined, undefined, 201],
  [0, 83.17, 136.49, 107.43, ImagesEnum.BlockLRBroken, undefined, undefined, 200],
];

export const smallIsland1: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [0, 0, 51.59, 40.64, ImagesEnum.BlockMRWhite],
  [74.48, 7.62, 36.39, 29.23, ImagesEnum.StickMRDefault, undefined, undefined, 201],
  [25.72, 22.5, 70.65, 56.5, ImagesEnum.BlockMRBlue],
];

export const smallIsland2: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [19.09, 0, 38.57, 68.07, ImagesEnum.TreeMRDefault, undefined, undefined, 201],
  [0, 57.5, 67.22, 54.64, ImagesEnum.BlockMRStones, undefined, undefined, 200],
  [48.5, 90, 42.07, 33.14, ImagesEnum.BlockSRWhite, undefined, undefined, 201],
];

export const smallIsland3: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [0, 0, 82.5, 80.06, ImagesEnum.BlockMRDouble],
  [28.2, 0.62, 9.36, 15.53, ImagesEnum.FenceMRCone, undefined, undefined, 201],
  [45.18, 8.12, 7.02, 11.64, ImagesEnum.FenceSRCone, undefined, undefined, 201],
  [60.68, 1.12, 7.02, 11.64, ImagesEnum.FenceSRCone, undefined, undefined, 201],
  [51.5, 30.97, 17.35, 15.32, ImagesEnum.SkullMRDefault],
];

export const smallIsland4: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [10.98, 0, 35.29, 62.28, ImagesEnum.TreeMRDefault, undefined, undefined, 201],
  [48.49, 49.86, 8.57, 14.21, ImagesEnum.FenceMRCone, undefined, undefined, 201],
  [41.33, 56.84, 6.42, 10.65, ImagesEnum.FenceSRCone, undefined, undefined, 201],
  [0, 51.69, 61.5, 49.18, ImagesEnum.BlockMRBlue, undefined, undefined, 200],
];
export const smallIsland5: Array<
  [number, number, number, number, string, boolean?, number?, number?]
> = [
  [39, 0, 9.11, 14.76, ImagesEnum.FenceMRCone, undefined, undefined, 201],
  [53.38, 6.08, 6.94, 11.28, ImagesEnum.FenceMRCone, undefined, undefined, 202],
  [43.62, 11.72, 6.94, 11.28, ImagesEnum.FenceMRCone, undefined, undefined, 201],
  [0, 9.55, 82.68, 80.29, ImagesEnum.BlockMRDouble, undefined, undefined, 200],
  [24, 43, 16.71, 14.76, ImagesEnum.SkullMRDefault, undefined, undefined, 201],
];

export const characterPositions = {
  elementary: [
    { x: 538, y: 772, mirror: false }, // Уровень 1
    { x: 351, y: 631 - 10, mirror: true }, // Уровень 2
    { x: 519, y: 503 - 10, mirror: false }, // Уровень 3
    { x: 365, y: 390 - 10, mirror: true }, // Уровень 4
    { x: 523, y: 291 - 10, mirror: false }, // Уровень 5
    { x: 376, y: 175 - 10, mirror: true }, // Конечная точка
    { x: 448, y: 141 - 10, mirror: false }, // Точка вылета
  ],
  middle: [
    { x: 538, y: 648 - 10, mirror: false }, // Уровень 6
    { x: 358, y: 508 - 10, mirror: true }, // Уровень 7
    { x: 515, y: 378 - 10, mirror: false }, // Уровень 8
    { x: 393, y: 297 - 10, mirror: true }, // Конечная точка
  ],
  high: [
    { x: 540, y: 755, mirror: false }, // Уровень 9
    { x: 358, y: 614, mirror: true }, // Уровень 10
    { x: 523, y: 482, mirror: false }, // Уровень 11
    { x: 369, y: 369, mirror: true }, // Уровень 12
    { x: 532, y: 272, mirror: false }, // Конечная точка
  ],
};

export const schoolButtonDimensions = { width: 86.7, height: 38.1 };
export const schoolButtonPositions = {
  elementary: { x: 436.7, y: 109.34 },
  middle: { x: 436.67, y: 247.34 },
  high: { x: 436.67, y: 192.84 },
};

export const levelButtonPositions = {
  elementary: [
    { x: 351, y: 569 },
    { x: 519, y: 441 },
    { x: 365, y: 328 },
    { x: 523, y: 229 },
    { x: 376, y: 113 },
  ],
  middle: [
    { x: 358, y: 446 },
    { x: 515, y: 316 },
    { x: 393, y: 235 },
  ],
  high: [
    { x: 358, y: 562 },
    { x: 523, y: 430 },
    { x: 369, y: 317 },
    { x: 532, y: 214 },
  ],
};
