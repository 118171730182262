import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Auction } from '../../../../models/Auction';
import useForm, { ErrorMessagesCode } from '../../../../hooks/useForm';
import { winnerClaimCreate } from '../../../../api/bid';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import { MButton } from '../../../../horizon-components/MButton';

type AuctionViewWinnerFormData = {
  email: string;
  phone: string;
};

type Props = {
  auctionId: number;
  auction: Auction;
  onClose: () => void;
};

function normalizePhoneNumber(input: string) {
  // Удаляем все символы, кроме цифр
  let onlyDigits = input.replaceAll(/[^\d]/g, '');

  // Если номер начинается с 8, заменяем её на 7
  if (onlyDigits.startsWith('8')) {
    onlyDigits = '7' + onlyDigits.slice(1);
  }

  return onlyDigits;
}

export const AuctionViewWinnerForm: React.FC<Props> = ({
  auctionId,
  onClose,
  auction,
}) => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(t(ErrorMessagesCode.email))
          .required(t(ErrorMessagesCode.required)),
        phone: yup.string().required(t(ErrorMessagesCode.required)),
      }),
    []
  );

  const { values, onChange, errors, validate } = useForm<AuctionViewWinnerFormData>({
    email: '',
    phone: '',
  });

  const handleSubmit = async () => {
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }
    const normalizedPhone = normalizePhoneNumber(values.phone);
    setIsSending(true);
    winnerClaimCreate(auctionId, values.email, normalizedPhone)
      .then(() => {
        onClose();
      })
      .finally(() => setIsSending(false));
  };

  return (
    <div>
      <p
        className={
          'mb-[10px] text-center text-[18px] font-bold text-secondaryGrey-900'
        }
      >
        {t('bid.You’reWinner')}
      </p>
      <p className={'mb-[10px] text-center text-[18px] font-bold text-purple-500'}>
        {auction.title}
      </p>
      <p
        className={
          'mb-[10px] text-center text-[16px] font-[600] text-secondaryGrey-900'
        }
      >
        {t('bid.form')}
      </p>
      <MInput
        label={t('bid.email')}
        placeholder={t('bid.emailPl')}
        id="email"
        name={'email'}
        type="text"
        bottomText={errors.email}
        state={errors.email ? 'error' : 'default'}
        value={values.email}
        onChange={onChange}
        inputClassName={'w-full leading-[18px] mb-[10px]'}
        wrapperClassName={'w-full'}
      />
      <MInput
        label={t('bid.phone')}
        placeholder={t('bid.phonePl')}
        id="phone"
        name={'phone'}
        type="text"
        bottomText={errors.phone}
        state={errors.phone ? 'error' : 'default'}
        value={values.phone}
        onChange={onChange}
        inputClassName={'w-full leading-[18px] mb-[16px]'}
        wrapperClassName={'w-full'}
      />
      <div className={'flex justify-end'}>
        <MButton
          variant={'highlighted'}
          color={'primary'}
          disabled={isSending}
          onClick={handleSubmit}
        >
          {t('bid.send')}
        </MButton>
      </div>
    </div>
  );
};
