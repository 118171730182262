import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileCourseStats } from '../../../../features/profile-course-stats';
import { TCourseProgress } from '../../../../models/Unit';
import { ResponsePagination } from '../../../../models/Response';

interface ProfileCoursesStatsCardProps {
  className?: string;
  courses?: ResponsePagination<TCourseProgress> | null;
}

export const ProfileCoursesStatsCard = ({
  className,
  courses,
}: ProfileCoursesStatsCardProps) => {
  const { t } = useTranslation(['translation']);
  if (!courses?.results.length) return null;
  return (
    <div
      className={classNames(
        'flex flex-col gap-2 lg:gap-4',
        'rounded-2xl bg-white p-2 text-xs text-secondary-dark-grey-500 shadow-md shadow-shadow-700 lg:rounded-[30px] lg:p-6 lg:text-base ',
        className
      )}
    >
      <div className="flex items-center justify-between">
        <h5 className="text-sm font-semibold text-secondary-dark-grey-900">
          {t('profile.Completed_courses')}
        </h5>
        {
          <span className="text-sm font-semibold text-secondary-grey-900">
            {courses?.count || 0}
          </span>
        }
      </div>
      <ProfileCourseStats className="w-full" courses={courses?.results} />
    </div>
  );
};
