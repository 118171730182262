import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../horizon-components/MButton';
import { Modal } from '../../horizon-components/Modal';
import { DeleteAccountModal } from '../../widgets/DeleteAccountModal';

export const DeleteAccount: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [isShowModal, setIsShowModal] = useState(false);

  const showModal = () => {
    setIsShowModal(true);
  };

  const hideModal = () => {
    setIsShowModal(false);
  };

  return (
    <div className={'flex w-full items-center justify-between'}>
      <div>
        <h3 className="text-xl font-semibold text-secondary-grey-900">
          {t('buttons.delete_account')}
        </h3>
        <p>{t('profile.This_action_cannot_be_undone')}</p>
      </div>
      <MButton color={'danger'} variant="highlighted" onClick={showModal}>
        {t('buttons.delete_account')}
      </MButton>
      {window.innerWidth > 768 ? (
        <Modal
          title={t('buttons.delete_account')}
          isOpen={isShowModal}
          onClose={hideModal}
        >
          <DeleteAccountModal onClose={hideModal} />
        </Modal>
      ) : (
        <BottomSheet open={isShowModal} onDismiss={hideModal}>
          <DeleteAccountModal onClose={hideModal} />
        </BottomSheet>
      )}
    </div>
  );
};
