import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../providers';
import { BidCard } from '../../../../widgets/bid-card';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { Loader } from '../../../../components/Loader';

export const AuctionViewBidsOtherList = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { auction, bids, isBidsLoading } = useAuctionContext();
  const number_winners = auction?.number_winners || 0;

  const userBid = bids?.findIndex((bid) => bid.user.guid === user?.guid);

  const others = useMemo(() => {
    return (
      bids?.filter((item, idx) => {
        if (
          typeof userBid !== 'undefined' &&
          userBid > 0 &&
          userBid + 1 <= number_winners
        ) {
          return (
            idx === 0 || (idx + 1 <= number_winners && user?.guid === item.user.guid)
          );
        }
        return idx === 0 || idx + 1 === number_winners;
      }) || []
    );
  }, [bids, userBid, number_winners, user?.guid]);

  return (
    <>
      <div className={'flex w-full items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-gray-900'}>
          {t('bid.Winning_bets')}
        </p>
        {isBidsLoading ? (
          <Loader
            className="!h-[16px] !w-[16px]"
            wrapperClassName="!w-fit !min-h-[0px]"
          />
        ) : null}
      </div>
      {others?.length
        ? others?.map((bid, idx) => (
            <BidCard
              bid={bid}
              place={idx + 1}
              isMy={bid.user.guid === user?.guid}
              isWin={bid.user.guid !== user?.guid}
              isMin={idx + 1 === 2}
              key={idx}
            />
          ))
        : null}
    </>
  );
};
