import classNames from 'classnames';
import { TBanner } from '../../../api/banner';
import './Banners.scss';
import { Link } from '../../../horizon-components/Link';

interface BannersProps {
  className?: string;
  banners?: TBanner[];
}

export const Banners = ({ className, banners }: BannersProps) => {
  return (
    <div className={classNames('Banners', className)}>
      {banners?.map(
        (banner) =>
          !!banner.image && (
            <Link
              key={banner.id}
              to={banner.url}
              className={classNames('Banners__item', {
                'cursor-pointer': banner.url,
              })}
            >
              <img
                src={banner.image}
                alt="banner-desctop"
                className="!hidden w-full sm:!block"
              />
              <img
                src={banner.image_mobile}
                alt="banner-mobile"
                className="!block w-full sm:!hidden"
              />
            </Link>
          )
      )}
    </div>
  );
};
