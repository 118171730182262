import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getPrivateDailyReward } from '../../api/auth';
import { DailyRewardModal } from '../../features/DailyRewardModal';
import { Modal } from '../../horizon-components/Modal';
import { useUserContext } from '../UserProvider';
import { DailyRewardStatusEnum, TDailyRewardData } from '../../models/Reward';

interface DailyRewardContextType {
  isStartOpen: boolean;
  hndlOpenStartModal: () => void;
  hndlCloseStartModal: () => void;
  dailyRewardData: TDailyRewardData | null;
}

const DailyRewardContext = createContext<DailyRewardContextType | undefined>(
  undefined
);

interface DailyRewardProviderProps {
  children: ReactNode;
}

export const DailyRewardProvider: React.FC<DailyRewardProviderProps> = ({
  children,
}) => {
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [dailyRewardData, setDailyRewardData] = useState<null | TDailyRewardData>(
    null
  );
  const { user } = useUserContext();

  const hndlOpenStartModal = () => {
    setIsStartOpen(true);
  };

  const hndlCloseStartModal = () => {
    setIsStartOpen(false);
  };

  const onRewardClaimed = () => {
    if (!dailyRewardData) return;
    const newDailyRewardData = dailyRewardData;
    newDailyRewardData.daily_info.forEach((item) => {
      if (item.status === DailyRewardStatusEnum.CAN_BE_CLAIMED) {
        item.status = DailyRewardStatusEnum.CLAIMED;
      }
    });
    setDailyRewardData(newDailyRewardData);
  };

  useEffect(() => {
    if (user?.guid) {
      getPrivateDailyReward(user.guid).then(data => {
        setDailyRewardData(data);
        if (
          data.daily_info.some(
            (item) => item.status === DailyRewardStatusEnum.CAN_BE_CLAIMED
          )
        ) {
          hndlOpenStartModal();
        }
      });
    }
  }, [user?.guid]);

  return (
    <DailyRewardContext.Provider
      value={{
        isStartOpen,
        hndlOpenStartModal,
        hndlCloseStartModal,
        dailyRewardData,
      }}
    >
      {children}
      {!!user?.guid ? (
        <Modal
          isOpen={isStartOpen}
          onClose={hndlCloseStartModal}
          bgClose={false}
          showClose={false}
          maxWidth={764}
        >
          <DailyRewardModal
            onClose={hndlCloseStartModal}
            dailyRewardData={dailyRewardData}
            onRewardClaimed={onRewardClaimed}
          />
        </Modal>
      ) : null}
    </DailyRewardContext.Provider>
  );
};

export const useDailyReward = (): DailyRewardContextType => {
  const context = useContext(DailyRewardContext);
  if (!context) {
    throw new Error('useDailyReward must be used within a DailyRewardProvider');
  }
  return context;
};
