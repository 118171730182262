import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getPrivateCoursesV3,
  getPrivateEnrolledCoursesV3,
  getPrivateFavouriteCoursesV3,
} from '../../../api/courseV3';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import useFetch from '../../../hooks/useFetch';
import { Pagination } from '../../../horizon-components/Pagination';
import { CourseCardStatus } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { CourseCard } from '../../../widgets/CourseCard';
import useHistoryParams from '../../../hooks/useHistoryParams';

export const CoursesGetStarted: React.FC = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { user } = useUserContext();
  const {
    addParamToUrl,
    getParamFromUrl,
    removeParamFromUrl,
    removeEmptyParamsFromUrl,
  } = useHistoryParams();
  const pageParam = getParamFromUrl('page') || 1;
  const titleParam = getParamFromUrl('title') || '';
  const tagsOrTitleParam = getParamFromUrl('tags_or_title') || '';

  const { data, isLoading, fetchData: fetchCourses } = useFetch(getPrivateCoursesV3);
  const { data: enrolled, fetchData: fetchEnrolled } = useFetch(
    getPrivateEnrolledCoursesV3
  );
  const { data: favorites, fetchData: fetchFavourite } = useFetch(
    getPrivateFavouriteCoursesV3
  );

  useEffect(() => {
    if (user?.guid) {
      fetchEnrolled();
      fetchFavourite();
    }
  }, [t]);

  useEffect(() => {
    fetchCourses({
      page: Number(pageParam),
      miscellaneous_subject: true,
      language: i18n.language,
      ...(titleParam && { title: titleParam }),
      ...(tagsOrTitleParam && { tags_or_title: [tagsOrTitleParam] }),
    });
    removeEmptyParamsFromUrl();
  }, [i18n.language, pageParam, titleParam, tagsOrTitleParam]);

  const handleChangePage = (newPage: number) => {
    if (newPage === 1) {
      removeParamFromUrl('page');
    } else {
      addParamToUrl('page', newPage);
    }
  };

  return (
    <div className="flex flex-col gap-4 md:gap-[20px]">
      <h3 className="self-stretch text-base font-semibold text-secondary-grey-800 md:text-2xl md:font-bold">
        {t('course.coursesToGetYouStarted')}
      </h3>
      {isLoading ? (
        <Loader />
      ) : data?.results?.length ? (
        <div className="grid gap-x-6 gap-y-5 md:grid-cols-3 lg:grid-cols-4">
          {data?.results?.map((course) => (
            <CourseCard
              course={course}
              status={
                !!enrolled?.find((enroll) => enroll === course.id)
                  ? CourseCardStatus.ENROLLED
                  : CourseCardStatus.CAN_ENROLL
              }
              favorite={!!favorites?.includes(course?.id)}
              onFavorite={fetchFavourite}
              key={course.id}
            />
          ))}
        </div>
      ) : (
        <NotFound />
      )}
      {!!data?.count ? (
        <div className={'flex w-full items-center justify-center'}>
          <Pagination
            totalPages={Math.ceil(data?.count / 12)}
            currentPage={Number(pageParam)}
            showPrevNext={true}
            onPageChange={handleChangePage}
          />
        </div>
      ) : null}
    </div>
  );
};
