import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';

export const Link = ({ to, ...props }: LinkProps) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(to);
  };

  return <RouterLink to={to} {...props} onClick={handleClick} />;
};
