import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../horizon-components/Modal';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { RootState } from '../../stores';
import {
  selectIsOpenSubjectModal,
  setIsOpenLevelModal,
  setIsOpenSubjectModal,
} from '../../stores/subject';
import { LevelMap } from './LevelMap/LevelMap';
import './Start.scss';
import LevelModal from './components/LevelModal';
import StartGameModal from './components/StartGameModal';
import SubjectSwitcher from './components/SubjectSwitcher';
import SubjectsModal from './components/SubjectsModal';
import { useImagesContext } from '../../providers/StartProvider/ImagesProvider';
import { Loader } from '../../components/Loader';

const Start: FC = () => {
  const { t } = useTranslation(['translation']);
  const isOpenSubjectModal = useSelector(selectIsOpenSubjectModal);
  const isOpenLevelModal = useSelector(
    (state: RootState) => state.start.isOpenLevelModal
  );
  const { loadingProgress } = useImagesContext();
  const [subjectModalIsOpen, setSubjectModalIsOpen] = useState(isOpenSubjectModal);
  const [levelModalIsOpen, setLevelModalIsOpen] = useState(isOpenLevelModal);
  const dispatch = useDispatch();
  useEffect(() => {
    setSubjectModalIsOpen(isOpenSubjectModal);
  }, [isOpenSubjectModal]);

  useEffect(() => {
    if (isOpenLevelModal === true) {
      setLevelModalIsOpen(isOpenLevelModal);
    }
    if (isOpenLevelModal === false) {
      closeLevelModal();
    }
  }, [isOpenLevelModal]);

  const closeSubjectModal = () => {
    dispatch(setIsOpenSubjectModal(false));
    setSubjectModalIsOpen(false);
  };
  const closeLevelModal = () => {
    dispatch(setIsOpenLevelModal(false));
    setLevelModalIsOpen(false);
  };
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );
  const { i18n } = useTranslation(['translation']);
  const language = i18n.language;

  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const findSubjectData = () => {
    return subjectDetails?.[language]?.find(
      (data) => data.subjectId === currentSubjectId
    );
  };

  const subjectData = findSubjectData();

  return (
    <div
      className={'h-fit-content relative'}
      style={{ WebkitOverflowScrolling: 'touch' }}
    >
      <LevelMap />
      {loadingProgress < 100 && (
        <div className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-white/80">
          <Loader />
          <div className="mt-4 text-lg font-bold text-primary-purple-blue-500">
            {Math.round(loadingProgress)}%
          </div>
        </div>
      )}
      <div className="subject-avatar-container fixed left-2 z-50">
        <SubjectSwitcher />
        {/* <AvatarProgress /> */}
      </div>
      <StartGameModal />
      {/* <GetLevelRewardModal /> */}
      {isOpenSubjectModal ? (
        window.innerWidth > 768 ? (
          <Modal
            title={`${t('map.TrainingUnderTheProgram')}`}
            isOpen={subjectModalIsOpen}
            onClose={closeSubjectModal}
            bodyClassName="sm:!w-[474px]"
          >
            <SubjectsModal />
          </Modal>
        ) : (
          <BottomSheet open={subjectModalIsOpen} onDismiss={closeSubjectModal}>
            <SubjectsModal />
          </BottomSheet>
        )
      ) : undefined}

      {isOpenLevelModal ? (
        window.innerWidth > 768 ? (
          <Modal
            title={`${t('map.Level')} ${subjectData?.subjectLevel || ''}`}
            isOpen={levelModalIsOpen}
            onClose={closeLevelModal}
            bodyClassName="sm:!w-[474px]"
          >
            <LevelModal title={t('map.Level')} />
          </Modal>
        ) : (
          <BottomSheet open={levelModalIsOpen || false} onDismiss={closeLevelModal}>
            <LevelModal title={t('map.Level')} />
          </BottomSheet>
        )
      ) : undefined}
    </div>
  );
};

export default Start;
