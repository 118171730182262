import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { MobileAppBanner } from '../../components/MobileAppBanner/MobileAppBanner';
import { MobileNavBar } from '../../features/MobileNavBar';
import { Feedback } from '../../horizon-components/Feedback/Feedback';
import { useUserContext } from '../../providers';
import { Footer } from '../../widgets/Footer';
import Navbar from './Navbar';
import { bidRoutes, mainRoutes, userRoutes, weberRoutes, wizRoutes } from './routes';
import { AuthRouter } from './Routes/AuthRouter';
import { BidRouter } from './Routes/BidRouter';
import { EduRouter } from './Routes/EduRouter';
import { MainRoutes, ProfileRoutes } from './Routes/types/routes';
import { WeberRouter } from './Routes/WeberRouter';
import { WizRouter } from './Routes/WizRouter';
import { Sidebar } from './Sidebar';
import { NotFoundPage } from '../../components/NotFoundPage';
import { StartRouter } from './Routes/StartRouter';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../stores';
import { setIsMapPage } from '../../stores/subject';
import { Cookie } from '../../features/Cookie';
import { TasksRouter } from './Routes/TasksRouter';

export const MainLayout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(window.innerWidth >= 1100);
  const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false);
  const [hovered, setHovered] = useState(true);
  const [mini] = useState(true);
  const { t } = useTranslation(['translation']);
  const { pathname } = useLocation();
  const { user, isCurrentUser } = useUserContext();

  React.useEffect(() => {
    document.documentElement.dir = 'ltr';
    const onResize = () => {
      setOpen(window.innerWidth >= 1100);
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const isNetworkingPage = pathname.indexOf(ProfileRoutes.networking) > -1;
  const isServicesPage = pathname.indexOf('services') > -1;
  const isWizPage = pathname.indexOf(MainRoutes.wiz) > -1;
  const isMapPage = pathname.indexOf(MainRoutes.start) > -1;
  const isTasksPage = pathname.indexOf(MainRoutes.tasks) > -1;
  dispatch(setIsMapPage(isMapPage));
  const isAuthPage = pathname.indexOf(MainRoutes.auth) > -1;
  const isProfilePage = pathname.indexOf(MainRoutes.profile) > -1;
  const hideSidebar =
    !isCurrentUser && isProfilePage
      ? false
      : isAuthPage ||
        isNetworkingPage ||
        isServicesPage ||
        isWizPage ||
        isMapPage ||
        isTasksPage;

  const hideMobileBanner = isMapPage;

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes?.[i]?.items || []);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      }
    }
    return activeRoute;
  };

  const routes: RoutesType[] = useMemo(() => {
    if (
      location.pathname.indexOf(MainRoutes.edu) > -1 ||
      location.pathname.indexOf(MainRoutes.profile) > -1
    ) {
      return userRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
        items: item?.items
          ?.filter((el) => (!!el?.isStaffRoute ? !!user?.is_staff : true))
          .map((el) => ({
            ...el,
            path: el.path,
            name: t(`menu.${el.name}`),
          })) as unknown as RoutesType[],
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.weber) > -1) {
      return weberRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
        items: item.items?.map((el) => ({
          ...item,
          path: el.path,
          name: t(`menu.${item.name}`),
        })) as unknown as RoutesType[],
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.wiz) > -1) {
      return wizRoutes.map((item) => ({
        ...item,
        path: item.path,
        name: t(`menu.${item.name}`),
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.bid) > -1) {
      return bidRoutes.map((item) => ({
        ...item,
        path: item.path,
        name: t(`menu.${item.name}`),
      })) as RoutesType[];
    }

    return mainRoutes.map((item) => ({
      ...item,
      path: item.path,
      name: t(`menu.${item.name}`),
    }));
  }, [location.pathname, t, user?.is_staff]);

  React.useEffect(() => {
    getActiveRoute(routes as RoutesType[]);
    // eslint-disable-next-line
  }, [location.pathname]);

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes?.[i]?.items || []);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return !!routes?.[i]?.secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return <Route path={`${prop.path}`} element={prop.component} key={key} />;
      }
      if (prop.collapse) {
        return getRoutes(prop.items || []);
      }
      return null;
    });
  };

  const openFeedbackModal = () => setFeedbackModalIsOpen(true);
  const closeFeedbackModal = () => setFeedbackModalIsOpen(false);

  return (
    <div
      className={`flex h-[100vh] w-full flex-col ${isMapPage ? 'bg-[#2d396b] lg:bg-white' : ''} dark:bg-background-900`}
    >
      {!hideMobileBanner && <MobileAppBanner />}
      <Navbar isMapPage={isMapPage} />
      {!hideSidebar && (
        <Sidebar
          open={open}
          hovered={hovered}
          setHovered={setHovered}
          onClose={() => setOpen(false)}
          mini={mini}
          routes={routes as RoutesType[]}
          openFeedback={openFeedbackModal}
        />
      )}
      {/* Navbar & Main Content */}
      <div className={`h-full w-full  dark:bg-navy-900`}>
        {/* Main Content */}
        <main
          className={`flex h-full flex-col transition-all dark:bg-navy-900 sm:mx-2.5 ${
            hideSidebar
              ? 'xl:mx-7'
              : !mini
                ? 'side:ml-[253px]'
                : mini && hovered
                  ? 'side:ml-[253px]'
                  : 'ml-0 side:ml-[253px]'
          } 
          ${isMapPage ? 'sm:mx-0 xl:mx-0' : ''}`}
        >
          <div className={'lg:mt-6'} />
          <Routes>
            <Route path={MainRoutes.auth + '/*'} element={<AuthRouter />} />
            <Route path={MainRoutes.edu + '/*'} element={<EduRouter />} />
            <Route path={MainRoutes.weber + '/*'} element={<WeberRouter />} />
            <Route path={MainRoutes.wiz + '/*'} element={<WizRouter />} />
            <Route path={MainRoutes.start + '/*'} element={<StartRouter />} />
            <Route path={MainRoutes.bid + '/*'} element={<BidRouter />} />
            <Route path={MainRoutes.tasks + '/*'} element={<TasksRouter />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <div>
            <MobileNavBar />
          </div>
          {!isMapPage && <div className="hidden pt-24 lg-max:block" />}
          <div className="p-3 md-max:hidden">
            <Footer />
          </div>
        </main>
      </div>
      <Cookie />
      {feedbackModalIsOpen && <Feedback onClose={closeFeedbackModal} />}
    </div>
  );
};
