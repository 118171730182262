import classNames from 'classnames';
import { ProfileSubjectsStats } from '../../../../features/profile-subjects-stats';
import { ProfileCourseStats } from '../../../../features/profile-course-stats';
import { TSubjectMetrica } from '../../../../models/Course';
import { TCourseProgress } from '../../../../models/Unit';

interface ProfileStatsProps {
  className?: string;
  metric?: TSubjectMetrica | null;
  courses?: TCourseProgress[];
}

export const ProfileStats = ({ className, metric, courses }: ProfileStatsProps) => {
  return (
    <div
      className={classNames(
        'flex gap-2 lg:gap-4',
        'rounded-2xl bg-white p-2 text-xs text-secondary-dark-grey-500 shadow-md shadow-shadow-700 lg:rounded-[30px] lg:p-6 lg:text-base ',
        className
      )}
    >
      {metric ? (
        <ProfileSubjectsStats
          className={classNames('w-[55%]', { '!w-full': !courses?.length })}
          metric={metric}
        />
      ) : null}
      {!!metric && !!courses?.length ? (
        <div className="h-full w-[1px] bg-secondary-grey-500" />
      ) : null}
      {courses?.length ? (
        <ProfileCourseStats
          className={classNames('w-[45%]', { '!w-full': !metric })}
          courses={courses}
        />
      ) : null}
    </div>
  );
};
