import classNames from 'classnames';
import { TSubjectMetrica } from '../../../../models/Course';
import { ResponsePagination } from '../../../../models/Response';
import { TCourseProgress } from '../../../../models/Unit';
import { ProfileCoursesStatsCard } from './profile-courses-stats-card';
import { ProfileSubjectsStatsCard } from './profile-subjects-stats-card';

interface ProfileStatsProps {
  metric?: TSubjectMetrica | null;
  courses?: ResponsePagination<TCourseProgress> | null;
}

export const ProfileStatsGroup = ({ metric, courses }: ProfileStatsProps) => {
  return (
    <div
      className={classNames('grid w-full gap-2 lg:hidden', {
        'grid-cols-2': metric && courses?.results.length,
        'grid-cols-1': !metric || !courses?.results.length,
      })}
    >
      {metric ? <ProfileSubjectsStatsCard metric={metric} /> : null}
      {courses?.results.length ? (
        <ProfileCoursesStatsCard courses={courses} />
      ) : null}
    </div>
  );
};
