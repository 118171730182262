import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  CURRENT_USER_GUID,
  getAssesTokenStorage,
  getStorage,
  getUserSomeDataStorage,
  removeUserSomeDataStorage,
  removeUserStorage,
  saveUserStorage,
  setUserSomeDataStorage,
  USER,
} from '../api';
import {
  deleteAccount as apiDeleteAccount,
  authMe,
  getEdresWallets,
  setPublicReferralsMyReferralCode,
  TempUserData,
} from '../api/auth';
import { usePageTracking } from '../hooks/useAnalytics';
import useFetch from '../hooks/useFetch';
import { useNavigate } from '../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../horizon-layout/MainLayout/Routes/types/routes';
import { UserProfile } from '../models';
import { ERPWallet } from '../models/Wallet';
import { useModal } from './ModalProvider';
import { AppDispatch } from '../stores';
import { useDispatch } from 'react-redux';
import { resetState } from '../stores/subject';
import { useTonConnectUI } from '@tonconnect/ui-react';

type UserContextValue = {
  user: UserProfile | null;
  logout: (navigateToSignin?: boolean) => void;
  saveUser: (user: UserProfile) => void;
  saveTempUserData: (user: TempUserData) => void;
  tempUserData: TempUserData | null;
  deleteAccount: () => void;
  wallet: ERPWallet | null;
  updateWallet: () => Promise<ERPWallet[] | undefined>;
  setFirstLogin: (value: boolean) => void;
  setIsTrained: (value: boolean) => void;
  referral: string | null;
  firstLogin: boolean;
  userSomeData?: { [key: string]: string | null | undefined } | string | null;
  setUserSomeData: (key: string, value: any) => void;
  getUserSomeData: (key: string) => string | null | undefined;
  removeUserSomeData: (key: string) => void;
  isCurrentUser: boolean;
};

const UserContext = createContext<UserContextValue>({} as UserContextValue);

const UserProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentUserGuid = getUserSomeDataStorage(CURRENT_USER_GUID);
  const [user, setUser] = useState<UserProfile | null>(
    getUserSomeDataStorage(USER, currentUserGuid) || null
  );
  const [tempUserData, setTempUserData] = useState<TempUserData | null>(null);
  const [firstLogin, setFirstLogin] = useState(false);
  const [isTrained, setIsTrained] = useState(false);
  const refCode = searchParams.get('ref-code');
  const { data: walletData, fetchData: fetchEdresWallets } =
    useFetch(getEdresWallets);
  const wallet = walletData?.[0] || null;
  const { data: referralCode, fetchData: fetchReferralCode } = useFetch(
    setPublicReferralsMyReferralCode
  );
  const { openModal } = useModal();
  const dispatch: AppDispatch = useDispatch();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    // Показать рефералку если это первая авторизация, прошел обучение и нет рефералки
    if (firstLogin && isTrained && !refCode) {
      openModal('RefCodeModal');
    }
  }, [firstLogin, isTrained, refCode]);

  usePageTracking();

  useEffect(() => {
    refCode && sessionStorage.setItem('refCode', refCode);
  }, [refCode]);

  const saveUserState = (userId: string) => {
    const userData = getUserSomeDataStorage(USER, userId);
    saveUser(userData);
  };

  const getAuthMe = async () => {
    const userData = await authMe();
    saveUser(userData);
  };

  useEffect(() => {
    getAuthMe();
  }, []);

  useEffect(() => {
    const access = getAssesTokenStorage();
    if (user?.guid && access) {
      fetchEdresWallets();
      fetchReferralCode(user?.guid);
    }
  }, [user]);

  useEffect(() => {
    currentUserGuid && saveUserState(currentUserGuid);
  }, [currentUserGuid]);

  const saveUser = useCallback((user: UserProfile) => {
    saveUserStorage(user);
    setUser(user);
  }, []);

  const saveTempUserData = useCallback((user: TempUserData) => {
    setTempUserData(user);
  }, []);

  const logout = useCallback(
    (navigateToSignin: boolean = true) => {
      currentUserGuid && removeUserStorage(currentUserGuid);
      tonConnectUI.disconnect();
      dispatch(resetState());
      setUser(null);
      if (navigateToSignin) {
        navigate(MainRoutes.auth + AuthRoutes.signin);
      }
    },
    [navigate, currentUserGuid]
  );

  const deleteAccount = useCallback(async () => {
    tonConnectUI.disconnect();
    await apiDeleteAccount();
    dispatch(resetState());
    logout();
  }, [logout]);

  // User Some Data
  const userSomeData = useMemo(() => {
    if (currentUserGuid) {
      const storageData = getStorage(currentUserGuid);
      if (storageData) {
        return storageData;
      }
    }
  }, [currentUserGuid]);

  const getUserSomeData = useCallback(
    (key: string) => {
      return getUserSomeDataStorage(key, currentUserGuid);
    },
    [currentUserGuid]
  );

  const removeUserSomeData = useCallback(
    (key: string) => {
      removeUserSomeDataStorage(key, currentUserGuid);
    },
    [currentUserGuid]
  );

  const setUserSomeData = useCallback(
    (key: string, value: any) => {
      setUserSomeDataStorage(key, value, currentUserGuid);
    },
    [currentUserGuid]
  );
  // User Some Data

  const anotherUserGuid = pathname.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
  );
  const isCurrentUser =
    anotherUserGuid && user?.guid ? anotherUserGuid[0] === user?.guid : false;

  return (
    <UserContext.Provider
      value={{
        saveUser,
        logout,
        user,
        saveTempUserData,
        tempUserData,
        deleteAccount,
        wallet,
        updateWallet: fetchEdresWallets,
        referral: referralCode,
        setFirstLogin,
        firstLogin,
        setIsTrained,
        userSomeData,
        setUserSomeData,
        getUserSomeData,
        removeUserSomeData,
        isCurrentUser,
      }}
    >
      {children}
      {/*{!!currentUserGuid && !!firstLogin && !refCode && (*/}
      {/*  <Modal modalId="RefCodeModal" onClose={handleCloseFirstModal}>*/}
      {/*    <StartModal />*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

export { UserContext, UserProvider, useUserContext };
