import { Buffer } from 'buffer';

const bounceable_tag = 0x11;
const non_bounceable_tag = 0x51;
const test_flag = 0x80;

function crc16(data: Buffer | Uint8Array): Buffer {
  const poly = 0x1021;
  let reg = 0;
  const message = Buffer.concat([data, Buffer.alloc(2)]);

  for (let i = 0; i < message.length; i++) {
    let mask = 0x80;
    while (mask > 0) {
      reg <<= 1;
      if (message[i] & mask) {
        reg += 1;
      }
      mask >>= 1;
      if (reg > 0xffff) {
        reg &= 0xffff;
        reg ^= poly;
      }
    }
  }

  return Buffer.from([reg >> 8, reg & 0xff]);
}

function toStringBuffer(
  workChain: number,
  hash: ArrayLike<number>,
  args: { testOnly?: boolean; bounceable?: boolean } = {}
): Buffer {
  const testOnly = args.testOnly !== undefined ? args.testOnly : false;
  const bounceable = args.bounceable !== undefined ? args.bounceable : true;

  let tag = bounceable ? bounceable_tag : non_bounceable_tag;
  if (testOnly) {
    tag |= test_flag;
  }

  const addr = Buffer.alloc(34);
  addr[0] = tag;
  addr[1] = workChain;
  addr.set(hash, 2);

  const addressWithChecksum = Buffer.alloc(36);
  addressWithChecksum.set(addr);
  addressWithChecksum.set(crc16(addr), 34);

  return addressWithChecksum;
}

export function rawToFriendly(
  rawAddress: string,
  args = { urlSafe: true, bounceable: false }
): string {
  const [workChain, hash] = rawAddress.split(':');
  const hashBuffer = Buffer.from(hash, 'hex');

  const buffer = toStringBuffer(parseInt(workChain), hashBuffer, args);

  if (args.urlSafe) {
    return buffer.toString('base64').replace(/\+/g, '-').replace(/\//g, '_');
  } else {
    return buffer.toString('base64');
  }
}
