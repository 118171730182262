import React, { useRef } from 'react';
import Select, { SelectInstance } from 'react-select';
import { SelectOptions } from '../MMultSelect/MMultiSelect';
import './styles.scss';

type SelectOption = {
  value: string;
  label: string;
};

type Props = {
  id?: string;
  label?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string, name?: string) => void;
  value?: string;
  bottomText?: string;
  bottomTextClassName?: string;
  name?: string;
  autoComplete?: string;
  labelClassName?: string;
  state?: 'error' | 'success' | 'gray' | 'default';
  leftIcon?: React.ReactNode;
  leftIconWrapperClassName?: string;
  onBlur?: (e: any) => void;
  autoFocus?: boolean;
  options: SelectOption[];
  size?: 'sm' | 'md';
};

export const MSelect: React.FC<Props> = ({
  id,
  label,
  labelClassName,
  wrapperClassName,
  onChange,
  name,
  disabled,
  value,
  placeholder,
  autoComplete,
  bottomText,
  bottomTextClassName,
  inputClassName,
  state = 'default',
  leftIcon,
  leftIconWrapperClassName,
  onBlur,
  autoFocus,
  options,
  size = 'md',
}) => {
  const selectRef = useRef<SelectInstance<SelectOptions>>(null);

  const handleFocus = () => {
    selectRef.current?.controlRef?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const handleChange = (selectedOption: any) => {
    onChange?.(selectedOption?.value, name);
  };

  return (
    <div className={`${wrapperClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`mb-1 ml-4 text-sm font-medium text-gray-900 ${labelClassName} ${
            state === 'gray'
              ? 'text-gray-900'
              : state === 'success'
                ? 'text-green-700'
                : state === 'error'
                  ? 'text-red-700'
                  : ''
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        {!!leftIcon && (
          <div className={`absolute left-4 top-3 ${leftIconWrapperClassName}`}>
            {leftIcon}
          </div>
        )}
        <Select
          id={id}
          name={name}
          value={options.find((option) => option.value === value)}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          isDisabled={disabled}
          classNamePrefix="react-select"
          className={`m-form-select b-multi-base flex w-full appearance-none items-center rounded-2xl border-2 border-secondaryGrey-500 bg-white bg-no-repeat px-1 text-sm font-medium text-gray-700 outline-none placeholder:text-gray-500 ${inputClassName} ${!!leftIcon && 'pl-9'} ${
            state === 'error'
              ? '!border-red-500 !text-red-600 placeholder:!text-red-400'
              : state === 'success'
                ? '!border-green-500 !text-green-600 placeholder:!text-green-400'
                : state === 'gray'
                  ? '!placeholder:text-purple-700 !border-secondaryGrey-600 !text-purple-900'
                  : ''
          } ${
            disabled
              ? 'border-secondaryGrey-500 !bg-secondaryGrey-200 text-secondaryGrey-600 placeholder:!text-secondaryGrey-400'
              : ''
          }`}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onFocus={handleFocus}
          ref={selectRef}
        />
      </div>
      {!!bottomText && (
        <p
          className={`ml-4 mt-1 text-xs font-medium text-gray-500 ${bottomTextClassName} ${
            state === 'success'
              ? 'text-green-500'
              : state === 'error'
                ? 'text-red-500'
                : ''
          }`}
        >
          {bottomText}
        </p>
      )}
    </div>
  );
};
