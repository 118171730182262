import { WalletsListConfiguration } from '@tonconnect/ui-react';
import walletTgIcon from './../assets/images/wallets/wallettg.webp';
import deWalletIcon from './../assets/images/wallets/de-wallet.webp';
import tobiIcon from './../assets/images/wallets/tobi.webp';
import tonWalletIcon from './../assets/images/wallets/ton-wallet.webp';
import bitgetWalletIcon from './../assets/images/wallets/bitget-wallet.webp';
import nicegramIcon from './../assets/images/wallets/nicegram.webp';
import tokenPocketIcon from './../assets/images/wallets/tokenpocket.webp';
import aproNcwApiFileIcon from './../assets/images/wallets/apro-ncw-api-file.webp';
import pubTomoIcon from './../assets/images/wallets/pub-tomo.webp';
import hyperPayIcon from './../assets/images/wallets/hyper-pay.webp';
import unstoppableIcon from './../assets/images/wallets/unstoppable.webp';
import foxWalletIcon from './../assets/images/wallets/fox-wallet.webp';
import jamboIcon from './../assets/images/wallets/jambo.webp';
import gateIoWalletIcon from './../assets/images/wallets/gate-io-wallet.webp';
import ninetyEightIcon from './../assets/images/wallets/ninetyeight.webp';
import miraiAppIcon from './../assets/images/wallets/miraiapp.webp';
import oneKeyIcon from './../assets/images/wallets/onekey.webp';
import nestWalletIcon from './../assets/images/wallets/nest-wallet.webp';

export const walletsListConfiguration: WalletsListConfiguration = {
  includeWallets: [
    {
      appName: 'telegram-wallet',
      name: 'Wallet',
      imageUrl: walletTgIcon,
      aboutUrl: 'https://wallet.tg/',
      universalLink: 'https://t.me/wallet?attach=wallet',
      bridgeUrl: 'https://bridge.ton.space/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'tonwallet',
      name: 'TON Wallet',
      imageUrl: tonWalletIcon,
      aboutUrl:
        'https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd',
      universalLink: 'https://wallet.ton.org/ton-connect',
      jsBridgeKey: 'tonwallet',
      bridgeUrl: 'https://bridge.tonapi.io/bridge',
      platforms: ['chrome', 'android'],
    },
    {
      appName: 'nicegramWallet',
      name: 'Nicegram Wallet',
      imageUrl: nicegramIcon,
      aboutUrl: 'https://nicegram.app',
      universalLink: 'https://nicegram.app/tc',
      deepLink: 'nicegram-tc://',
      jsBridgeKey: 'nicegramWallet',
      bridgeUrl: 'https://tc.nicegram.app/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'tokenpocket',
      name: 'TokenPocket',
      imageUrl: tokenPocketIcon,
      aboutUrl: 'https://www.tokenpocket.pro',
      universalLink: 'https://tp-lab.tptool.pro/ton-connect/',
      jsBridgeKey: 'tokenpocket',
      bridgeUrl: 'https://ton-connect.mytokenpocket.vip/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'dewallet',
      name: 'DeWallet',
      imageUrl: deWalletIcon,
      aboutUrl: 'https://delabwallet.com',
      universalLink: 'https://t.me/dewallet?attach=wallet',
      bridgeUrl: 'https://bridge.dewallet.pro/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'cdcTonWallet',
      name: 'Crypto.com DeFi Wallet',
      imageUrl: aproNcwApiFileIcon,
      aboutUrl: 'https://crypto.com/defi-wallet',
      universalLink: 'https://wallet.crypto.com/deeplink/ton-connect',
      deepLink: 'dfw://',
      jsBridgeKey: 'cdcTonWallet',
      bridgeUrl: 'https://wallet.crypto.com/sse/tonbridge',
      platforms: ['ios', 'android', 'chrome'],
    },
    {
      appName: 'tobi',
      name: 'Tobi',
      imageUrl: tobiIcon,
      aboutUrl: 'https://tobi.fun',
      universalLink: 'https://t.me/TobiCopilotBot?attach=wallet',
      bridgeUrl: 'https://ton-bridge.tobiwallet.app/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    // {
    //   appName: 'trustwalletTon',
    //   name: 'Trust',
    //   imageUrl: 'https://assets-cdn.trustwallet.com/dapps/trust.logo.png',
    //   aboutUrl: 'https://trustwallet.com/about-us',
    //   bridgeUrl: 'https://tonconnect.trustwallet.com/bridge',
    //   universalLink: 'https://link.trustwallet.com/tc',
    //   deepLink: 'trust://ton-connect',
    //   jsBridgeKey: 'trustwalletTon',
    //   platforms: ['chrome', 'ios', 'android'],
    // },
    {
      appName: 'bitgetWalletLite',
      name: 'Bitget Wallet Lite',
      imageUrl: bitgetWalletIcon,
      aboutUrl: 'https://web3.bitget.com',
      universalLink: 'https://t.me/BitgetWallet_TGBot?attach=wallet',
      bridgeUrl: 'https://ton-connect-bridge.bgwapi.io/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'onekey',
      name: 'OneKey',
      imageUrl: oneKeyIcon,
      aboutUrl: 'https://onekey.so',
      jsBridgeKey: 'onekeyTonWallet',
      platforms: ['chrome'],
    },
    {
      appName: 'tomoWallet',
      name: 'Tomo Wallet',
      imageUrl: pubTomoIcon,
      aboutUrl: 'https://www.tomo.inc/',
      universalLink: 'https://t.me/tomotestwalletbot?attach=wallet',
      bridgeUrl: 'https://go-bridge.tomo.inc/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'hpyTonWallet',
      name: 'HyperPay Wallet',
      imageUrl: hyperPayIcon,
      aboutUrl: 'https://www.hyperpay.tech',
      universalLink:
        'https://www.hyperpay.tech/download&deeplink=hyperpay://web3/wallet/tonconnect',
      jsBridgeKey: 'hpyTonWallet',
      bridgeUrl: 'https://onchain-wallet.hyperpay.online/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'unstoppable',
      name: 'Unstoppable Wallet',
      imageUrl: unstoppableIcon,
      aboutUrl: 'https://unstoppable.money/',
      universalLink: 'https://unstoppable.money/ton-connect',
      bridgeUrl: 'https://bridge.unstoppable.money/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'foxwallet',
      name: 'FoxWallet',
      imageUrl: foxWalletIcon,
      aboutUrl: 'https://foxwallet.com/',
      universalLink: 'https://link.foxwallet.com/tc',
      jsBridgeKey: 'foxwallet',
      bridgeUrl: 'https://connect.foxwallet.com/ton/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'jambo',
      name: 'Jambo',
      imageUrl: jamboIcon,
      aboutUrl: 'https://www.jambo.technology/',
      deepLink: 'jambotc://',
      universalLink: 'https://jambophone.xyz/',
      bridgeUrl: 'https://bridge.tonapi.io/bridge',
      jsBridgeKey: 'jambowallet',
      platforms: ['android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'Gate.io wallet',
      name: 'Gate.io wallet',
      imageUrl: gateIoWalletIcon,
      aboutUrl: 'https://www.gate.io',
      universalLink: 'https://t.me/gateio_wallet_bot?attach=wallet',
      bridgeUrl: 'https://dapp.gateio.services/tonbridge_api/bridge/v1',
      platforms: ['ios', 'android', 'linux', 'windows', 'macos'],
    },
    {
      appName: 'coin98',
      name: 'Coin98 ',
      imageUrl: ninetyEightIcon,
      aboutUrl: 'https://docs.coin98.com',
      deepLink: 'coin98://ton-connect',
      bridgeUrl: 'https://ton-bridge.coin98.tech/bridge',
      platforms: ['ios', 'android'],
      universalLink: 'https://coin98.com/ton-conect',
    },
    {
      appName: 'miraiapp-tg',
      name: 'Mirai App',
      imageUrl: miraiAppIcon,
      aboutUrl: 'https://mirai.app',
      universalLink: 'https://t.me/MiraiAppBot?attach=wallet',
      bridgeUrl: 'https://bridge.tonapi.io/bridge',
      platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    },
    {
      appName: 'nestwallet',
      name: 'Nest Wallet',
      imageUrl: nestWalletIcon,
      aboutUrl: 'https://www.nestwallet.xyz',
      jsBridgeKey: 'nestwallet',
      platforms: ['chrome'],
    },
  ],
};
