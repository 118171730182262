import { useTranslation } from 'react-i18next';
import { Wallet } from '../../../models/Wallet';
import dayjs from 'dayjs';
import './styles.scss';

interface WalletTableProps {
  data: Wallet[];
}

export const WalletTable = ({ data }: WalletTableProps) => {
  const { t } = useTranslation(['translation']);
  if (!data?.length) return null;
  return (
    <table className="WalletTable">
      <thead>
        <tr>
          <th>{t('wallet.date')}</th>
          <th>{t('wallet.transaction')}</th>
          <th>{t('wallet.amount')}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((trans) => (
          <tr key={trans.id}>
            <td className="!whitespace-nowrap !text-nowrap">
              {dayjs(trans.created_at).format('DD/MM/YYYY')}
            </td>
            <td>{trans.title}</td>
            <td className="!whitespace-nowrap !text-nowrap">
              {Math.round(trans.amount)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
