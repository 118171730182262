import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useUserContext } from '../providers';
import { setPrivateCreateCryptoWallet } from '../api/tonWallet';
import { rawToFriendly } from '../utils/ton-address';
import { useEffect } from 'react';

export const useWalletConnection = () => {
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const { user, saveUser } = useUserContext();

  const handleDisconnectWallet = () => {
    if (wallet) {
      tonConnectUI.disconnect();
    }
  };

  const handleOpenWalletModal = async () => {
    await handleDisconnectWallet();
    tonConnectUI.openModal();
  };

  const updateWalletConnection = async () => {
    const tonAddress = wallet?.account.address
      ? rawToFriendly(wallet.account.address)
      : undefined;
    const publicKey = wallet?.account.publicKey;

    const hasWalletChanged =
      user?.crypto_wallet?.address !== tonAddress ||
      user?.crypto_wallet?.public_key !== publicKey ||
      (user?.crypto_wallet?.address === undefined && tonAddress === undefined);

    if (tonAddress && publicKey && hasWalletChanged) {
      try {
        await setPrivateCreateCryptoWallet({
          address: tonAddress,
          public_key: publicKey,
        });

        const updatedUser = {
          ...user!,
          crypto_wallet: { address: tonAddress, public_key: publicKey },
        };
        saveUser(updatedUser);
        return true;
      } catch (error) {
        console.error('Failed to create crypto wallet:', error);
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    updateWalletConnection();
  }, [wallet, user]);

  return {
    wallet,
    tonConnectUI,
    handleOpenWalletModal,
    handleDisconnectWallet,
    updateWalletConnection,
  };
};
