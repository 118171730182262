import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OrderBy } from '../../../../api/courseReview';
import { ButtonsFilter } from '../../../../components/ButtonsFilter';
import { CourseComment } from '../../../../components/CourseComment';
import { CourseRating } from '../../../../components/CourseRating';
import { Loader } from '../../../../components/Loader';
import { CourseFeedback } from '../../../../features/CourseFeedback';
import { Pagination } from '../../../../horizon-components/Pagination';
import { TRating } from '../../../../models/Review';
import { useCourseReview } from '../../../../providers';
import { PAGE_SIZE } from '../../../../shared/lib/const/limit';

export const CourseViewReview = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [filter, setFilter] = useState<OrderBy>('-rating');
  const [page, setPage] = useState(1);

  const {
    report,
    othersReviews,
    myComments,
    comments,
    isLoadingMine,
    isLoadingOthers,
    isEnrolled,
    refetchOthers,
    checkEnroll,
    liked,
    getRating,
  } = useCourseReview();

  useEffect(() => {
    checkEnroll(Number(id));
  }, [id]);

  useEffect(() => {
    refetchOthers({
      course: Number(id),
      page,
      review_owner: 'others',
      order_by: filter,
    });
  }, [filter, id, page, t]);

  const getAllInfo = () => {
    refetchOthers({
      course: Number(id),
      page,
      review_owner: 'others',
      order_by: filter,
    });
    getRating(Number(id));
  };

  const sort: { id: OrderBy; title: string }[] = [
    { id: '-rating', title: t('sort.positive') },
    { id: 'rating', title: t('sort.negative') },
    { id: '-created_at', title: t('sort.latest') },
  ];

  const handleFilter = (filterId: OrderBy) => {
    setFilter(filterId);
  };

  const generateReviewElement = (review: TRating, mine: boolean) => (
    <CourseComment
      key={review.id}
      userReaction={!!liked.find((like) => like === review.id)}
      user={review.user}
      reviewLikes={review.review_reactions}
      reviewDate={review.created_at}
      reviewRating={review.rating}
      reviewId={review?.id}
      mine={mine}
    >
      {review.comment}
    </CourseComment>
  );

  return (
    <div className="flex w-full flex-col gap-4 md:gap-6">
      {isLoadingMine ? (
        <Loader />
      ) : myComments?.length ? (
        generateReviewElement(myComments[0], true)
      ) : (
        isEnrolled && <CourseFeedback onSend={getAllInfo} courseId={Number(id)} />
      )}
      <CourseRating
        totalRating={report?.average_rating}
        ratingsBreakdown={report?.ratings_breakdown}
        key={id}
      />
      <div className="flex flex-col gap-[8px] md:gap-[16px]">
        {!!(othersReviews?.count && comments?.length > 0) && (
          <div className="flex justify-end">
            <ButtonsFilter buttons={sort} active={filter} onFilter={handleFilter} />
          </div>
        )}
        {isLoadingOthers ? (
          <Loader />
        ) : (
          <>
            {comments?.map((review: any) => generateReviewElement(review, false))}
            {othersReviews?.count > PAGE_SIZE && (
              <div className="flex w-full items-center justify-between">
                <Pagination
                  currentPage={page}
                  onPageChange={(pageNumber) => setPage(pageNumber)}
                  totalPages={Math.ceil(othersReviews?.count / PAGE_SIZE)}
                  maxVisiblePages={3}
                  showPrevNext={false}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
