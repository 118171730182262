import { FC, useEffect, useRef, useState } from 'react';
import {
  ImagesEnum,
  useImagesContext,
} from '../../../../providers/StartProvider/ImagesProvider';
import * as WinterItems from '../items/winterItems';
import { drawObject } from '../utils/drawing';
import { calculatePosition } from '../utils/calculatePosition';
import {
  schoolButtonDimensions,
  schoolButtonPositions,
  characterPositions,
  levelButtonPositions,
} from '../constants';
import { moveTo } from '../utils/moveTo';
import './CharacterMovementArea.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPublicSubjects,
  fetchPublicUnits,
  fetchSubjectDetails,
  setIsOpenLevelModal,
  updateProgressLevelData,
} from '../../../../stores/subject';
import { AppDispatch, RootState } from '../../../../stores';
import { getPrivateSubjectsProgress } from '../../../../api/subjectV3';
import useFetch from '../../../../hooks/useFetch';
import { useUserContext } from '../../../../providers';
import { useTranslation } from 'react-i18next';
import { saveCurrentUrl } from '../../../../api/sessions';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';

type CharacterMovementAreaProps = {
  className?: string;
};

const CharacterMovementArea: FC<CharacterMovementAreaProps> = ({ className }) => {
  const { user } = useUserContext();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const cloudsCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const levelButtonCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [positionLevelButton, setPositionLevelButton] = useState({
    x: -100,
    y: -100,
  });
  const { images, isLoaded, getImage } = useImagesContext();
  const [positionCharacter, setPositionCharacter] = useState({ x: 538, y: 772 });
  const dispatch: AppDispatch = useDispatch();
  const { data: progressLevelData, fetchData: fetchSubjectsProgress } = useFetch(
    getPrivateSubjectsProgress
  );
  const { i18n, t } = useTranslation(['translation']);
  const language = i18n.language;
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.guid) {
      fetchSubjectsProgress();
    }
  }, [user?.guid, t]);

  const [isProgressUpdated, setIsProgressUpdated] = useState(false);

  useEffect(() => {
    if (progressLevelData) {
      dispatch(updateProgressLevelData({ language, data: progressLevelData }));
      setIsProgressUpdated(true);
    }
  }, [progressLevelData, language, dispatch]);

  useEffect(() => {
    if (isProgressUpdated) {
      dispatch(fetchSubjectDetails('en')).unwrap();
      dispatch(fetchSubjectDetails('ru')).unwrap();
      setIsProgressUpdated(false);
    }
  }, [isProgressUpdated, dispatch]);

  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const determineInitialState = () => {
    return { level: 1, progress: 0 };
  };
  const [stateLevelProgress, setStateLevelProgress] =
    useState(determineInitialState);
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );
  const units = useSelector((state: RootState) => state.start.units);

  useEffect(() => {
    const subjectDetail = subjectDetails?.[language]?.find(
      (detail) => detail.subjectId === currentSubjectId
    );

    if (subjectDetail) {
      setStateLevelProgress({
        level: subjectDetail.subjectLevel,
        progress: subjectDetail.levelProgress,
      });
    }
  }, [subjectDetails, currentSubjectId, progressLevelData, units]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(fetchPublicUnits('en')).unwrap(),
          dispatch(fetchPublicSubjects('en')).unwrap(),
          dispatch(fetchPublicUnits('ru')).unwrap(),
          dispatch(fetchPublicSubjects('ru')).unwrap(),
        ]);

        await Promise.all([
          dispatch(fetchSubjectDetails('en')).unwrap(),
          dispatch(fetchSubjectDetails('ru')).unwrap(),
        ]);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [dispatch]);

  useEffect(() => {
    const canvas = levelButtonCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx && isLoaded) {
        canvas.width = 960;
        canvas.height = 960;

        const level = stateLevelProgress.level;
        const levelButtonImg = getImage(ImagesEnum.LevelButton);

        let newPositionLevelButton = { x: -100, y: -100 };

        if (level >= 1 && level <= 5) {
          newPositionLevelButton = levelButtonPositions.elementary[level - 1];
        } else if (level >= 6 && level <= 8) {
          newPositionLevelButton = levelButtonPositions.middle[level - 6];
        } else if (level >= 9 && level <= 12) {
          newPositionLevelButton = levelButtonPositions.high[level - 9];
        }

        setPositionLevelButton(newPositionLevelButton);

        const subjectDetail = subjectDetails?.[language]?.find(
          (detail) => detail.subjectId === currentSubjectId
        );

        if (!subjectDetail?.isLevelCompleted) {
          drawObject(
            ctx,
            newPositionLevelButton.x,
            newPositionLevelButton.y,
            69,
            80,
            levelButtonImg!
          );
        }
      }
    }
  }, [
    isLoaded,
    images,
    currentSubjectId,
    progressLevelData,
    getImage,
    dispatch,
    stateLevelProgress,
    subjectDetails,
  ]);

  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  useEffect(() => {
    const canvas = canvasRef.current;
    const cloudsCanvas = cloudsCanvasRef.current;
    if (canvas && cloudsCanvas && !isAnimationRunning) {
      const ctx = canvas.getContext('2d');
      const cloudsCtx = cloudsCanvas.getContext('2d');

      if (ctx && cloudsCtx && isLoaded) {
        canvas.width = 960;
        canvas.height = 960;

        const cloudsImg = getImage(ImagesEnum.Clouds);
        const characterImg = getImage(ImagesEnum.Character2);

        const isMirror = (level: number, progress: number): boolean => {
          return (
            (progress < 1 && [1, 3, 5, 6, 8, 9, 11].includes(level)) ||
            (level === 12 && progress === 1)
          );
        };

        const level = stateLevelProgress.level;
        const progress = stateLevelProgress.progress;

        drawObject(
          ctx,
          positionCharacter.x,
          positionCharacter.y,
          80,
          80,
          characterImg!,
          isMirror(level, progress)
        );

        ctx.imageSmoothingEnabled = false;

        const targetPosition = calculatePosition(level, progress);

        const getCharacterPositions = (level: number) => {
          if (level >= 1 && level <= 5) {
            return JSON.parse(JSON.stringify(characterPositions.elementary));
          } else if (level >= 6 && level <= 8) {
            return JSON.parse(JSON.stringify(characterPositions.middle));
          } else if (level >= 9 && level <= 12) {
            return JSON.parse(JSON.stringify(characterPositions.high));
          }
          return [];
        };

        const characterPositionsForLevel = getCharacterPositions(level);

        const lastIndex = characterPositionsForLevel
          .map((pos: { x: number; y: number }) => pos.y)
          .lastIndexOf(
            characterPositionsForLevel
              .filter((pos: { x: number; y: number }) => pos.y >= targetPosition.y)
              .pop()?.y ?? -1
          );

        if (
          positionCharacter.x !== targetPosition.x ||
          positionCharacter.y !== targetPosition.y
        ) {
          setIsAnimationRunning(true);
          if (lastIndex > -1) {
            // Перемещение по всем точкам от 0 до lastIndex
            const moveToPoints = characterPositionsForLevel.slice(0, lastIndex + 1);
            let currentIndex = 0;

            const moveToNextPoint = () => {
              if (currentIndex < moveToPoints.length) {
                const point = moveToPoints[currentIndex];
                moveTo(
                  point,
                  positionCharacter,
                  3,
                  drawObject,
                  characterImg!,
                  80,
                  80,
                  point.mirror,
                  canvas,
                  () => {
                    setPositionCharacter(point);
                    currentIndex++;
                    moveToNextPoint(); // Перемещаемся к следующей точке
                  }
                );
              } else {
                // После завершения перемещения ко всем точкам, перемещаемся к targetPosition
                moveTo(
                  targetPosition,
                  positionCharacter,
                  3,
                  drawObject,
                  characterImg!,
                  80,
                  80,
                  !isMirror(level, progress),
                  canvas,
                  () => {
                    drawObject(
                      ctx,
                      targetPosition.x,
                      targetPosition.y,
                      80,
                      80,
                      characterImg!,
                      isMirror(level, progress)
                    );
                    setPositionCharacter({
                      x: targetPosition.x,
                      y: targetPosition.y,
                    });
                    setIsAnimationRunning(false);
                  }
                );
              }
            };

            moveToNextPoint(); // Начинаем перемещение
          } else {
            // Если lastIndex <= -1, просто перемещаемся к targetPosition
            moveTo(
              targetPosition,
              positionCharacter,
              3,
              drawObject,
              characterImg!,
              80,
              80,
              !isMirror(level, progress),
              canvas,
              () => {
                setPositionCharacter(targetPosition);
              }
            );
            setIsAnimationRunning(false);
          }
        }

        const handleClick = (event: MouseEvent) => {
          const rect = canvas.getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          // Обновлено для проверки всех кнопок с учетом уровня и прогресса
          Object.entries(schoolButtonPositions).forEach(([key, button]) => {
            const isButtonEnabled =
              (key === 'elementary' && level === 5 && progress === 1) ||
              (key === 'middle' && level === 8 && progress === 1) ||
              (key === 'high' && level === 12 && progress === 1);

            if (
              isButtonEnabled &&
              x >= button.x &&
              x <= button.x + schoolButtonDimensions.width &&
              y >= button.y &&
              y <= button.y + schoolButtonDimensions.height
            ) {
              // Вызов соответствующей функции в зависимости от нажатой кнопки
              switch (key) {
                case 'elementary':
                  handleElementaryClick();
                  break;
                case 'middle':
                  handleMiddleClick();
                  break;
                case 'high':
                  handleHighClick();
                  break;
                default:
                  break;
              }
            }
          });
        };

        const characterJump = getImage(ImagesEnum.Character2Jump);
        const handleElementaryClick = () => {
          console.log('Elementary button clicked');
          // const targetPosition = { x: 448, y: 141 };
          // const currentPosition = {
          //   x: positionCharacter.x,
          //   y: positionCharacter.y,
          // };
          // const speed = 2;
          // moveTo(
          //   targetPosition,
          //   currentPosition,
          //   speed,
          //   drawObject,
          //   characterImg!,
          //   80,
          //   80,
          //   false,
          //   canvas,
          //   () => {
          //     drawObject(
          //       ctx,
          //       targetPosition.x,
          //       targetPosition.y,
          //       69,
          //       69,
          //       characterJump!,
          //       isMirror(level, progress)
          //     );
          //     setCurrentPosition('middle');
          //     // Перемещение облаков вниз
          //     moveTo(
          //       { x: -55, y: 1268 },
          //       { x: -55, y: -1100 },
          //       10,
          //       drawObject,
          //       cloudsImg!,
          //       1102,
          //       1400,
          //       false,
          //       cloudsCanvas
          //     );
          //     moveTo(
          //       { x: 450, y: 400 },
          //       targetPosition,
          //       4,
          //       drawObject,
          //       characterJump!,
          //       69,
          //       69,
          //       false,
          //       canvas,
          //       () => {
          //         moveTo(
          //           { x: 538, y: 665 },
          //           targetPosition,
          //           1.4,
          //           drawObject,
          //           characterJump!,
          //           69,
          //           69,
          //           true,
          //           canvas,
          //           () => {
          //             drawObject(ctx, 538, 648, 80, 80, characterImg!, true);
          //           }
          //         );
          //       }
          //     );
          //   }
          // );
        };

        const handleMiddleClick = () => {
          console.log('Middle button clicked');
          // const targetPosition = { x: 436.67, y: 220 };
          // const currentPosition = {
          //   x: positionCharacter.x,
          //   y: positionCharacter.y,
          // };
          // const speed = 2;
          // moveTo(
          //   targetPosition,
          //   currentPosition,
          //   speed,
          //   drawObject,
          //   characterImg!,
          //   80,
          //   80,
          //   false,
          //   canvas,
          //   () => {
          //     drawObject(
          //       ctx,
          //       targetPosition.x,
          //       targetPosition.y,
          //       69,
          //       69,
          //       characterJump!,
          //       isMirror(level, progress)
          //     );
          //     setCurrentPosition('high');
          //     moveTo(
          //       { x: -55, y: 1268 },
          //       { x: -55, y: -1100 },
          //       5,
          //       drawObject,
          //       cloudsImg!,
          //       1102,
          //       1400,
          //       false,
          //       cloudsCanvas
          //     );
          //     moveTo(
          //       { x: 450, y: 400 },
          //       targetPosition,
          //       2,
          //       drawObject,
          //       characterJump!,
          //       69,
          //       69,
          //       false,
          //       canvas,
          //       () => {
          //         moveTo(
          //           { x: 538, y: 665 + 120 },
          //           targetPosition,
          //           0.7,
          //           drawObject,
          //           characterJump!,
          //           69,
          //           69,
          //           true,
          //           canvas,
          //           () => {
          //             drawObject(ctx, 538, 648 + 120, 80, 80, characterImg!, true);
          //           }
          //         );
          //       }
          //     );
          //   }
          // );
        };

        const handleHighClick = () => {
          console.log('High button clicked');
        };
        canvas.addEventListener('click', handleClick);

        return () => {
          canvas.removeEventListener('click', handleClick);
        };
      }
    }
  }, [
    isLoaded,
    images,
    positionLevelButton,
    stateLevelProgress,
    isAnimationRunning,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const handleClickLevelButton = (event: MouseEvent) => {
      if (user?.guid === undefined) {
        if (!user) {
          saveCurrentUrl(window.location.pathname);
          navigate(MainRoutes.auth + AuthRoutes.signin);
          return;
        }
      }
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const subjectDetail = subjectDetails?.[language]?.find(
        (detail) => detail.subjectId === currentSubjectId
      );

      if (
        positionLevelButton &&
        x >= positionLevelButton.x &&
        x <= positionLevelButton.x + 69 &&
        y >= positionLevelButton.y &&
        y <= positionLevelButton.y + 80 &&
        !subjectDetail?.isLevelCompleted
      ) {
        dispatch(setIsOpenLevelModal(true));
      }
    };

    canvas.addEventListener('click', handleClickLevelButton);

    return () => {
      canvas.removeEventListener('click', handleClickLevelButton);
    };
  }, [
    user,
    navigate,
    subjectDetails,
    language,
    currentSubjectId,
    positionLevelButton,
    dispatch,
  ]);
  return (
    <div className={`${className}`}>
      <div className={`map-container`}>
        <canvas
          className="absolute-custom scale-100 overflow-hidden"
          ref={levelButtonCanvasRef}
          width={960}
          height={960}
        ></canvas>
        <canvas
          className="absolute-custom scale-100 overflow-hidden "
          ref={cloudsCanvasRef}
          width={960}
          height={960}
        ></canvas>
        <canvas
          className="absolute-custom scale-100 overflow-hidden "
          ref={canvasRef}
          width={960}
          height={960}
        ></canvas>
      </div>
    </div>
  );
};

export default CharacterMovementArea;
