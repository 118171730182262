import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../horizon-components/MButton';
import useFetch from '../../hooks/useFetch';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { BidStatusEnum } from '../../models/Auction';
import { getAuctionsV3 } from '../../api/bid';
import { MiniAuctionCard } from '../mini-auction-card';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { Link } from '../../horizon-components/Link';
import { MainRoutes } from '../../horizon-layout/MainLayout/Routes/types/routes';
import { groupAuctionsByStatus } from '../../modules/Bid/helpers';

interface BidMainPageCardProps {
  className?: string;
}

export const BidMainPageCard = ({ className }: BidMainPageCardProps) => {
  const { t } = useTranslation(['translation']);
  const hasFetched = useRef(false);

  const {
    data: actual,
    isLoading,
    fetchData: fetchActual,
  } = useFetch(getAuctionsV3);

  const handleFetch = useCallback(() => {
    if (!hasFetched.current) {
      fetchActual();
      hasFetched.current = true;
      setTimeout(() => {
        hasFetched.current = false;
      }, 5000);
    }
  }, [fetchActual]);

  useEffect(() => {
    hasFetched.current = false;
    handleFetch();
  }, [t, handleFetch]);

  const actualAuctions = useMemo(() => {
    if (!actual) {
      return Array(2).fill(null);
    }
    return groupAuctionsByStatus(actual?.results)[BidStatusEnum.ACTIVE];
  }, [actual]);

  return (
    <Link
      to={MainRoutes.bid}
      className={classNames(
        'inline-flex h-full flex-col items-start justify-start gap-3 rounded-2xl border border-[#e0e5f2] bg-[#f6f8fd] p-3 lg:gap-4 lg:p-6',
        className
      )}
    >
      <div className="flex flex-col items-start justify-start gap-1 self-stretch lg:gap-2">
        <h3 className="self-stretch text-base font-bold text-[#4318ff] lg:text-3xl">
          {t('main.Win')}
        </h3>
        <p className="self-stretch text-sm font-semibold leading-snug text-[#11047a] lg:text-lg lg:font-medium lg:text-[#1b2559]">
          {t('main.Spend_your_earned_tokens_on_real_auction_winnings')}
        </p>
      </div>
      <div className="inline-flex shrink grow basis-0 items-start justify-start gap-3 self-stretch">
        {actualAuctions.slice(0, 2).map((auction, index) => (
          <MiniAuctionCard
            key={index}
            data={auction}
            className={
              actualAuctions.slice(0, 2).length === 1
                ? 'min-h-[230px] lg:min-h-[360px]'
                : ''
            }
            isLoading={isLoading}
          />
        ))}
      </div>
      <MButton variant="highlighted" className="w-full">
        {t('buttons.Participate_in_the_auction')}
        <ArrowIcon className="Icon relative h-5 w-5" />
      </MButton>
    </Link>
  );
};
