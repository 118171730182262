import axios, { AxiosInstance } from 'axios';
import {
  clearStorage,
  getAssesTokenStorage,
  getRefreshTokenStorage,
  saveAssesTokenStorage,
  saveUserStorage,
} from './storages';
import { authMe, refreshToken } from './auth';
import i18n from '../i18n';
import {
  AuthRoutes,
  MainRoutes,
} from '../horizon-layout/MainLayout/Routes/types/routes';
import qs from 'qs';

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '';
export const SOCKET_URL = REACT_APP_API_URL.replace('https:', 'wss:');
export const API_URL = `${REACT_APP_API_URL}/api/edu`;
export const AUTH_API_URL = `${REACT_APP_API_URL}/api/auth`;
export const WEBER_API_URL = `${REACT_APP_API_URL}/weber/api`;

export const WIZ_API_URL = `${REACT_APP_API_URL}/api/wiz`;

export const IS_USER_DELETED_CODE = 'user_not_found';
export const IS_USER_INACTIVE = 'user_inactive';

const TOKEN_EXPIRING_SOON_THRESHOLD = 5 * 60 * 1000; // 5 минут
let refreshTokenPromise: Promise<string | null> | null = null;

const isTokenExpiringSoon = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = payload.exp * 1000;
    return expirationTime - Date.now() <= TOKEN_EXPIRING_SOON_THRESHOLD;
  } catch {
    return true;
  }
};

function instance(
  authorization = true,
  abort = true,
  url?: string,
  autoRefreshToken = true
): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: API_URL,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
        filter: (prefix, value) => (value === '' ? undefined : value),
      }),
  });

  if (authorization) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const language = i18n.language;
        config.headers['Accept-Language'] = language === 'kz' ? 'kk' : language;

        const token = getAssesTokenStorage();
        if (token) {
          if (isTokenExpiringSoon(token) && autoRefreshToken) {
            if (!refreshTokenPromise) {
              refreshTokenPromise = refreshTokenInBackground();
            }
            await refreshTokenPromise;
          }
          config.headers.Authorization = `JWT ${getAssesTokenStorage()}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (axios.isCancel(error)) return error;

        const originalRequest = error.config;
        const token = getRefreshTokenStorage();

        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          token &&
          autoRefreshToken
        ) {
          originalRequest._retry = true;

          if (!refreshTokenPromise) {
            refreshTokenPromise = refreshTokenInBackground();
          }

          const newToken = await refreshTokenPromise;
          if (newToken) {
            originalRequest.headers['Authorization'] = `JWT ${newToken}`;
            return axiosInstance(originalRequest);
          }
        }

        return Promise.reject(error);
      }
    );

    startTokenExpirationCheck();
  } else {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const language = i18n.language;
        config.headers['Accept-Language'] = language === 'kz' ? 'kk' : language;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  return axiosInstance;
}

async function refreshTokenInBackground(): Promise<string | null> {
  const token = getRefreshTokenStorage();
  if (!token) return null;

  try {
    const { access } = await refreshToken(token);
    saveAssesTokenStorage(access);
    const user = await authMe(false);
    saveUserStorage(user);
    return access;
  } catch (error: any) {
    handleAuthError(error);
    return null;
  } finally {
    refreshTokenPromise = null;
  }
}

function startTokenExpirationCheck() {
  setInterval(() => {
    const token = getAssesTokenStorage();
    if (token && isTokenExpiringSoon(token)) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshTokenInBackground();
      }
    }
  }, 60 * 1000);
}

function handleAuthError(error: any) {
  if (error.response?.data?.code === IS_USER_DELETED_CODE) {
    console.error('User was deleted:', error);
  } else if (error.response?.data?.code === IS_USER_INACTIVE) {
    console.error('User inactive:', error);
  } else {
    console.error('Refresh token expired:', error);
  }
  clearStorage();
  window.location.href = MainRoutes.auth + AuthRoutes.signin;
}

export default instance;
