import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { MyWallet } from '../../../modules/Edu/MyWallet';
import { Chat } from '../../../modules/Profile/Chat/Chat';
import { EditProfile } from '../../../modules/Profile/EditProfile/EditProfile';
import { Networking } from '../../../modules/Profile/Networking/Networking';
import { ProfileRoute } from '../../../modules/Profile/profile-route';
import { ProfileTasksPage } from '../../../modules/Profile/profile-tasks';
import { ProfileRoutes } from './types/routes';

export const ProfileRouter: React.FC = () => {
  return (
    <div className="AddBottom mx-auto w-full grow sm-max:p-2">
      <Routes>
        <Route path={ProfileRoutes.user + '/:id'} element={<ProfileRoute />} />
        <Route path={ProfileRoutes.edit} element={<EditProfile />} />
        <Route path={ProfileRoutes.chat} element={<Chat />} />
        <Route path={ProfileRoutes.networking} element={<Networking />} />
        <Route path={ProfileRoutes.wallet} element={<MyWallet />} />
        <Route path={ProfileRoutes.tasks} element={<ProfileTasksPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
