import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CoinIcon } from '../../components/CoinIcon';
import { Loader } from '../../components/Loader';
import { Badge } from '../../horizon-components/Badge';
import { Auction, BidStatusEnum } from '../../models/Auction';
import { formatNumber } from '../../shared/utils/formatNumber';
import { BidTime } from '../bid-time';
import { useBidServiceContext } from '../../providers/BidServiceProvider/BidServiceProvider';

interface MiniAuctionCardProps {
  data: Auction;
  className?: string;
  isLoading: boolean;
}

export const MiniAuctionCard = ({
  data,
  className,
  isLoading,
}: MiniAuctionCardProps) => {
  const { i18n } = useTranslation(['translation']);
  const { bidsCommonInfo } = useBidServiceContext();
  const auctionBidsCommonInfo = data?.id
    ? bidsCommonInfo?.[data.id.toString()]
    : undefined;
  const highestBid = auctionBidsCommonInfo?.highest_bid || '0';

  return (
    <div
      className={classNames(
        'relative inline-flex shrink grow basis-0 flex-col items-end justify-end gap-1 self-stretch',
        'min-h-[154px] w-full overflow-hidden rounded-xl lg:min-h-[308px]',
        className
      )}
      style={{
        backgroundImage: `url(${data?.images?.[0]?.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center bg-white">
          <Loader />
        </div>
      ) : (
        <div className="absolute bottom-1 right-1 inline-flex shrink grow basis-0 flex-col items-end justify-end gap-1 self-stretch rounded-xl">
          {data ? (
            <Badge size="xl" className="pointer-events-none !hidden lg:!flex">
              <BidTime data={data} status={BidStatusEnum.ACTIVE} hideText />
            </Badge>
          ) : null}
          {highestBid && data?.id ? (
            <Badge variant="primary" active size="xl" className="!hidden lg:!flex">
              {formatNumber(highestBid, i18n.language)}
              <CoinIcon className="fill-current relative !h-3.5 !w-3.5 stroke-0" />
            </Badge>
          ) : null}
          {data ? (
            <Badge variant="primary" active size="sm" className="!flex lg:!hidden">
              <BidTime data={data} status={BidStatusEnum.ACTIVE} hideText />
            </Badge>
          ) : null}
        </div>
      )}
    </div>
  );
};
