import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getMyAnswers,
  getUnitExam,
  setUnitExamAnswer,
  unitExamStaff,
} from '../../../../api/course';
import { Loader } from '../../../../components/Loader';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { MCheckbox } from '../../../../horizon-components/MCheckbox/MCheckbox';
import {
  Course,
  ExamAnswerVariant,
  StartedExam,
  UnitExamQuestion,
  UnitItem,
} from '../../../../models/Course';

type Props = {
  course: Course;
  currentUnitExamId: number;
  unitData: UnitItem;
  isStaff: boolean;
  isPreview: boolean;
  isLoading: boolean;
  submitExam: () => void;
  nextUnit: () => void;
};

export const CourseExamBody: React.FC<Props> = ({
  course,
  currentUnitExamId,
  unitData,
  isStaff,
  isPreview,
  isLoading,
  submitExam,
  nextUnit,
}) => {
  const { t } = useTranslation(['translation']);
  const [currentExamData, setCurrentExamData] = useState<StartedExam | null>(null);
  const [userAnswers, setUserAnswers] = useState<Record<string, number[]>>({});
  const [isAnswer, setIsAnswer] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (isStaff && isPreview) {
        const questions = await unitExamStaff(unitData.unit.id, course.id);
        setCurrentExamData({
          id: unitData.id,
          unit: {
            id: unitData.id,
            questions,
          },
        });
        return;
      }
      const [examData, myAnswers] = await Promise.all([
        getUnitExam(currentUnitExamId),
        getMyAnswers(currentUnitExamId),
      ]);
      setCurrentExamData(examData);
      setUserAnswers(
        Object.fromEntries(myAnswers.map((item) => [item.question, item.answer]))
      );
    })();
  }, [course.id, isStaff, unitData.id, isPreview]);

  const updateLocalState = (
    question: UnitExamQuestion,
    answer: ExamAnswerVariant,
    checked: boolean
  ) => {
    let updatedAnswers;

    let answers = userAnswers[question.id] as number[];
    if (!answers) answers = [];

    if (checked) {
      if (!question.is_multiple_answers) {
        updatedAnswers = {
          ...userAnswers,
          [question.id]: [answer.id],
        };
      } else {
        updatedAnswers = {
          ...userAnswers,
          [question.id]: [...answers, answer.id],
        };
      }
    } else {
      updatedAnswers = {
        ...userAnswers,
        [question.id]: answers.filter((id) => id !== answer.id),
      };
    }

    setUserAnswers(updatedAnswers);
    return updatedAnswers;
  };

  const updateServerAnswerState = async (
    updatedAnswers: Record<string, number[]>,
    question: UnitExamQuestion
  ) => {
    setIsAnswer(true);
    await setUnitExamAnswer(currentUnitExamId, {
      id: question.id,
      answer: updatedAnswers[question.id],
    }).finally(() => setIsAnswer(false));
  };

  const onChangeSelect = async (
    question: UnitExamQuestion,
    answer: ExamAnswerVariant,
    checked: boolean
  ) => {
    const updatedAnswers = updateLocalState(question, answer, checked);
    await updateServerAnswerState(updatedAnswers, question);
  };

  return (
    <>
      {/* Header */}
      <div className="flex items-center justify-between gap-2">
        <div className={'text-[14px] font-bold md:text-[18px]'}>
          {t('course.unitExam')}
        </div>
      </div>
      {/*Content*/}
      {!currentExamData ? (
        <Loader />
      ) : (
        <div className={'flex flex-col gap-[32px]'}>
          {currentExamData.unit.questions.map((question, index) => (
            <div className={'flex flex-col gap-[12px]'} key={question.id}>
              <div
                className={
                  'flex gap-2 text-sm font-semibold text-secondary-dark-grey-900 md:text-base'
                }
              >
                <span className="font-semibold">{index + 1}. </span>
                <div
                  className={'w-full font-medium'}
                  dangerouslySetInnerHTML={{ __html: question.text }}
                />
              </div>
              {question.unit_answers.map((answer) => (
                <MCheckbox
                  key={answer.id}
                  checked={
                    isPreview && isStaff
                      ? answer.correct
                      : !!(userAnswers[question.id] as number[])?.find(
                          (id) => answer.id === id
                        )
                  }
                  disabled={isPreview && isStaff}
                  onChange={(e) => {
                    if (isPreview && isStaff) return;
                    onChangeSelect(question, answer, e.target.checked);
                  }}
                  type={question.is_multiple_answers ? 'checkbox' : 'radio'}
                  id={`answer${answer.id}index${index + 1}`}
                  text={answer.text}
                />
              ))}
            </div>
          ))}
          {!(isStaff && isPreview) && (
            <div className={'flex w-full justify-between gap-3'}>
              <div
                className={
                  'text-[14px] font-normal text-secondary-dark-grey-500 md:text-[16px]'
                }
              >
                {t('course.noteInfo')}
              </div>
              {isPreview ? (
                <MButton
                  color={'primary'}
                  variant={'highlighted'}
                  onClick={nextUnit}
                >
                  {t('course.Next_unit')}
                </MButton>
              ) : (
                <MButton
                  onClick={submitExam}
                  color={'primary'}
                  variant={'highlighted'}
                  disabled={isLoading || isAnswer}
                >
                  {t('course.submit')}
                </MButton>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
