import { useSelector } from 'react-redux';
import { RootState } from '../stores';
import { useTranslation } from 'react-i18next';

const useCurrentSubjectData = () => {
  const { i18n } = useTranslation(['translation']);

  const language = i18n.language;
  const subjectDetails = useSelector(
    (state: RootState) => state.start.subjectDetails
  );
  const currentSubjectId = useSelector(
    (state: RootState) => state.start.currentSubjectId
  );

  const findSubjectData = () => {
    return subjectDetails?.[language]?.find(
      (data) => data.subjectId === currentSubjectId
    );
  };

  return findSubjectData();
};

export default useCurrentSubjectData;
