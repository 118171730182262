import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../../../hooks/useNavigate';
import { BottomSheet } from '../../../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../../../horizon-components/MButton';
import { Modal } from '../../../../horizon-components/Modal';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { useBidServiceContext } from '../../../../providers/BidServiceProvider/BidServiceProvider';
import { AuctionViewBidsAll } from './auction-view-bids-all';

export const AuctionViewBidsAllModal = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  let navigate = useNavigate();
  const [isAllBidsMode, setIsAllBidsMode] = useState(false);
  const { bids, auction } = useAuctionContext();
  const { bidsCommonInfo } = useBidServiceContext();

  const handleToggle = () => {
    setIsAllBidsMode(!isAllBidsMode);
  };

  const goTo = () =>
    navigate(
      MainRoutes.bid + AuctionRoutes.auctionView + `/${id}` + AuctionRoutes.bids
    );

  const biddersCount =
    auction && bidsCommonInfo && bidsCommonInfo[auction.id]
      ? bidsCommonInfo[auction.id].bidders_count
      : 0;

  if (!bids?.length) return null;

  return (
    <>
      <div className={'flex w-full items-center justify-between'}>
        <div
          className={
            'h-fit rounded-full bg-purple-100 px-[8px] py-[2px] text-[12px] font-[600] text-purple-400'
          }
        >
          {`${t('bid.more_bets', { biddersCount })}`}
        </div>
        <MButton
          variant={'transparent'}
          color={'primary'}
          onClick={() => (window.innerWidth >= 768 ? handleToggle() : goTo())}
        >
          {t('bid.Show_all_bets')}
        </MButton>
      </div>
      {isAllBidsMode &&
        (window.innerWidth > 768 ? (
          <Modal
            title={t('bid.allBids')}
            modalId={'bids-full-modal'}
            isOpen={isAllBidsMode}
            onClose={handleToggle}
            maxWidth={400}
          >
            <AuctionViewBidsAll onBidMode={() => setIsAllBidsMode(false)} />
          </Modal>
        ) : (
          <BottomSheet
            modalId={'bids-full-modal'}
            open={isAllBidsMode}
            onDismiss={handleToggle}
          >
            <AuctionViewBidsAll onBidMode={() => setIsAllBidsMode(false)} />
          </BottomSheet>
        ))}
    </>
  );
};
