import classNames from 'classnames';
import './Loader.scss';

interface LoaderProps {
  className?: string;
  wrapperClassName?: string;
}

export const Loader = ({ className, wrapperClassName }: LoaderProps) => {
  return (
    <div className={classNames("Loader", wrapperClassName)}>
      <div className={classNames('spinner', className)}></div>
    </div>
  );
};
