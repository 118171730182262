import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as NotifyIcon } from '../../../assets/icons/menu/notify.svg';
import { ReactComponent as ERIcon } from '../../../assets/icons/new-er.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/profile/edit.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/profile/share.svg';
import { ReactComponent as TransactionIcon } from '../../../assets/icons/transaction/transaction.svg';
import { ReactComponent as PenIcon } from '../../../assets/icons/profile/pen.svg';
import { useNavigate } from '../../../hooks/useNavigate';
import { useShareReferal } from '../../../hooks/useShareReferal';
import { MButton } from '../../../horizon-components/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useModal, useUserContext } from '../../../providers';
import { useRewardContext } from '../../../providers/RewardProvider';
import { formatNumber } from '../../../shared/utils/formatNumber';
import { useUserMeta } from '../../../entities/User';
import { TonWalletCard } from '../../WalletCard/TonWalletCard';

interface ProfileCardNewProps {
  className?: string;
  gpa_avg?: number;
  completed?: number;
}

export const ProfileCardNew = ({
  className,
  gpa_avg = 0,
  completed = 0,
}: ProfileCardNewProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const { logout, wallet, isCurrentUser } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const { data: userMeta } = useUserMeta(anotherUserGuid || '');
  const { handleClick } = useShareReferal();
  const { isLoading } = useRewardContext();
  const navigate = useNavigate();
  const { openModal } = useModal();

  const handleOpen = () => {
    openModal('details');
  };

  const avatarSrc =
    userMeta?.profile_image || require('../../../assets/images/profile.200.jpg');

  const buttons = useMemo(() => {
    return (
      <div className="flex flex-row justify-end gap-2 self-start">
        {!!isCurrentUser ? (
          <>
            <MButton
              color="white"
              variant="highlighted"
              className="!block shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] lg:!hidden lg-max:!px-2.5"
              onClick={() =>
                navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit)
              }
            >
              <EditIcon />
            </MButton>
            <MButton
              color="white"
              variant="highlighted"
              className="!block shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] lg:!hidden lg-max:!px-2.5"
              onClick={handleClick}
              loading={isLoading}
              disabled={isLoading}
              isLoaderCenter
            >
              <ShareIcon />
            </MButton>
            <MButton
              color={'danger'}
              variant="secondary"
              onClick={() => logout()}
              className="!hidden lg:!block"
            >
              {t('buttons.Log_Out')}
            </MButton>
          </>
        ) : (
          <MButton
            color="white"
            variant="highlighted"
            className="!hidden shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] lg:!flex lg-max:!px-2.5"
            onClick={handleClick}
            loading={isLoading}
            disabled={isLoading}
            isLoaderCenter
          >
            <span>{t('buttons.Share')}</span>
            <ShareIcon />
          </MButton>
        )}
      </div>
    );
  }, [handleClick, isCurrentUser, isLoading, logout, navigate, t]);

  const handlNotify = () => {
    openModal('Notifications');
  };

  return (
    <div
      className={classNames(
        'flex flex-col justify-between gap-2 lg:gap-6',
        'rounded-2xl bg-white p-2 text-xs text-secondary-dark-grey-500 shadow-md shadow-shadow-700 lg:rounded-[30px] lg:p-6 lg:text-base ',
        className
      )}
    >
      <div className="flex items-center justify-between gap-3 lg:gap-6">
        <div className="relative flex items-center gap-3 lg:gap-6">
          <div className="relative flex-none">
            <img
              className="h-[48px] w-[48px] rounded-full object-cover lg:h-[62px] lg:w-[62px]"
              src={avatarSrc}
              alt={userMeta?.username}
            />
            {isCurrentUser ? (
              <div className="absolute -bottom-1 -right-1 hidden h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-primary-purple-blue-500 p-2 lg:flex">
                <PenIcon
                  onClick={() =>
                    navigate(
                      MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit
                    )
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="flex flex-col justify-center gap-[2px] truncate lg:gap-2">
            <h4 className="truncate text-base font-bold text-secondary-grey-900 lg:text-3xl">
              {userMeta?.username}
            </h4>
            <p className="truncate">
              {userMeta?.last_name} {userMeta?.first_name}
            </p>
          </div>
        </div>
        {buttons}
      </div>

      <div
        className={classNames('justify-around gap-4 text-center', {
          'hidden lg:flex': isCurrentUser,
          flex: !isCurrentUser,
        })}
      >
        <div className="flex flex-col p-2 lg:gap-1">
          <h4 className="flex items-center justify-center text-sm font-bold text-secondary-grey-900 lg:text-3xl">
            {gpa_avg.toFixed(1)}
          </h4>
          <p>GPA</p>
        </div>

        <div className="flex flex-col p-2 lg:gap-1">
          <h4 className="flex items-center justify-center gap-1 text-sm font-bold text-secondary-grey-900 lg:gap-2 lg:text-3xl">
            {formatNumber(isCurrentUser ? wallet?.value || '0' : '0', i18n.language)}{' '}
            <ERIcon className="h-3 w-3 fill-secondary-grey-900 lg:h-6 lg:w-6" />
          </h4>
          <p>{t('profile.Balance')}</p>
        </div>

        <div className="flex flex-col p-2 lg:gap-1">
          <h4 className="flex items-center justify-center text-sm font-bold text-secondary-grey-900 lg:text-3xl">
            {completed}
          </h4>
          <p>WIZ</p>
        </div>
      </div>

      {isCurrentUser ? (
        <div className="flex gap-2 lg:hidden">
          <MButton
            color="primary"
            variant="secondary"
            className="w-full !px-0"
            onClick={handleOpen}
          >
            {t('edu.Transactions')} <TransactionIcon />
          </MButton>
          <MButton
            color="primary"
            variant="secondary"
            className="w-full !px-0"
            onClick={handlNotify}
          >
            {t('notifications.title')}{' '}
            <NotifyIcon className="fill-primary-purple-blue-500 lg:fill-primary-purple-blue-200" />
          </MButton>
        </div>
      ) : null}
      {isCurrentUser ? (
        <TonWalletCard className="flex w-full flex-col items-center justify-center" />
      ) : null}
    </div>
  );
};
