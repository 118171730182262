import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileAppBanner } from '../../../components/MobileAppBanner/MobileAppBanner';
import { Cookie } from '../../../features/Cookie';
import { MobileNavBar } from '../../../features/MobileNavBar';
import { ShareFriends } from '../../../features/ShareFriends';
import { useShareReferal } from '../../../hooks/useShareReferal';
import { BottomSheet } from '../../../horizon-components/bottom-sheet/ui';
import { Modal } from '../../../horizon-components/Modal';
import Navbar from '../../../horizon-layout/MainLayout/Navbar';
import { useModal } from '../../../providers/ModalProvider';
import { BidMainPageCard } from '../../../widgets/bid-main-page-card';
import { Footer } from '../../../widgets/Footer';
import { MainPageEarn } from '../../../widgets/MainPageEarn';
import { MainPageLearn } from '../../../widgets/MainPageLearn';
import { MainPageLearnMore } from '../../../widgets/MainPageLearnMore';
import { MainPageTeach } from '../../../widgets/MainPageTeach';
import { RewardModal } from '../../../widgets/RewardModal';
import './Main.scss';
import { useEffect, useMemo } from 'react';
import { groupAuctionsByStatus } from '../../Bid/helpers';
import { BidStatusEnum } from '../../../models/Auction';
import { getAuctionsV3 } from '../../../api/bid';
import useFetch from '../../../hooks/useFetch';

interface MainProps {
  className?: string;
}

export const Main = ({ className }: MainProps) => {
  const { t } = useTranslation(['translation']);
  const { closeModal } = useModal();
  const { url, activationRewardAmount } = useShareReferal();

  const {
    data: actual,
    isLoading,
    fetchData: fetchActual,
  } = useFetch(getAuctionsV3);

  useEffect(() => {
    fetchActual();
  }, [fetchActual]);

  const actualAuctions = useMemo(() => {
    if (!actual) {
      return Array(2).fill(null);
    }
    return groupAuctionsByStatus(actual?.results)[BidStatusEnum.ACTIVE];
  }, [actual]);

  return (
    <>
      <div className={classNames('Main', className)}>
        <MobileAppBanner />
        <Navbar />
        <div className={classNames('Main__wrapper', className)}>
          <div className="hidden flex-col gap-1 lg-max:flex">
            <h3 className="text-xl font-bold leading-tight text-[#1f242e]">
              {t('main.Study_and_earn_money')}
            </h3>
            <div className="text-sm font-semibold leading-[18px] text-[#11047a]">
              {t('main.Get_tokens_backed_by_knowledge')}
            </div>
          </div>
          <div className="grid gap-2 lg:grid-cols-2 lg:gap-6">
            <div className="hidden gap-4 lg:order-2 lg:grid lg:gap-6">
              <MainPageEarn hasIcon />
              <BidMainPageCard auctions={actualAuctions} isLoading={isLoading} />
            </div>
            <div className="grid gap-2 lg:order-2 lg:gap-6">
              <div className="!grid grid-cols-2 gap-2 lg:!hidden">
                <MainPageLearn />
                <MainPageEarn className="!flex lg:!hidden" />
              </div>
              <MainPageLearn hasIcon className="!hidden lg:!flex" />
              <BidMainPageCard
                className="inline-flex lg:hidden"
                auctions={actualAuctions}
                isLoading={isLoading}
              />
              <MainPageTeach hasIcon />
              <ShareFriends />
            </div>
          </div>
          <MainPageLearnMore className="!flex sm:mb-0 md:mb-5 lg:!hidden" />
          <Footer className={'lg-max:hidden'} />
        </div>
        <MobileNavBar />
        <Cookie />
        {window.innerWidth > 768 ? (
          <Modal modalId="registration-reward">
            <RewardModal
              onClick={() => closeModal('registration-reward')}
              rewardAmount={activationRewardAmount}
              url={url}
            />
          </Modal>
        ) : (
          <BottomSheet modalId="registration-reward">
            <RewardModal
              onClick={() => closeModal('registration-reward')}
              rewardAmount={activationRewardAmount}
              url={url}
            />
          </BottomSheet>
        )}
      </div>
    </>
  );
};
