import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getQuestionCategories } from '../../api/wiz/wiz';
import { Loader } from '../../components/Loader';
import useFetch from '../../hooks/useFetch';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { Modal } from '../../horizon-components/Modal';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { QuizTestModal } from '../QuizTestModal';
import './style.scss';

interface QuizCategoriesProps {
  className?: string;
}

export const QuizCategories = ({ className }: QuizCategoriesProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const [isEnergyModalOpen, setIsEnergyModalOpen] = useState(false);

  const {
    data,
    isLoading,
    fetchData: fetchCategories,
  } = useFetch(getQuestionCategories);

  const { livesCount, fetchStartQuiz } = useWizContext();
  const [isFetching, setIsFetching] = useState(false);

  const onCategoryClicked = async (categoryId: number) => {
    if (isFetching) return;
    setIsFetching(true);
    if (livesCount === 0) {
      setIsEnergyModalOpen(true);
    } else {
      await fetchStartQuiz(categoryId);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchCategories(i18n.language);
  }, [t]);

  return (
    <div className={classNames('QuizCategories', className)}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="QuizCategories__content">
            {data?.results.map((cat) => (
              <div onClick={() => onCategoryClicked(cat.id)} key={cat.id}>
                <img src={cat.image} alt={cat.title} />
                <h5>{cat.title}</h5>
              </div>
            ))}
          </div>
          {window.innerWidth > 768 ? (
            <Modal
              isOpen={isEnergyModalOpen}
              onClose={() => setIsEnergyModalOpen(false)}
              showClose={false}
            >
              <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
            </Modal>
          ) : (
            <BottomSheet
              open={isEnergyModalOpen}
              onDismiss={() => setIsEnergyModalOpen(false)}
            >
              <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
            </BottomSheet>
          )}
        </>
      )}
    </div>
  );
};
