import { SplashScreen } from '@capacitor/splash-screen';
import OneSignal from 'onesignal-cordova-plugin';
import { useEffect, useState } from 'react';
import { setOneSignalSubscriptions } from '../api/auth';
import useFetch from '../hooks/useFetch';
import { useUserContext } from './UserProvider';
import getPlatform from '../utils/getPlatform';
import { PlatformsEnum } from '../entities/Platforms/platforms';
declare global {
  interface Window {
    OpenReplay: any;
  }
}

export const PushProvider = () => {
  const { user, updateWallet } = useUserContext();
  const OSAPPID = process.env.REACT_APP_ONESIGNAL_WEB_APPID || '';
  const { fetchData: fetchSubscribe } = useFetch(setOneSignalSubscriptions);
  const [isOneSignalInitialized, setIsOneSignalInitialized] = useState(false);
  const [OneSignalWEB, setOneSignalWEB] = useState<any>(null);
  const platform = getPlatform();
  const SplashScreeHide = async () => {
    await SplashScreen.hide();
  };
  setTimeout(SplashScreeHide, 1500);

  const initializeOneSignal = () => {
    const { default: OneSignalWEB } = require('react-onesignal');
    setOneSignalWEB(OneSignalWEB);
    try {
      if (platform === PlatformsEnum.WEB) {
        OneSignalWEB.init({
          appId: OSAPPID,
          allowLocalhostAsSecureOrigin: true,
        }).then(() => {
          setIsOneSignalInitialized(true);
          OneSignalWEB.Debug.setLogLevel('error');
          // else {
          //   OneSignal.logout()
          // }
          // OneSignal.Slidedown.promptPush();
        });
        OneSignalWEB.Notifications.requestPermission()
          .then(() => {
            try {
              fetchSubscribe().then(() => updateWallet());
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error: any) => {
            console.log('Failed to request notification permission', error);
          });
      } else if (
        platform === PlatformsEnum.IOS ||
        platform === PlatformsEnum.ANDROID
      ) {
        //mobile ios & android
        OneSignal.initialize(OSAPPID);
        setIsOneSignalInitialized(true);
        OneSignal.Notifications.addEventListener(
          'click',
          async (e: { notification: any }) => {
            let clickData = await e.notification;
            console.log('Notification Clicked : ' + clickData);
          }
        );
        OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
          console.log('Notification permission granted ' + success);
          if (success) {
            try {
              fetchSubscribe().then(() => updateWallet());
            } catch (e) {
              console.log(e);
            }
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTimeout(initializeOneSignal, 5000);
  }, []);

  // console.log('user-m', user?.guid);
  window.OpenReplay.setUserID(user?.guid || '');
  // check platform
  // web
  useEffect(() => {}, []);

  useEffect(() => {
    setTimeout(SplashScreeHide, 1500);
    if (!isOneSignalInitialized) return;
    // check platform
    if (platform === PlatformsEnum.WEB) {
      if (user?.guid && OneSignalWEB) {
        console.log('user', user?.guid);
        window.OpenReplay.setUserID(user?.guid);
        console.log(window.OpenReplay.getSessionID());
        OneSignalWEB.login(user?.guid);
        // OneSignalWEB.User.addEmail(user?.email);
        OneSignalWEB.User.addAlias('user_name', user?.user_meta.username);
      }
    } else if (
      platform === PlatformsEnum.IOS ||
      platform === PlatformsEnum.ANDROID
    ) {
      if (user?.guid) {
        console.log('user', user?.guid);
        window.OpenReplay.setUserID(user?.guid);
        console.log(window.OpenReplay.getSessionID());
        OneSignal.login(user?.guid);
        // OneSignal.User.addEmail(user?.email);
        OneSignal.User.addAlias('user_name', user?.user_meta.username);
      }
    }
  }, [user, isOneSignalInitialized]);

  return null;
};
