import { FocusEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import getPlatform from "../../utils/getPlatform";
import { PlatformsEnum } from "../../entities/Platforms/platforms";
import { Clipboard } from "@capacitor/clipboard";

type Props = {
    children: React.ReactNode
}


export const ContextMenu = ({ children }: Props) => {
    const platform = getPlatform();
    const { t } = useTranslation(['translation'])

    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [canPaste, setCanPaste] = useState(false);
    const [canCopy, setCanCopy] = useState(false);
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [lastActiveElement, setLastActiveElement] = useState<HTMLInputElement | null>(null);

    const handleCopy = async () => {
        if (!canCopy) {
            return;
        }
        const selectedText = window.getSelection()?.toString();
        if (selectedText) {
            try {
                Clipboard.write({
                    string: selectedText
                })
                setLastActiveElement(null);
                window.getSelection()?.removeAllRanges();
                setMenuVisible(false);
            } catch (error) {
                console.error('Copy failed:', error);
            }
        }
    };

    const handlePaste = async () => {
        if (!canPaste) {
            return;
        }
        try {
            const { value: clipboardValue } = await Clipboard.read();
            if (lastActiveElement) {
                const selectionStart = lastActiveElement.selectionStart;
                const selectionEnd = lastActiveElement.selectionEnd;
                if (selectionStart !== null && selectionEnd !== null) {
                    const text = lastActiveElement.value;
                    const newText = text.slice(0, selectionStart) + clipboardValue + text.slice(selectionEnd);

                    lastActiveElement.value = newText;
                    // @ts-ignore
                    lastActiveElement._valueTracker?.setValue("");
                    lastActiveElement.dispatchEvent(new Event("input", { bubbles: true }));
                }
            }
            setMenuVisible(false);
        } catch (error) {
            console.error('Paste failed:', error);
        }
    };

    const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        if (platform !== PlatformsEnum.ANDROID) {
            return;
        }
        if (!containerRef.current) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        const activeElement = document.activeElement as HTMLInputElement;
        const panelRect = containerRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth + window.scrollX;
        const windowHeight = window.innerHeight + window.scrollY;

        const leftPadding = 20
        const topPadding = 40
        const bottomRightPadding = 20

        const endX = panelRect.width + event.pageX + leftPadding;
        const endY = panelRect.height + event.pageY + topPadding;
        const moveLeftPixels = Math.max(0, endX - windowWidth + bottomRightPadding);
        const moveTopPixels = Math.max(0, endY - windowHeight + bottomRightPadding);

        setMenuVisible(true);
        setLastActiveElement(activeElement);
        setMenuPosition({ x: event.pageX - moveLeftPixels + leftPadding, y: event.pageY - moveTopPixels + topPadding });
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (!event.target) {
            setMenuVisible(false);
            return;
        }

        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        if (platform === PlatformsEnum.ANDROID) {
            document.addEventListener("click", handleClickOutside);
            return () => {
                document.removeEventListener("click", handleClickOutside);
            };
        }
    }, []);

    useEffect(() => {
        if (platform !== PlatformsEnum.ANDROID) {
            return;
        }
        if (!menuVisible) {
            return;
        }

        const activeElement = document.activeElement as HTMLInputElement;
        Clipboard.read().then(({ value }) => {
            if (value && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA") && !activeElement.readOnly) {
                setCanPaste(true);
            } else {
                setCanPaste(false);
            }
        }).catch((error) => {
            setCanPaste(false);
        });
        const hasSelection = window.getSelection()?.toString();
        if (hasSelection) {
            setCanCopy(true);
        } else {
            setCanCopy(false);
        }
    }, [menuVisible])

    if (platform !== PlatformsEnum.ANDROID) {
        return <>{children}</>;
    }

    return <div
        style={{ width: "100%", height: "100vh" }}
        onContextMenu={handleContextMenu}
    >
        {children}
        <div
            ref={containerRef}
            style={{
                position: "absolute",
                top: menuVisible ? menuPosition.y : -1000,
                left: menuVisible ? menuPosition.x : -1000,
                backgroundColor: "#fff",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                zIndex: 1000,
                padding: "10px",
                borderRadius: "4px",
            }}
        >
            <div className="flex flex-row gap-3 text-sm font-medium text-gray-500">
                <button onClick={handleCopy} className={`${canCopy ? "text-gray-800" : "text-gray-500 cursor-default"}`}>{t("btnsText.copy")}</button>
                <button onClick={handlePaste} className={`${canPaste ? "text-gray-800" : "text-gray-500 cursor-default"}`}>{t("btnsText.paste")}</button>
            </div>
        </div>
    </div>
};