import classNames from 'classnames';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getSubjectsList } from '../../../../api/dictionaries';
import {
  communitiesCoveredImageList,
  createCommunity,
  getCommunity,
  updateCommunity,
} from '../../../../api/weber/communities';
import { ReactComponent as Document } from '../../../../assets/icons/document.svg';
import { ReactComponent as Drag } from '../../../../assets/icons/drag.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.primary.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/trash.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { DragAndDrop } from '../../../../components/DragAndDrop/DragAndDrop';
import { Loader } from '../../../../components/Loader';
import useForm, { ErrorMessagesCode } from '../../../../hooks/useForm';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Card } from '../../../../horizon-components/Card/Card';
import { SelectOptions } from '../../../../horizon-components/HSelect/HSelect';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import { MSelect } from '../../../../horizon-components/MSelect/MSelect';
import { MTextArea } from '../../../../horizon-components/MTextArea/MTextArea';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { TCommunity } from '../../../../models/Community';
import { ImageItem } from '../../../../models/Course';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import getErrorMessages from '../../../../utils/getErrorMessages';
import objectToFormData from '../../../../utils/objectToFormData';
import { GroupLinkItem, TGroupLinkItem } from './GroupLinkItem';

type Props = {
  isEdit?: boolean;
};

type CommunitiesCreateForm = {
  name: string;
  goal: string;
  description: string;
  subject: string;
};

export const CommunitiesForm: React.FC<Props> = ({ isEdit }) => {
  const navigate = useNavigate();
  let { id: communityId } = useParams();
  const [imagesLoading, setImagesLoading] = useState(false);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [activeImageId, setActiveImageId] = useState<number | string | null>(null);
  const [subjectsList, setSubjectsList] = useState<SelectOptions[]>([]);
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const [avatarImagePath, setAvatarImagePath] = useState<string>('');
  const [community, setCommunity] = useState<TCommunity | null>(null);
  const [communityCoverImage, setCommunityCoverImage] = useState<
    File | string | null
  >(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [groups, setGroups] = useState<TGroupLinkItem[]>([{ name: '', link: '' }]);
  const { t } = useTranslation(['translation']);
  const { useTitleBack } = useTitleBackContext();

  const { values, errors, setValues, onChange, validate } =
    useForm<CommunitiesCreateForm>({
      name: '',
      goal: '',
      description: '',
      subject: '',
    });

  const title = !isEdit ? t('communities.create') : t('communities.edit');

  useTitleBack(
    title,
    isEdit
      ? MainRoutes.weber + WeberRoutes.community + `/${communityId}`
      : MainRoutes.weber + WeberRoutes.communities
  );

  const fetchCommunity = useCallback(
    (communityId: number) => {
      if (!isEdit) return;
      if (!communityId) return;

      getCommunity(communityId).then((data) => {
        setCommunity(data);
        setCommunityCoverImage(data.cover_image);
        if (!!data.predefined_image) {
          setActiveImageId(data.predefined_image.id);
        } else {
          setAvatarImagePath(data.custom_image);
          setActiveImageId(data.custom_image);
        }
        setGroups(data.group_links || []);
        setValues((v) => ({
          ...v,
          name: data.name,
          goal: data.goal,
          subject: data.subject,
          description: data.description,
        }));
      });
    },
    [isEdit, setValues]
  );

  useEffect(() => {
    fetchCommunity(parseInt(communityId || ''));
  }, [communityId, fetchCommunity, navigate]);

  useEffect(() => {
    getSubjectsList().then((subjects) => {
      subjects?.length &&
        setSubjectsList([
          { label: `${t('weber.Select')}`, value: '' },
          ...subjects?.map((item) => ({
            label: item.title,
            value: item.slug,
          })),
        ]);
    });
  }, [t]);

  useEffect(() => {
    setImagesLoading(true);
    communitiesCoveredImageList()
      .then((data) => {
        setImages(data);
      })
      .finally(() => setImagesLoading(false));
  }, []);

  const onCoverImageDrop = (files: File[]) => {
    setCommunityCoverImage(files[0]);
  };

  const removeCoverImage = () => {
    setCommunityCoverImage(null);
  };

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t(ErrorMessagesCode.required)),
        goal: yup
          .string()
          .max(1000, t('error.max1000'))
          .required(t(ErrorMessagesCode.required)),
        subject: yup.string().required(t(ErrorMessagesCode.required)),
        description: yup.string().required(t(ErrorMessagesCode.required)),
      }),
    [t]
  );

  const submitForm = async () => {
    setSubmitButtonDisabled(true);
    try {
      const hasError = await validate(schema);

      if (hasError) {
        return;
      }

      if (!activeImageId) {
        toast(t('communities.communityImageError'), {
          type: 'error',
        });
        return;
      }

      const form: {
        name: string;
        subject: string;
        goal: string;
        description: string;
        custom_image?: File;
        predefined_image?: string;
        cover_image?: File;
        group_links?: TGroupLinkItem[];
      } = {
        name: values.name,
        subject: values.subject as string,
        goal: values.goal,
        description: values.description,
      };

      if (typeof activeImageId === 'string') {
        if (!!avatarImage) form.custom_image = avatarImage;
      } else {
        form.predefined_image = activeImageId?.toString() || '';
      }
      if (communityCoverImage instanceof File) {
        form.cover_image = communityCoverImage;
      }
      const validGroupLinks = groups.filter((item) => !!item.name && !!item.link);
      if (!!validGroupLinks?.length) form.group_links = validGroupLinks;

      const responseCommunity = !isEdit
        ? await createCommunity(objectToFormData(form))
        : await updateCommunity(
            (community as TCommunity).id,
            objectToFormData(form)
          );
      navigate(
        MainRoutes.weber + WeberRoutes.community + `/${responseCommunity.id}`
      );
    } catch (error: any) {
      toast(getErrorMessages(error?.response?.data), {
        type: 'error',
      });
    } finally {
      setSubmitButtonDisabled(false);
    }
  };

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    // @ts-ignore
    const imageFile = e.target.files[0];

    if (['image/png', 'image/jpeg', 'image/jpg'].includes(imageFile.type)) {
      setAvatarImage(imageFile);
      const reader = new FileReader();
      reader.addEventListener('load', (_e) => {
        // @ts-ignore
        setAvatarImagePath(_e.target.result);
      });

      reader.readAsDataURL(imageFile);
    }
  };

  const renderSelectAvatarImage = () => (
    <div className={'mb-4 flex flex-wrap items-center gap-3'}>
      {imagesLoading ? (
        <Loader />
      ) : images?.length ? (
        images?.map((item, key) => (
          <div className={'flex flex-col items-center gap-y-1'} key={key}>
            <div
              className={classNames(
                'h-[93px] w-[93px] min-w-[93px] cursor-pointer overflow-hidden rounded-[9px] border-[1px] border-[#E0E5F2]',
                {
                  'border-[2px] border-purple-500': activeImageId === item.id,
                }
              )}
              onClick={() => setActiveImageId(item.id)}
            >
              <img src={item.image} alt={'item'} />
            </div>
          </div>
        ))
      ) : (
        <p>{t('course.notFound')}</p>
      )}
      {avatarImagePath && (
        <div
          onClick={() => setActiveImageId(avatarImagePath)}
          className={'flex cursor-pointer flex-col items-center gap-y-1'}
        >
          <div
            className={classNames(
              'flex h-[93px] w-[93px] min-w-[93px] cursor-pointer items-center justify-center overflow-hidden rounded-[9px] border-[1px] border-[#E0E5F2] bg-secondaryGrey-500',
              {
                'border-[2px] border-purple-500': activeImageId === avatarImagePath,
              }
            )}
          >
            <img src={avatarImagePath} alt={'item'} />
          </div>
        </div>
      )}
      <label
        htmlFor={'cover-image'}
        className={'flex cursor-pointer flex-col items-center gap-y-1'}
      >
        <div
          className={
            'flex h-[93px] w-[93px] min-w-[93px] cursor-pointer items-center justify-center overflow-hidden rounded-[9px] border-[1px] border-dashed border-[#E0E5F2] bg-secondaryGrey-100'
          }
        >
          <Plus />
          <input
            id={'cover-image'}
            type={'file'}
            accept=".png, .jpg, .jpeg"
            onChange={handleChange}
            style={{ display: 'none' }}
          />
        </div>
      </label>
    </div>
  );

  const handleChangeGroups = (item: TGroupLinkItem, index: number) => {
    groups.splice(index, 1, item);
    setGroups([...groups]);
  };

  const addGroupItem = () => {
    setGroups([...groups, { name: '', link: '' }]);
  };

  const breads: Bread[] = useMemo(() => {
    const breadcrumbArray: Bread[] = [
      { title: 'WEBER', url: MainRoutes.weber },
      {
        title: t('communities.title'),
        url: MainRoutes.weber + WeberRoutes.communities,
      },
    ];

    if (Number(communityId)) {
      breadcrumbArray.push({
        title: community?.name || '',
        url: MainRoutes.weber + WeberRoutes.community + `/${communityId}`,
      });
    }

    breadcrumbArray.push({
      title: Number(communityId) ? t('communities.edit') : t('communities.create'),
      url: Number(communityId)
        ? MainRoutes.weber + WeberRoutes['community-edit'] + `/${communityId}`
        : MainRoutes.weber + WeberRoutes['community-create'],
    });

    return breadcrumbArray;
  }, [communityId, t, community]);

  const removeGroup = (index: number) => {
    if (index) {
      groups.splice(index, 1);
      setGroups([...groups]);
    }
  };

  return (
    <div>
      <div className="col-span-11 mb-[16px] hidden lg:block">
        <Breadcrumbs breads={breads} />
      </div>
      <Card
        extra={
          'w-full max-w-[898px] mx-auto rounded-[30px] !shadow-[14px_17px_40px_4px_#7090B014] p-[8px] lg:p-[24px]'
        }
      >
        <p
          className={'mb-[10px] text-[16px] font-[500] text-gray-900 lg:text-[18px]'}
        >
          {t('communities.basicInfo')}
        </p>
        <div
          className={
            'mb-[16px] flex flex-col justify-between gap-[20px] lg:flex-row'
          }
        >
          <MInput
            placeholder={t('communities.Input')}
            name={'name'}
            label={t('communities.name*')}
            value={values.name}
            bottomText={errors.name}
            state={errors.name ? 'error' : 'default'}
            wrapperClassName={'w-full lg:w-[50%]'}
            inputClassName={'w-full'}
            onChange={onChange}
            autoFocus
            size={'sm'}
          />
          <MSelect
            placeholder={t('communities.Select')}
            label={t('communities.subject')}
            wrapperClassName={'w-full lg:w-[50%]'}
            value={values.subject}
            name={'subject'}
            bottomText={errors.subject}
            inputClassName={'w-full'}
            size={'sm'}
            state={errors.subject ? 'error' : 'default'}
            onChange={onChange}
            options={subjectsList}
          />
        </div>
        <p
          className={'mb-[10px] text-[16px] font-[500] text-gray-900 lg:text-[18px]'}
        >
          {t('communities.Avatar')}
        </p>
        {renderSelectAvatarImage()}
        <DragAndDrop
          uploadedFiles={[]}
          onChange={onCoverImageDrop}
          fileTypes={['JPG', 'JPEG', 'PNG']}
          multi={false}
          title={t('communities.CoverImage')}
          optional
        />
        {communityCoverImage && (
          <div className={'b-dad__list'}>
            <div className={'b-dad__list__item flex items-center justify-between'}>
              <div className={'flex items-center gap-3'}>
                <Drag className={'b-dad__list__item__drag'} />
                <Document />
                <p className={'b-dad__list__item__text'}>
                  {typeof communityCoverImage === 'string'
                    ? (() => {
                        const arrayPath = communityCoverImage?.split('/');
                        return (
                          arrayPath?.[arrayPath?.length - 1].split('?')[0] || ''
                        );
                      })()
                    : (communityCoverImage as File)?.name}
                </p>
              </div>
              <Trash
                className={'b-dad__list__item__trash'}
                onClick={removeCoverImage}
              />
            </div>
          </div>
        )}
        <p
          className={
            'mb-[10px] mt-[10px] text-[16px] font-[500] text-gray-900 lg:text-[18px]'
          }
        >
          {t('communities.About')}
        </p>
        <MTextArea
          value={values.description}
          name={'description'}
          onChange={onChange}
          label={t('communities.Description')}
          placeholder={'Text'}
          wrapperClassName={'w-full mb-[10px]'}
          inputClassName={'w-full'}
          rows={3}
          state={errors.description ? 'error' : undefined}
          bottomText={errors.description}
        />
        <MTextArea
          value={values.goal}
          name={'goal'}
          onChange={onChange}
          label={t('communities.Goal')}
          placeholder={'Text'}
          wrapperClassName={'w-full mb-[10px]'}
          inputClassName={'w-full'}
          rows={3}
          state={errors.goal ? 'error' : undefined}
          bottomText={errors.goal}
        />
        {groups?.map((item, key) => (
          <GroupLinkItem
            item={item}
            onChange={(data) => handleChangeGroups(data, key)}
            onClose={() => removeGroup(key)}
            hideLabel={!!key}
            key={key}
          />
        ))}
        <div className={'flex'}>
          <MButton
            color={'primary'}
            variant={'secondary'}
            size={'sm'}
            onClick={addGroupItem}
          >
            {t('buttons.add_link')} +
          </MButton>
        </div>
        <div className={'mt-[20px] flex justify-end'}>
          <MButton
            color={'primary'}
            variant={'highlighted'}
            size={'sm'}
            onClick={submitForm}
            disabled={submitButtonDisabled}
          >
            {isEdit ? t('communities.Update') : t('communities.Create')}
          </MButton>
        </div>
      </Card>
    </div>
  );
};
