import { useCallback, useState } from 'react';
import { Schema, ValidationError } from 'yup';

// export enum ErrorMessages {
//   required = 'Поле является обязательным к заполнению',
//   endDate = 'Дата окончания должна быть позже даты начала ',
//   validTime = 'Время должно быть в формате ЧЧ:ММ',
//   email = 'Некорректный email',
//   url = 'Некорректный url',
//   atlasOne = 'Выберите хотя бы один вариант',
//   validTimeDate = 'Нарушена хронология',
//   valueNumber = 'Значение должно быть больше чем 0',
//   max200 = 'Максимальное значение 200 символов',
//   phone = 'Phone number is not valid',
// }

export enum ErrorMessagesCode {
  required = 'errors.required',
  endDate = 'errors.endDate',
  validTime = 'errors.validTime',
  email = 'errors.email',
  url = 'errors.url',
  atlasOne = 'errors.atlasOne',
  validTimeDate = 'errors.validTimeDate',
  valueNumber = 'errors.valueNumber',
  max200 = 'errors.max200',
  phone = 'errors.phone',
}

function useForm<T>(defaultValues: T) {
  const [values, setValues] = useState<T>(defaultValues);
  const [errors, setErrors] = useState<{
    [K in keyof T]?: string;
  }>({});

  const onChange = useCallback((value: any, name?: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name as keyof T]: value,
    }));
  }, []);

  const validate = useCallback(
    async (schema: Schema) => {
      try {
        await schema.validate(values, {
          abortEarly: false,
        });

        setErrors({});

        return false;
      } catch (e) {
        const validationErrors = e as ValidationError;
        const tempErrors: typeof errors = {};

        validationErrors.inner.forEach((error: ValidationError) => {
          if (Object.keys(values as keyof T).indexOf(error.path as string) > -1) {
            tempErrors[error.path as keyof T] = error.errors[0];
          }
        });

        setErrors(tempErrors as typeof errors);

        return true;
      }
    },
    [values]
  );

  return {
    values,
    errors,
    onChange,
    validate,
    setValues,
    setErrors,
  };
}

export default useForm;
