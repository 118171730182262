import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.scss';

interface MainPageLearnMoreProps {
  className?: string;
}

export const SupportMore = ({ className }: MainPageLearnMoreProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <Link to={'https://t.me/edres_community'} target="_blank">
      <div className={classNames('SupportMore', className)}>
        <div className={classNames('SupportMore__content')}>
          <h4>{t('support.more')}</h4>
          <p>
            {t('support.contact_more_description')}
            <br />
            <span>{t('support.more_link')}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};
