import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../horizon-components/Modal';
import { BottomSheet } from '../../../../horizon-components/bottom-sheet/ui';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { AuctionViewPlaceBid } from './auction-view-place-bid';

export const AuctionViewRate = () => {
  const { t } = useTranslation(['translation']);
  const { setIsSendBidMode, isSendBidMode } = useAuctionContext();

  const handleToggle = () => {
    setIsSendBidMode(!isSendBidMode);
  };

  return window.innerWidth > 768 ? (
    <Modal
      title={t('bid.Place_a_bet')}
      isOpen={isSendBidMode}
      onClose={handleToggle}
    >
      <AuctionViewPlaceBid onShowBet={handleToggle} />
    </Modal>
  ) : (
    <BottomSheet open={isSendBidMode} onDismiss={handleToggle}>
      <AuctionViewPlaceBid onShowBet={handleToggle} />
    </BottomSheet>
  );
};
