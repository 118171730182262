import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo/logo.svg';
import { PlatformsEnum } from '../../entities/Platforms/platforms';
import { MButton } from '../../horizon-components/MButton';
import { useUserContext } from '../../providers';
import getPlatform from '../../utils/getPlatform';
import { DownloadMobileAppModal } from '../DownloadMobileAppModal';

export const MobileAppBanner = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [showModal, setShowModal] = useState(false);
  const platform = getPlatform();

  if (!user) {
    return null;
  }
  if (platform !== PlatformsEnum.WEB) {
    return null;
  }

  return (
    <>
      <DownloadMobileAppModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
      <div
        className={
          'hidden h-[72px] w-full cursor-pointer items-center gap-[12px] bg-secondary-dark-grey-700 p-[12px] lg-max:flex'
        }
        onClick={() => setShowModal(true)}
      >
        <LogoIcon className={'shrink-0'} />
        <div className={'flex grow flex-col gap-[2px] text-white'}>
          <div className={'text-[14px] font-bold'}>Edres</div>
          <div className={'text-[11px] font-medium'}>
            {t('app_banner.mobile_app_banner_text')}
          </div>
        </div>
        <MButton variant="highlighted" color="white" size={'xs'}>
          {t('app_banner.open')}
        </MButton>
      </div>
    </>
  );
};
