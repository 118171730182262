import React, { useEffect } from 'react';
import SubjectImg from '../../../assets/images/education/subject.svg';
import StarIcon from '../../../assets/icons/subject/star.svg';
import { useTranslation } from 'react-i18next';
import { CourseEnroll } from '../../../features/course-enroll';
import { CourseFavorite } from '../../../features/CourseFavorite';
import useFetch from '../../../hooks/useFetch';
import { getPrivateFavouriteCoursesV3 } from '../../../api/courseV3';
interface SubjectInfoCardProps {
  subject: string;
  grade: string;
  name: string;
  rating: number;
  language: string;
  courseId: number;
  onFavorite?: () => void;
  isFavorite?: boolean;
  isEnrolled?: boolean;
}

const SubjectInfoCard: React.FC<SubjectInfoCardProps> = ({
  subject,
  grade,
  name,
  rating,
  language,
  courseId,
  onFavorite,
  isFavorite,
  isEnrolled,
}) => {
  return (
    <div className="rounded-[16px] bg-[#E9E3FF] p-[16px]">
      <div className="flex justify-between">
        <div>
          <div className="mb-[10px] md:mb-[40px]">
            <p className="mb-[4px] text-xs font-semibold text-[#7551FF]">
              {subject}, {grade}
            </p>
            <p className="text-md mb-[10px] font-bold text-secondary-grey-900">
              {name}
            </p>
          </div>

          <div className="mb-[10px] flex gap-[6px]">
            <div className="flex h-[24px] items-center gap-[2px] rounded-[36px] bg-purple-500 px-[10px] py-[4px]">
              <img src={StarIcon} alt="" />
              <p className="text-xs font-semibold text-white">{rating}</p>
            </div>
            <div className="flex h-[24px] items-center gap-[2px] rounded-[36px] bg-purple-500 px-[10px] py-[4px]">
              <p className="text-xs font-semibold text-white">
                {language === 'ru' ? 'Рус' : language === 'en' ? 'EN' : language}
              </p>
            </div>
          </div>
        </div>
        <div>
          <img src={SubjectImg} alt="" />
        </div>
      </div>

      <div className="flex justify-between gap-[12px]">
        <CourseEnroll
          className="w-full border-none !bg-primary-white !text-primary-purple-blue-500"
          courseId={courseId}
          isLogo={true}
          isEnrolled={isEnrolled}
        />
        <CourseFavorite
          favoriteClassName="!text-primary-purple-blue-300 fill-primary-purple-blue-300"
          favoriteId={courseId}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
        />
      </div>
    </div>
  );
};

export default SubjectInfoCard;
