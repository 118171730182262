import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../horizon-components/Navigate';
import { Loader } from '../../../components/Loader';
import { TasksPage } from '../../../modules/Tasks/tasks-view';

export const TasksRouter: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <div className="AddBottom mx-auto w-full grow  sm-max:p-2">
        <Routes>
          <Route path="/" element={<TasksPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Suspense>
  );
};
