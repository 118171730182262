import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ISubjectDetail, ISubjectProgress, IUnit } from '../models/Subjects';
import { ISubject } from '../models/Subjects';
import { getPublicSubjectsV3, getPublicUnitsV3 } from '../api/subjectV3';
import { RootState } from '.';
import mathImage from '../assets/images/map/subjects/math.svg';

interface SubjectState {
  progressLevelData: Record<string, ISubjectProgress[]> | null;
  subjects: Record<string, ISubject[]> | null;
  isOpenSubjectModal: boolean;
  isOpenLevelModal: boolean | undefined;
  subjectsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  subjectsError: string | null;
  units: Record<string, IUnit[]> | null;
  unitsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  unitsError: string | null;
  currentSubjectId: number;
  subjectDetails: Record<string, ISubjectDetail[]> | null;
  isMapPage: boolean;
}

const initialState: SubjectState = {
  progressLevelData: null,
  subjects: null,
  isOpenSubjectModal: false,
  isOpenLevelModal: undefined,
  subjectsStatus: 'idle',
  subjectsError: null,
  units: null,
  unitsStatus: 'idle',
  unitsError: null,
  currentSubjectId: 8,
  subjectDetails: null,
  isMapPage: false,
};

export const fetchPublicSubjects = createAsyncThunk<
  { language: string; data: ISubject[] },
  string
>('subjects/fetchPublicSubjects', async (language: string) => {
  const data = await getPublicSubjectsV3(language);
  return { language, data };
});

export const fetchPublicUnits = createAsyncThunk<
  { language: string; data: IUnit[] },
  string
>('units/fetchPublicUnits', async (language: string) => {
  const data = await getPublicUnitsV3(language);
  return { language, data };
});

export const fetchSubjectDetails = createAsyncThunk<
  { language: string; data: ISubjectDetail[] },
  string,
  { state: RootState }
>('subjects/fetchSubjectDetails', async (language, { getState }) => {
  const state = getState();
  const { progressLevelData, subjects, units } = state.start;

  const languageProgressData = progressLevelData?.[language];

  if (!languageProgressData || !subjects || !units) return { language, data: [] };

  const data = languageProgressData
    .map((progress) => {
      const subject = subjects[language]?.find((s) => s.id === progress.subject_id);

      const subjectImage = subject?.image;
      const defaultImage = mathImage;

      if (!subject) return null;

      const category = subject.subject_categories.find((cat) =>
        cat.grades.some((grade) => grade.order === progress.level)
      );

      if (!category) return null;

      const matchingUnits = units[language]
        .filter((unit) => unit.subject_id === progress.subject_id)
        .filter((unit) => unit.level === progress.level)
        .filter((unit) => !progress.completed_units_ids.includes(unit.id))
        .filter((unit) => unit.level <= 12);

      const filteredCompletedUnitsIds = progress.completed_units_ids.filter(
        (unitId) => {
          const unit = units[language].find((u) => u.id === unitId);
          return unit?.level === progress.level;
        }
      );

      const levelProgress =
        filteredCompletedUnitsIds.length /
        (matchingUnits.length + filteredCompletedUnitsIds.length);
      const findUnit = matchingUnits[0];

      let nextLevelProgress = null;
      let nextUnits = null;
      let isLevelCompleted = false;
      let completedLevelProgress = null;

      if (matchingUnits.length === 0) {
        nextUnits = units[language]
          .filter((unit) => unit.subject_id === progress.subject_id)
          .filter((unit) => unit.level > progress.level)
          .filter((unit) => unit.level <= 12)
          .reduce<IUnit[]>((acc, unit) => {
            if (!acc.length || unit.level < acc[0].level) {
              return [unit];
            }
            if (unit.level === acc[0].level) {
              acc.push(unit);
            }
            return acc;
          }, [] as IUnit[]);

        nextLevelProgress = 0;
        if (nextUnits.length === 0) {
          isLevelCompleted = true;
          completedLevelProgress = 1;
        }
      }

      return {
        subjectId: subject.id,
        title: subject.title,
        levelLabel: category.level_label,
        gradeCount: category.grade_count,
        unitCount: category.unit_count,
        levelProgress: completedLevelProgress ?? nextLevelProgress ?? levelProgress,
        unitTitle: nextUnits?.[0]?.title ?? findUnit?.title ?? 'Default Title',
        unitId: nextUnits?.[0]?.id ?? findUnit?.id ?? -1,
        subjectLevel: nextUnits?.[0]?.level ?? findUnit?.level ?? 12,
        subjectImage: subjectImage ?? defaultImage,
        isLevelCompleted,
      };
    })
    .filter((item): item is ISubjectDetail => item !== null);

  return { language, data };
});

export const startSlice = createSlice({
  name: 'start',
  initialState,
  reducers: {
    updateProgressLevelData: (
      state,
      action: PayloadAction<{ language: string; data: ISubjectProgress[] }>
    ) => {
      if (!state.progressLevelData) {
        state.progressLevelData = {};
      }
      state.progressLevelData[action.payload.language] = action.payload.data;
    },

    setCurrentSubjectId: (state, action: PayloadAction<number>) => {
      state.currentSubjectId = action.payload;
    },

    setIsOpenSubjectModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSubjectModal = action.payload;
    },

    setIsOpenLevelModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenLevelModal = action.payload;
    },

    setIsMapPage: (state, action: PayloadAction<boolean>) => {
      state.isMapPage = action.payload;
    },

    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicSubjects.pending, (state) => {
        state.subjectsStatus = 'loading';
        state.subjectsError = null;
      })

      .addCase(fetchPublicSubjects.fulfilled, (state, action) => {
        state.subjectsStatus = 'succeeded';
        if (!state.subjects) {
          state.subjects = {};
        }
        state.subjects[action.payload.language] = action.payload.data;
      })

      .addCase(fetchPublicSubjects.rejected, (state, action) => {
        state.subjectsStatus = 'failed';
        state.subjectsError = action.error.message || 'Error loading subjects';
      })

      .addCase(fetchPublicUnits.pending, (state) => {
        state.unitsStatus = 'loading';
        state.unitsError = null;
      })

      .addCase(
        fetchPublicUnits.fulfilled,
        (state, action: PayloadAction<{ language: string; data: IUnit[] }>) => {
          state.unitsStatus = 'succeeded';
          if (!state.units) {
            state.units = {};
          }
          state.units[action.payload.language] = action.payload.data;
        }
      )

      .addCase(
        fetchSubjectDetails.fulfilled,
        (
          state,
          action: PayloadAction<{ language: string; data: ISubjectDetail[] }>
        ) => {
          if (!state.subjectDetails) {
            state.subjectDetails = {};
          }
          state.subjectDetails[action.payload.language] = action.payload.data;
        }
      )

      .addCase(fetchPublicUnits.rejected, (state, action) => {
        state.unitsStatus = 'failed';
        state.unitsError = action.error.message || 'Error loading units';
      });
  },
});

export const {
  updateProgressLevelData,
  setIsOpenSubjectModal,
  setIsOpenLevelModal,
  setCurrentSubjectId,
  setIsMapPage,
  resetState,
} = startSlice.actions;
export const selectProgressLevelData = (state: { start: SubjectState }) =>
  state.start.progressLevelData;
export const selectIsOpenSubjectModal = (state: { start: SubjectState }) =>
  state.start.isOpenSubjectModal;
export default startSlice.reducer;
